import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import camelCase from "lodash/camelCase";

//Components
import InformationContainer from "components/intelligent-search/search-result/information/InformationContainer";
import PreviewResultContainer from "components/intelligent-search/search-result/preview/PreviewResultContainer";

// Services
import { 
  getEventTimelineDetailApi, 
  getChatParticipants 
} from "services/EventTimelineService";

// Helpers
import { onConvertMessages } from "helpers/ConversationHelper";

// Store
import {
  resetTimeLineDetail,
  resetItemDetailData,
  setParamSearchTimeLine,
} from "store/EventTimelineReducer";
import { fetchTagsList } from "store/TagReducer";
import { setIsMatterDisable } from "store/CommonReducer";
import { fetchDataSourceList } from "store/DataSourceReducer";

// Constants
import { DATA_TYPE } from "constants/DataType";
import { EVENT_KEY_LISTENER, PAGE_NAME } from "constants/Common";

// Styles
import styles from "./EventTimelineDetail.module.scss";

const EventTimelineDetail = (props) => {
  const {
    eventTimelineType = DATA_TYPE.chat,
    eventTimelineID,
    isLoading = true,
    setIsLoading = () => {},
    pageName = "",
    titleActivity = "",
    dataDetail,
    updateStoreDetail,
    searchMsgInput,
  } = props;
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const [totalSearchCount, setTotalSearchCount] = useState(0);
  const [totalMessageContainKeyWord, setTotalMessageContainKeyWord] =
    useState(0);
  const [listPageNumber, setListPageNumber] = useState([]);
  const [allFirstLoadMessages, setAllFirstLoadMessages] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [partyLoading, setPartyLoading] = useState(true);

  const { messageViewDatePicker: dateFilterMsg } = useSelector(
    (state) => state.datePicker
  );

  // Get tags from store
  const {
    tags: { selectTags: tagsList, showTags: showTagsList },
    isExecutingTag,
  } = useSelector((state) => state.tag);

  // Get participants from reducer 
  const fetchParticipants = async (groupId) => {
    setPartyLoading(true);
    try {
      const resultParticipants = await getChatParticipants(projectId, groupId.toLowerCase());

      updateStoreDetail({
        participants: resultParticipants?.data,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setPartyLoading(false);
    }
  };

  const fetchEventTimelineDetail = async (filter = {}) => {
    setIsLoading(true);
    try {
      const resultDetail = await getEventTimelineDetailApi(
        camelCase(eventTimelineType),
        projectId,
        eventTimelineID,
        filter
      );
      if (eventTimelineType !== DATA_TYPE.chat)
        updateStoreDetail(resultDetail.data);
      else {
        const data = resultDetail.data || {};
        fetchParticipants(data.chatID);
        const messages = onConvertMessages(
          data.instantMessages,
          data.pageNumbers[0] - 1
        );
        setAllFirstLoadMessages(messages);
        updateStoreDetail({
          deletedState: data.deletedState,
          id: data.id,
          instantMessages: messages,
          type: data.type,
          chatID: data.chatID,
          totalMessage: data.totalMessage,
          pageNumbers: data.pageNumbers[0],
          source: data.sources,
          dataSourceID: data.dataSourceID,
        });
        // set total search count when have search input
        if (searchMsgInput) {
          setTotalSearchCount(data.searchCount);
          setTotalMessageContainKeyWord(data.messageCount);
        }
        setListPageNumber(data.pageNumbers);
      }
    } catch (err) {
      dispatch(resetTimeLineDetail());
      dispatch(resetItemDetailData());
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const setInstantMessagesStore = (lstMessages, totalMessage = -1) => {
    if (isExecutingTag) return;
    updateStoreDetail({
      ...dataDetail,
      instantMessages: [...lstMessages],
      totalMessage:
        totalMessage === -1 ? dataDetail?.totalMessage : totalMessage,
    });
  };

  const fetchChatDetailApi = async (type, id, filter = {}) => {
    const dataResult = await getEventTimelineDetailApi(
      camelCase(type),
      projectId,
      id,
      filter
    );
    return dataResult;
  };

  const handleSearchEmailDetailByDate = async (data) => {
    const paramFilterDate = {
      Start: data.dateTimeDataStart || null,
      End: data.dateTimeDataEnd || null,
    };
    await fetchEventTimelineDetail(paramFilterDate);
  };

  const setSearchMsgInputStore = (keyword) =>
    dispatch(setParamSearchTimeLine({ searchMsgInput: keyword }));

  const onSetParamIsNewestStore = (isNewest) =>
    dispatch(setParamSearchTimeLine({ isNewest }));

  const getInitialData = async () => {
    dispatch(fetchDataSourceList(projectId));
    dispatch(fetchTagsList({ projectId }));
    if (!eventTimelineID) return;
    await fetchEventTimelineDetail({ PageNumber: 1 });
    setIsFirstLoad(false);
  };

  useEffect(() => {
    getInitialData();
    // Events when time out
    const timeOutEventHandler = (event) => {
      if (
        event.detail.key === EVENT_KEY_LISTENER.timeOut &&
        !event.detail.newValue
      )
        getInitialData();
    };
    dispatch(setIsMatterDisable(true));
    // Hook up the event handler
    window.addEventListener(EVENT_KEY_LISTENER.timeOut, timeOutEventHandler);
    return () => dispatch(setIsMatterDisable(false));
  }, []);

  return (
    <div className={styles["et-detail-body"]}>
      <div className={styles["et-detail-information"]}>
        <InformationContainer
          loading={
            eventTimelineType === DATA_TYPE.chat ? partyLoading : isLoading
          }
          reviewType={eventTimelineType}
          data={dataDetail}
          totalRecords={10}
        />
      </div>
      <div className={styles["et-detail-result"]}>
        <PreviewResultContainer
          loading={isLoading}
          reviewType={eventTimelineType}
          data={dataDetail}
          participants={dataDetail?.participants}
          searchMsgInput={searchMsgInput || ""}
          dateTime={dateFilterMsg}
          totalSearchCount={totalSearchCount}
          showTagsList={showTagsList}
          setTotalSearchCount={setTotalSearchCount}
          totalMessageContainKeyWord={totalMessageContainKeyWord}
          setTotalMessageContainKeyWord={setTotalMessageContainKeyWord}
          listPageNumber={listPageNumber}
          setListPageNumber={setListPageNumber}
          setSearchMsgInputStore={setSearchMsgInputStore}
          onSetParamIsNewestStore={onSetParamIsNewestStore}
          setInstantMessagesStore={setInstantMessagesStore}
          handleSearchEmailDetailByDate={handleSearchEmailDetailByDate}
          fetchChatDetailApi={fetchChatDetailApi}
          isShowFooter={[
            PAGE_NAME.viewDetail,
            PAGE_NAME.eventTimeline,
          ].includes(pageName)}
          allFirstLoadMessages={allFirstLoadMessages}
          isFirstLoad={isFirstLoad}
          setIsFirstLoad={setIsFirstLoad}
          totalItemOfPage={1}
          isShowChangeOption={false}
          tagsList={tagsList}
          titleActivity={titleActivity}
        />
      </div>
    </div>
  );
};

EventTimelineDetail.propTypes = {
  eventTimelineType: PropTypes.string,
  eventTimelineID: PropTypes.string,
  titleActivity: PropTypes.string,
  pageName: PropTypes.string,
  searchMsgInput: PropTypes.string,
  isLoading: PropTypes.bool,
  dataDetail: PropTypes.any,
  setIsLoading: PropTypes.func,
  updateStoreDetail: PropTypes.func,
};

export default EventTimelineDetail;
