import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useOnClickOutside } from "hook/click-outside";
import { PropTypes } from "prop-types";
import { camelCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

// Services
import { getEventTimelineDetailApi } from "services/EventTimelineService";

// Components
import { MemberList } from "../members/MemberList";
import { Button } from "components/shared/button/Button";
import { EmailItem } from "components/review/email/email-item/EmailItem";
import MessageHeader from "components/shared/message-header/MessageHeader";
import PreviewVideo from "components/intelligent-search/search-result/preview/preview-video/PreviewVideo";
import PreviewAudio from "components/intelligent-search/search-result/preview/preview-audio/PreviewAudio";
import RenderAttachment from "components/shared/attachment-view/RenderAttachment";

// Helpers
import {
  formatDateTime,
  formatHourMinSec,
} from "helpers/DateTimeFormatterHelper";
import { getDuration, toThousandsNumber } from "helpers/FormatterHelper";
import { getDeletedStatus } from "helpers/TextHelper";
import { getFileNameFromUrl } from "helpers/GetFileNameHelper";
import { getDurationByUrl } from "helpers/UrlHelper";
import { getAddressAzureMap } from "helpers/LocationHelper";
import {
  getDataSourceNameFromID,
  getSourceIconByName,
} from "helpers/CommonHelper";
import { isCheckJoinLeave } from "helpers/ConversationHelper";

// Reducers
import {
  resetTimeLineDetail,
  setIsTimelineOrigin,
  setValidTime,
} from "store/EventTimelineReducer";
import { setIsGoToOrigin } from "store/CommonReducer";

// Constants
import {
  DATA_TYPE,
  MEDIA_TYPE,
  TIMELINE_INFORMATION,
} from "constants/DataType";
import { CONVERSATION_ACTION, DATE_TIME_TYPE } from "constants/Constants";
import {
  COMMON_TEXT,
  PARAMETERS,
  PATH_NAME,
  PAGE_NAME,
} from "constants/Common";

// Styles
import styles from "./ItemTimeLine.module.scss";
import MatterAvatar from "components/shared/file-components/matter-avatar/MatterAvatar";

const ItemTimeLine = ({ data, currentIndex, isGroupEvents }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { projectId } = useParams();
  const { pathname } = useLocation();
  const timeLineRef = useRef(null);

  const [description, setDescription] = useState("");
  const [isShowDescription, setIsShowDescription] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [mainParticipant, setMainParticipant] = useState("");
  const [fileDuration, setFileDuration] = useState();
  const [addressMap, setAddressMap] = useState();
  const [currentEvent, setCurrentEvent] = useState(
    isGroupEvents ? data[0] : data
  );
  const dataSourceList = useSelector(
    (state) => state.dataSource.dataSourceList
  );

  const containerClassName = clsx(
    styles.container,
    currentEvent?.type === DATA_TYPE.chat && styles['chat-container'],
    currentEvent?.type === DATA_TYPE.email && styles['email-container'],
    currentEvent?.type === DATA_TYPE.note && styles['note-container'],
    (currentEvent?.type === DATA_TYPE.audio || currentEvent?.type === DATA_TYPE.voicemail) && styles['audio-container'],
    (currentEvent?.type === DATA_TYPE.image || currentEvent?.type === DATA_TYPE.video) && styles['media-container'],
    isGroupEvents && styles['container-group']
  );

  const bodyClassName = clsx(
    (currentEvent?.type !== DATA_TYPE.chat && currentEvent?.type !== DATA_TYPE.email && currentEvent?.type !== DATA_TYPE.image) && styles["body-datatype"],
    (currentEvent?.type === DATA_TYPE.audio || currentEvent?.type === DATA_TYPE.voicemail) && styles["body-audio"],
    currentEvent?.type === DATA_TYPE.image && styles["body-image"],
    currentEvent?.type === DATA_TYPE.video && styles["body-video"],
    currentEvent?.type === DATA_TYPE.note && styles["body-note"],
    currentEvent?.type === DATA_TYPE.email && styles["body-email"]
  );

  const memberListStyles = {
    [DATA_TYPE.chat]: styles["member-list-chat"],
    [DATA_TYPE.email]: styles["member-list-email"],
  };

  const renderType = (type, media) => {
    switch (type) {
      case DATA_TYPE.chat:
        switch (media) {
          case MEDIA_TYPE.messenger:
          case MEDIA_TYPE.facebookMessenger:
            return "linear-gradient(224.66deg, #FF6968 4.84%, #A334FA 39.86%, #0695FF 78.29%)";
          case MEDIA_TYPE.skype:
            return "#00AFF0";
          case MEDIA_TYPE.linkedin:
            return "#0E76A8";
          case MEDIA_TYPE.viber:
            return "#7360F2";
          case MEDIA_TYPE.instagram:
            return "linear-gradient(134deg, rgba(184, 65, 177, 0.20) 0%, rgba(188, 68, 173, 0.20) 100%), radial-gradient(108.94% 105.67% at 16.67% 100.00%, #F9DA54 0%, #EF6745 47.60%, #AC3DB8 83.07%, #4B65C8 100%)";
          case MEDIA_TYPE.weibo:
            return "linear-gradient(180deg, #F93 0%, #E6162D 100%)";
          default:
            return "#D3D3D3";
        }
      case DATA_TYPE.email:
        return "#FFF";
      default:
        return "#D3D3D3";
    }
  };

  const renderIcon = (type, source) => {
    if ([DATA_TYPE.chat, DATA_TYPE.email].includes(type))
      return getSourceIconByName(source, "other");
    else if (DATA_TYPE.callLog === type)
      return getSourceIconByName(source, camelCase(type));
    else return `/images/icons/${camelCase(type)}.svg`;
  };

  const descriptionList = (type) => {
    switch (type) {
      case DATA_TYPE.callLog:
        return TIMELINE_INFORMATION.callLog;
      case DATA_TYPE.voicemail:
        return TIMELINE_INFORMATION.voicemail;
      case DATA_TYPE.email:
        return TIMELINE_INFORMATION.email;
      case DATA_TYPE.historyLink:
        return TIMELINE_INFORMATION.historyLink;
      case DATA_TYPE.contact:
        return TIMELINE_INFORMATION.contact;
      case DATA_TYPE.chat:
        return TIMELINE_INFORMATION.chat;
      case DATA_TYPE.image:
      case DATA_TYPE.file:
        return TIMELINE_INFORMATION.image;
      case DATA_TYPE.video:
      case DATA_TYPE.audio:
        return TIMELINE_INFORMATION.video;
      case DATA_TYPE.location:
        return TIMELINE_INFORMATION.location;
      case DATA_TYPE.creditCard:
        return TIMELINE_INFORMATION.creditCard;
      case DATA_TYPE.network:
        return TIMELINE_INFORMATION.network;
      case DATA_TYPE.note:
        return TIMELINE_INFORMATION.note;
      case DATA_TYPE.database:
        return TIMELINE_INFORMATION.database;
      default:
        return TIMELINE_INFORMATION.default;
    }
  };

  const getEventTimeLineDetail = async (event, callback) => {
    try {
      const result = await getEventTimelineDetailApi(
        camelCase(event.type),
        projectId,
        event.id,
        {
          PageNumber: 1,
        }
      );
      if (!result || !result.data) return;
      const dataAPI = result.data;
      setDescription(dataAPI);

      // Scroll to current message
      dataAPI.instantMessages?.length > 0 &&
        setTimeout(() => {
          const ele = document.getElementById(event.id);
          ele?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
        }, 300);

      if (event.type === DATA_TYPE.chat)
        setMainParticipant(dataAPI.instantMessages[0].name);

      // get address map from location
      if (dataAPI.address?.trim()) {
        setAddressMap(dataAPI.address);
        return;
      }
      if (
        [DATA_TYPE.location].includes(event.type) &&
        !dataAPI.address?.trim()
      ) {
        const address = await getAddressAzureMap(
          dataAPI.latitude,
          dataAPI.longitude
        );
        setAddressMap(address);
      }

      // get duration to video,audio,voicemail
      if (dataAPI.duration) {
        const duration = getDuration(dataAPI.duration);
        setFileDuration(duration);
        return;
      }

      if (
        ![DATA_TYPE.video, DATA_TYPE.audio, DATA_TYPE.voicemail].includes(
          event.type
        ) ||
        !dataAPI.url
      )
        return;

      setFileDuration(await getDurationByUrl(dataAPI.url));
    } catch (error) {
      console.log(error);
    } finally {
      callback();
    }
  };

  const showDescription = (event) => {
    if (isShowDescription || !event) return;
    setIsShowDescription(true);
    setLoading(true);
    getEventTimeLineDetail(event, () => setLoading(false));
  };

  const getDataByDocID = (event) => {
    if (!event || isLoadingContent) return;
    setIsLoadingContent(true);
    getEventTimeLineDetail(event, () => setIsLoadingContent(false));
  };

  const getNonPhoneOwnerNames = (callLog) => {
    const participants = callLog.callParticipants;
    
    if (!participants || participants.length === 0) {
        return "No participants found";
    }

    const nonPhoneOwners = participants
        .filter(participant => participant.isPhoneOwner === false)
        .map(participant => participant.showName?.trim() ? participant.showName : participant.partyIdentifier)
        .join(', ');

    return nonPhoneOwners || "No participants found";
  }

  const renderParticipantsList = (participants) => (
    <div className={styles["des-participant"]}>
      {participants?.length === 0
        ? "-"
        : participants?.slice(0, 4).map((participant, index) => (
          <span key={index} className={styles["des-participant-avatar"]}>
            <MatterAvatar
              id={participant.id}
              matterId={projectId}
              avatarType="entity"
              deferFetch={true}
            />
            <span className={styles["des-participant-hover"]}>
              <MatterAvatar
                id={participant.id}
                matterId={projectId}
                avatarType="entity"
                deferFetch={true}
              />
              {participant.name}
            </span>
          </span>
        ))}
      {participants?.length > 4 && (
        <span
          className={clsx(
            styles["des-participant-more"],
            styles["des-participant-avatar"]
          )}
        >
          +{participants?.length - 4}
          <ul className={styles["des-participants-more"]}>
            {participants?.slice(4).map((participant, index) => (
              <li key={index}>
                <MatterAvatar
                  id={participant.id}
                  matterId={projectId}
                  avatarType="entity"
                  deferFetch={true}
                />
                {participant.name}
              </li>
            ))}
          </ul>
        </span>
      )}
      {participants?.length === 1 && (
        <span className={styles["des-participant-name"]}>
          {participants[0].name}
        </span>
      )}
    </div>
  );

  const renderValueDescription = (key, data) => {
    const value = description[data?.key];
    switch (key) {
      case PARAMETERS.type:
      case PARAMETERS.typeLabel: {
        return (
          <span className={styles["des-item-value"]}>
            <img
              className={styles["des-item-icon"]}
              src={`/images/icons/${camelCase(description?.type)}.svg`}
              alt={`${description?.type}`}
            />{" "}
            {value || COMMON_TEXT.default}
          </span>
        );
      }
      case PARAMETERS.totalMessage:
        return (
          <span className={styles["des-item-value"]}>
            {toThousandsNumber(value)}
          </span>
        );
      case PARAMETERS.dataSourceID:
        return (
          <span
            className={styles["des-item-value"]}
            title={getDataSourceNameFromID(dataSourceList, value?.split(", "))}
          >
            {getDataSourceNameFromID(dataSourceList, value?.split(", "))}
          </span>
        );
      case PARAMETERS.participants:
        return renderParticipantsList(value);
      case PARAMETERS.dateTime:
      case PARAMETERS.createdDate:
      case PARAMETERS.updatedDate:
      case PARAMETERS.lastConnection:
      case PARAMETERS.lastAutoConnection:
      case PARAMETERS.dateLastUsed:
      case PARAMETERS.expirationDate:
      case PARAMETERS.creationTime:
      case PARAMETERS.dateFirst:
      case PARAMETERS.dateLast:
      case PARAMETERS.lastVisited:
        return (
          <span className={styles["des-item-value"]}>
            {value
              ? formatDateTime({
                dateTime: value,
                type: DATE_TIME_TYPE.MM_DD_YYYY_hma,
              })
              : COMMON_TEXT.default}
          </span>
        );
      case PARAMETERS.duration: {
        return (
          <span className={styles["des-item-value"]}>
            {formatHourMinSec(fileDuration?.split(":"))}
          </span>
        );
      }
      case PARAMETERS.emails:
        return (
          <span className={styles["des-item-value"]}>{value?.length}</span>
        );
      case PARAMETERS.dateFirstEmail:
      case PARAMETERS.dateLastEmail: {
        return (
          <span className={styles["des-item-value"]}>
            {description["emails"]?.length > 0
              ? formatDateTime({
                dateTime:
                  description["emails"][
                  key === "dateFirstEmail"
                    ? 0
                    : description["emails"]?.length - 1
                  ]["dateSend"],
                type: DATE_TIME_TYPE.MM_DD_YYYY_hma,
              })
              : COMMON_TEXT.default}
          </span>
        );
      }
      case PARAMETERS.contactName: {
        return (
          <span className={styles["des-item-value"]}>
            {description.name && (
              <>
                <span>{description.name}</span> <br />
              </>
            )}
            {description.email && (
              <>
                <span className={styles["des-item-sub"]}>
                  {description.email}
                </span>
                <br />
              </>
            )}
            {description.phone && (
              <>
                <span className={styles["des-item-sub"]}>
                  {description.phone}
                </span>
                <br />
              </>
            )}
          </span>
        );
      }
      case PARAMETERS.profilePicture: {
        return (
          <span className={styles["des-item-value"]}>
            <MatterAvatar
              id={description?.id}
              matterId={projectId}
              avatarType="entity"
              deferFetch={true}
            />
          </span>
        );
      }
      case PARAMETERS.deletedState: {
        return (
          <span className={styles["des-item-value"]}>
            {getDeletedStatus(value)}
          </span>
        );
      }
      case PARAMETERS.source: {
        return (
          <span className={styles["des-item-value"]}>
            {value || COMMON_TEXT.unknown}
          </span>
        );
      }
      case PARAMETERS.title: {
        const fileName = getFileNameFromUrl(description?.url);
        const renderValue = () =>
          description?.type === DATA_TYPE.voicemail ? fileName : value;
        return (
          <span
            className={styles["des-item-value"]}
            title={renderValue() || COMMON_TEXT.unknown}
          >
            {renderValue() || COMMON_TEXT.unknown}
          </span>
        );
      }
      case PARAMETERS.address: {
        return (
          <span className={styles["des-item-value"]} title={addressMap}>
            {addressMap}
          </span>
        );
      }
      default: {
        let newValue = value?.toString()?.trim() || COMMON_TEXT.unknown;
        if (
          key === PARAMETERS.attachment &&
          description.type === DATA_TYPE.email &&
          description.emails?.length > 0
        ) {
          newValue = 0;
          description.emails.forEach((item) => {
            newValue += item.attachments?.length;
          });
        }
        return (
          <span className={styles["des-item-value"]} title={newValue}>
            {newValue}
          </span>
        );
      }
    }
  };

  const goToDetail = () => {
    if (!currentEvent) return;
    // reset store event timeline detail
    dispatch(resetTimeLineDetail());
    dispatch(setIsGoToOrigin(false));
    dispatch(setIsTimelineOrigin(true));
    dispatch(setValidTime({ keepDate: true, validTimeLoading: true }));

    navigate(
      `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.eventTimeline}/${PATH_NAME.detail}`,
      {
        state: {
          id: currentEvent.id,
          docID: currentEvent.docID,
          eventTimeLineType: currentEvent.type,
          pathname,
          pageName: PAGE_NAME.eventTimeline,
        },
      }
    );
  };

  const renderDodIDList = () => (
    <>
      {data.map((item) => (
        <div
          className={clsx(
            styles["doc-id-list-item"],
            item.id === currentEvent.id
              ? styles["doc-id-list-item__active"]
              : ""
          )}
          key={item.docID}
          onClick={() => {
            setCurrentEvent(item);
            getDataByDocID(item);
          }}
        >
          <div
            className={
              item.type === DATA_TYPE.chat
                ? styles["icon-type-chat"]
                : styles["icon-type"]
            }
          >
            <img
              src={renderIcon(item.type, item.source)}
              alt={`${item.type}`}
            />
          </div>
          <p>{item.docID}</p>
        </div>
      ))}
    </>
  );

  // TO-DO: Refactor to use the index
  const renderPreviewChat = (message) => {
    if (isCheckJoinLeave(message.label)) {
      return (
        <div
          className={styles["conversation-action"]}
          key={message.instantMessageID}
        >
          <span
            className={clsx(
              styles["content"],
              message.label === CONVERSATION_ACTION.leave ? styles["leave"] : ""
            )}
          >
            {message.body}
          </span>
          <span className={styles["date"]}>
            {formatDateTime({
              dateTime: message.timeStamp,
              type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
            })}
          </span>
        </div>
      );
    }

    return (
      <li
        key={message.instantMessageID}
        className={
          mainParticipant !== message?.name ? styles["des-chat-left"] : ""
        }
        id={message.instantMessageID}
      >
        <div className={styles["des-chat-header"]}>
          <MessageHeader
            name={message.name}
            dateTime={message.timeStamp}
            sourceType={message.source}
          />
        </div>
        <p className={styles["des-chat-body"]}>{message.body}</p>
      </li>
    );
  };

  const renderChat = (message) => {
    if (isCheckJoinLeave(message.label)) {
      return (
        <div
          className={styles["conversation-action"]}
          key={message.id}
        >
          <span
            className={clsx(
              styles["content"],
              message.label === CONVERSATION_ACTION.leave ? styles["leave"] : ""
            )}
          >
            {message.body}
          </span>
          <span className={styles["date"]}>
            {formatDateTime({
              dateTime: message.dateTime,
              type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
            })}
          </span>
        </div>
      );
    }

    return (
      <li
        key={message.id}
        className={
          mainParticipant !== message?.identifierName ? styles["body-groupchat-left"] : ""
        }
        id={message.id}
      >
        <div className={styles["body-groupchat-header"]}>
          <MessageHeader
            name={message.identifierName}
            dateTime={message.dateTime}
            sourceType={message.source}
            addZulu={false}
          />
        </div>
        <p className={styles["body-groupchat-message"]}>{message.body}</p>
        {message.attachments && message.attachments.length > 0 && (
          <div className={styles["body-groupchat-attachment-container"]}>
            {message.attachments.map((attachment) => (
              <div
                className={styles["body-groupchat-attachment"]}
                key={attachment.attachmentID}
                onClick={(event) => event.stopPropagation()}
              >
                <RenderAttachment
                  attachment={attachment}
                />
              </div>
            ))}
          </div>
        )}
      </li>
    );
  };

  const renderEmail = (email, index) => {
    return (
      <div onClick={(event) => event.stopPropagation()}>
        <EmailItem
          key={email.id}
          data={email}
          index={index}
          isBorderTop={index > 0}
          isShowCheck={false}
        />
      </div>
    );
  }

  const renderTimelineItemBody = () => {
    if (!currentEvent) return null;

    switch (currentEvent.type) {
      case DATA_TYPE.audio:
      case DATA_TYPE.voicemail:
        return (
          <PreviewAudio
            attachmentId={currentEvent.id}
            matterId={projectId}
            allowEventPropagation={false}
          />
        );

      case DATA_TYPE.historyLink:
        return (
          <div>
            <div className={styles.title}>
              <strong>{currentEvent.title ?? "Title not available"}</strong>
            </div>
            {currentEvent.url ?? "Url not available"}
          </div>
        );

      case DATA_TYPE.callLog:
        return (
          <div>
              <strong>
                {getNonPhoneOwnerNames(currentEvent)}
              </strong>
            <div className={styles.title}>
              {currentEvent.direction ?? "Unknown Direction"} - {currentEvent.status ?? "Unknown Status"}
            </div>
            {currentEvent.duration?.split('.')[0] ?? "Duration not available"}
          </div>
        );

      case DATA_TYPE.chat:
        return (
          <ul className={styles["body-groupchat"]}>
            {isGroupEvents
              ? data?.map((message) => renderChat(message))
              : data && renderChat(data)}
          </ul>
        );

      case DATA_TYPE.contact:
        return (
          <div>
            <div className={styles.title}>
              <strong>{currentEvent.name ?? "Name not available"}</strong>
            </div>
            {currentEvent.name !== currentEvent.phone && (
              <>{currentEvent.phone ?? "Phone not available"}</>
            )}
          </div>
        );

      case DATA_TYPE.database:
        return (
          <div>
            <div className={styles.title}>
              <strong>{currentEvent.size ? `${(currentEvent.size / 1024).toFixed(2)} KB` : "Size not available"}</strong>
            </div>
            {currentEvent.fileName ?? "File Name not available"}
          </div>
        );

      case DATA_TYPE.file:
        return (
          <div>
            <div className={styles.title}>
              <strong>{currentEvent.contentType ?? "Content Type not available"}</strong>
            </div>
            {currentEvent.title ?? "Title not available"}
          </div>
        );

      case DATA_TYPE.email:
        return (
          <div>
            {isGroupEvents
              ? data?.map((email, index) => renderEmail(email, index))
              : data && renderEmail(data, data.id)}
          </div>
        );

      case DATA_TYPE.image:
        return (
          <div onClick={(event) => event.stopPropagation()}>
            <RenderAttachment
              attachment={{
                attachmentID: currentEvent?.id,
                contentType: currentEvent?.type.toLowerCase(),
                hasNative: currentEvent?.hasNative,
                hasThumbnail: currentEvent?.hasThumbnail,
              }}
            />
          </div>
        );

      case DATA_TYPE.location:
        return (
          <div>
            <strong>{currentEvent.address ?? "Address not available"}</strong>
          </div>
        );

      case DATA_TYPE.network:
        return (
          <div>
            <div className={styles.title}>
              <strong>{currentEvent.ssid ?? "SSID not available"}</strong>
            </div>
            {currentEvent.bssid ?? "BSSID not available"}
          </div>
        );

      case DATA_TYPE.note:
        return (
          <div style={{
            textAlign: currentEvent.body ? 'left' : 'center',
            paddingTop: currentEvent.body ? '10px' : '85px'
          }}>
            {currentEvent.body
              ? currentEvent.body.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < currentEvent.body.split('\n').length - 1 && <br />}
                </React.Fragment>
              ))
              : <div className={styles.title}>
                No content available
              </div>}
          </div>
        );

      case DATA_TYPE.video:
        return (
          <PreviewVideo
            attachmentId={currentEvent.id}
            matterId={projectId}
            allowEventPropagation={false}
          />
        );

      default:
        return null;
    }
  };

  useOnClickOutside(
    timeLineRef,
    () => isShowDescription && setIsShowDescription(false)
  );

  useEffect(() => {
    if (loading || !description?.instantMessages || !data) return;
    const element = document.getElementById("destination");
    element?.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
  }, [loading]);

  useEffect(() => {
    if ((currentEvent?.type === DATA_TYPE.chat && currentEvent?.identifierName !== null) && currentEvent?.identifierName.length !== 0)
      setMainParticipant(currentEvent.identifierName);
  }, [currentEvent]);

  return (
    <div
      className={containerClassName}
      ref={timeLineRef}
      onClick={() => showDescription(currentEvent)}
    >
      <div className={styles["header"]}>
        <div className={styles["source-container"]}>
          <div
            className={styles["progress-bar-container"]}
            style={{
              background: `${renderType(
                currentEvent?.type,
                currentEvent?.source
              )}`,
            }}
          ></div>
          <p
            className={styles["source"]}
            title={
              currentEvent?.source ? currentEvent.source : COMMON_TEXT.unknown
            }
          >
            {currentEvent?.source ? currentEvent.source : COMMON_TEXT.unknown}
          </p>
        </div>
        <p className={styles["time"]}>
          {formatDateTime({
            dateTime: currentEvent?.dateTime,
            type: DATE_TIME_TYPE.LT,
            addZulu: false,
          })}
        </p>
      </div>
      <div className={styles["body"]}>
        <div className={styles["total-record"]}>
          <div className={bodyClassName}>
            {renderTimelineItemBody()}
          </div>
          <div
            className={clsx(
              styles["general-info"],
              currentEvent?.type === DATA_TYPE.chat && styles['general-info-chat'],
              currentEvent?.participantDTO?.length > 0
              && (currentEvent?.type === DATA_TYPE.chat || currentEvent?.type === DATA_TYPE.email) && styles["spacing-right"]
            )}
          >
            <img
              src={renderIcon(currentEvent?.type, currentEvent?.source)}
              alt={`${currentEvent?.type}`}
            />

            {currentEvent?.participantDTO?.length > 0 && (currentEvent?.type === DATA_TYPE.chat || currentEvent?.type === DATA_TYPE.email) ? (
              <MemberList
                data={currentEvent.participantDTO}
                className={memberListStyles[currentEvent?.type] || styles["member-list"]}
                limitMember={2}
                isEntity
                matterId={projectId}
                deferAvatarFetch={true}
              />
            ) : (
              <div className={styles["data-source"]}>
                {getDataSourceNameFromID(dataSourceList, [currentEvent?.dataSource])}
              </div>
            )}
          </div>
        </div>
      </div>
      {isShowDescription && (
        <>
          {loading ? (
            <div className={styles["des"]}>
              <Spinner
                className="spinner-loading"
                animation="border"
                variant="primary"
              />
            </div>
          ) : (
            <div
              className={styles["des"]}
              id="destination"
              style={
                currentIndex % 4 === 1 || currentIndex % 4 === 2
                  ? { left: 0 }
                  : { right: 0 }
              }
            >
              {isGroupEvents && (
                <div className={styles["des-doc-id-group"]}>
                  <p className={styles["des-label"]}>
                    {`${currentEvent?.type === DATA_TYPE.chat
                      ? "Message List"
                      : "Email List"
                      } (${data.length})`}
                  </p>
                  <div className={styles["doc-id-list"]}>
                    {renderDodIDList()}
                  </div>
                </div>
              )}
              <div
                className={clsx(
                  styles["des-body"],
                  [DATA_TYPE.chat].includes(currentEvent?.type)
                    ? styles["des-body-chat"]
                    : ""
                )}
              >
                {isLoadingContent ? (
                  <Spinner
                    className="spinner-loading"
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <div className={styles["des-header"]}>
                      <p className={styles["des-label"]}>
                        {description?.typeLabel || COMMON_TEXT.unknown}
                      </p>
                      <MemberList
                        data={currentEvent?.participantDTO}
                        showFromBottom
                        isEntity
                        matterId={projectId}
                        deferAvatarFetch={true}
                      />
                    </div>
                    <div className={styles["des-content"]}>
                      <div className={styles["des-detail"]}>
                        <ul>
                          {descriptionList(description?.type).map(
                            (item, index) => (
                              <li key={index} className={styles["des-item"]}>
                                <div className={styles["des-item-title"]}>
                                  {item.label || item}:
                                </div>
                                {renderValueDescription(item.key, item)}
                              </li>
                            )
                          )}
                        </ul>
                        <Button
                          name="View Detail"
                          fillType="full-width"
                          className="btn-primary-fill"
                          handleClick={goToDetail}
                        />
                      </div>
                      {description?.type === DATA_TYPE.chat && (
                        <ul className={styles["des-chat"]}>
                          {description?.instantMessages?.map((message) =>
                            renderPreviewChat(message)
                          )}
                        </ul>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

ItemTimeLine.propTypes = {
  currentIndex: PropTypes.number,
  isGroupEvents: PropTypes.bool,
  data: PropTypes.any,
};

export default ItemTimeLine;
