import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Row } from "react-bootstrap";
import clsx from "clsx";
import { useDispatch } from "react-redux";

// Store
import { setCanExecuteHotKey } from "store/TagReducer";

// Services
import { getAttachmentReviewApi } from "services";

// Helpers
import { checkCoordinate, getAddressAzureMap } from "helpers/LocationHelper";
import { isAllFieldEmpty } from "helpers/ObjectHelper";

// Constants
import { TITLE_ATTACHMENT_MODAL } from "constants/Constants";
import { COMMON_TEXT } from "constants/Common";
import { FILE_TYPE, SPREADSHEET_MIME_TYPES, WORD_MIME_TYPES, PRESENTATION_MIME_TYPES, ZIP_MIME_TYPES } from "constants/DataType";

//components
import PreviewVideo from "components/intelligent-search/search-result/preview/preview-video/PreviewVideo";
import AzureMaps from "components/shared/azure-maps/AzureMaps";
import PreviewAudio from "components/intelligent-search/search-result/preview/preview-audio/PreviewAudio";
import PreviewImage from "components/intelligent-search/search-result/preview/preview-image/PreviewImage";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import ItemResultInline from "components/shared/item-result-inline/ItemResultInline";
import RichTextAttachmentRenderer from "components/attachment-render/RichTextAttachmentRenderer";
import PdfAttachmentRenderer from "components/attachment-render/PdfAttachmentRenderer";
import DocumentAttachmentRenderer from "components/attachment-render/DocAttachmentRenderer";
import SpreadsheetAttachmentRenderer from "components/attachment-render/SpreadsheetAttachmentRenderer";
import PresentationAttachmentRenderer from "components/attachment-render/PresentationAttachmentRenderer";
import TabList from "components/shared/tab-list/TabList";
import DownloadAttachmentButton from "components/shared/download-attachment-button/DownloadAttachmentButton";
import TagSingleButton from "components/tag-management/tag-single-button/TagSingleButton";
import PreviewNotAvailable from "components/intelligent-search/search-result/preview/preview-not-available/PreviewNotAvailable";

// Helpers
import * as AttReviewHelper from "helpers/AttachmentReviewHelper";

//styles
import "./AttachmentReview.scss";

const AttachmentReview = (props) => {
  const dispatch = useDispatch();

  const {
    projectId,
    attachmentId,
    onHide,
    show
  } = props;

  const [dataAttach, setDataAttach] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(1);

  const fetchAttachmentReview = async () => {
    setLoading(true);
    try {
      const dataResult = await getAttachmentReviewApi(attachmentId, projectId);
      const { latitude, longitude, positionAddress } = dataResult.data;
      let address;
      if (positionAddress) address = positionAddress;
      else address = await getAddressAzureMap(latitude, longitude);
      setDataAttach({
        ...dataResult.data,
        address
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renderAttachments = () => {
    const { contentType } = dataAttach;
    if (contentType) {
      if (ZIP_MIME_TYPES.includes(contentType)) {
        return <PreviewNotAvailable />;
      }
      if (contentType.startsWith(FILE_TYPE.pdf)) {
        return (
          <div className="attachment_application">
            <PdfAttachmentRenderer
              attachmentId={dataAttach.attachmentID}
              projectId={projectId}
            />
          </div>
        )
      } else if (contentType.startsWith(FILE_TYPE.application) || contentType.startsWith(FILE_TYPE.text)) {

        if (SPREADSHEET_MIME_TYPES.includes(contentType)) {
          return (
            <div className="attachment_application">
              <SpreadsheetAttachmentRenderer
                attachmentId={dataAttach.attachmentID}
                projectId={projectId}
              />
            </div>
          )
        }

        if (WORD_MIME_TYPES.includes(contentType)) {
          return (
            <div className="attachment_application">
              <DocumentAttachmentRenderer
                attachmentId={dataAttach.attachmentID}
                projectId={projectId}
              />
            </div>
          )
        }

        if (PRESENTATION_MIME_TYPES.includes(contentType)) {
          return (
            <div className="attachment_application">
              <PresentationAttachmentRenderer
                attachmentId={dataAttach.attachmentID}
                projectId={projectId}
              />
            </div>
          )
        }

        if (dataAttach?.hasHtml === undefined) {
          return <PreviewNotAvailable />;
        }
        return (
          <div className="attachment_application">
            <RichTextAttachmentRenderer
              attachmentId={dataAttach.attachmentID}
              projectId={projectId}
            />
          </div>
        );
      } else if (contentType.startsWith(FILE_TYPE.image)) {
        return (
          <div className="attachment_img-view">
            <PreviewImage data={dataAttach} />
          </div>
        );
      } else if (contentType.startsWith(FILE_TYPE.video)) {
        return (
          <div className="attachment-video">
            <PreviewVideo matterId={projectId} attachmentId={dataAttach?.attachmentID} />
          </div>
        );
      } else if (contentType.startsWith(FILE_TYPE.audio)) {
        return <PreviewAudio matterId={projectId} attachmentId={dataAttach?.attachmentID} />;
      }
    } else {
      return (
        <div className="unknown-type">
          <p>{COMMON_TEXT.unknown}</p>
        </div>
      );
    }
  };

  const checkAttachmentNotAvailable = () => isAllFieldEmpty(dataAttach);

  const generalMetadata = [
    {
      label: "Content Type",
      value: dataAttach.contentType,
    },
    {
      label: "Record Type",
      value: dataAttach.recordType,
    },
    {
      label: "File Name",
      value: dataAttach.filename,
    },
    {
      label: "File Size",
      value: dataAttach.fileSize === 0 ? null : AttReviewHelper.formatDisplayBytes(dataAttach.fileSize),
    },
    {
      label: "File Path",
      value: dataAttach.filePath,
    },
    {
      label: "File Container Path",
      value: dataAttach.fileContainerPath,
    },
    {
      label: "Image Date Modified",
      value: AttReviewHelper.getFormattedDateTime(dataAttach.imageDateModified, false),
    },
    {
      label: "Image Date Accessed",
      value: AttReviewHelper.getFormattedDateTime(dataAttach.imageDateAccessed, false),
    },
    {
      label: "Image Date Deleted",
      value: AttReviewHelper.getFormattedDateTime(dataAttach.imageDateDeleted, false),
    },

    {
      label: "Created Date",
      value: AttReviewHelper.getFormattedDateTime(dataAttach.createdDate, true),
    },
    {
      label: "Created Time",
      value: AttReviewHelper.getStringDisplay(dataAttach.createdTime),
    },
    {
      label: "Last Modified Date",
      value: AttReviewHelper.getFormattedDateTime(dataAttach.lastModifiedDate, true),
    },
    {
      label: "Print Date",
      value: AttReviewHelper.getFormattedDateTime(dataAttach.printDate, true),
    },
    {
      label: "Image Resolution",
      value: dataAttach.imageResolution,
    },
    {
      label: "Created By",
      value: AttReviewHelper.getStringDisplay(dataAttach.fileCreatedBy),
    },
    {
      label: "Last Modified By",
      value: AttReviewHelper.getStringDisplay(dataAttach.lastModifiedBy),
    },
    {
      label: "Language",
      value: AttReviewHelper.getStringDisplay(dataAttach.language),
    },
    {
      label: "MAPI Message Class",
      value: AttReviewHelper.getStringDisplay(dataAttach.mapiMessageClass),
    },
    {
      label: "Sent Representing Email",
      value: AttReviewHelper.getStringDisplay(dataAttach.sentRepresentingEmail),
    },
    {
      label: "Subject",
      value: AttReviewHelper.getStringDisplay(dataAttach.subject),
    },
    {
      label: "To",
      value: AttReviewHelper.getStringDisplay(dataAttach.to),
    },
    {
      label: "To Email",
      value: AttReviewHelper.getStringDisplay(dataAttach.toEmail),
    },
    {
      label: "To Name",
      value: AttReviewHelper.getStringDisplay(dataAttach.toName),
    },
  ];

  const locationInfo = [
    {
      label: "Position",
      value: `${parseFloat(dataAttach.latitude)}, ${parseFloat(
        dataAttach.longitude
      )}`,
    },
    {
      label: "Address",
      value: AttReviewHelper.getLocationMapValue(dataAttach.address),
    },
  ];

  useEffect(() => {
    fetchAttachmentReview();
    // Don't execute hot key tag when show popup attachment
    if (show) dispatch(setCanExecuteHotKey(false));
    return () => dispatch(setCanExecuteHotKey(true));
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="attachment-review-modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="attachment-review-title"
          id="contained-modal-title-vcenter"
        >
          {TITLE_ATTACHMENT_MODAL.attachment}
        </Modal.Title>
      </Modal.Header>
      {loading ? (
        <div className="loading-map">
          <SpinnerLoading />
        </div>
      ) : (
        <Modal.Body>
          {checkAttachmentNotAvailable() ? (
            <Row className="attachment-content">
              <div className="loading-map">
                <h4 className="font-weight-bold">
                  This attachment is not available.
                </h4>
              </div>
            </Row>
          ) : (
            <Row className="attachment-content">
              <div className="attachment-info">
                <div
                  className={clsx("info-detail")}>
                  <h5 className="info-detail-label">Properties</h5>
                  {generalMetadata
                    .filter((item) => item.value !== null && item.value !== undefined && item.value !== "")
                    .map((item) => (
                      <ItemResultInline
                        key={item.label}
                        label={item.label}
                        value={item.value}
                      />
                    ))}
                </div>
              </div>
              <div className="preview-tabs">
                <div className="preview-tabs-header">
                  <div className="preview-tabs-header-item">
                    <TabList
                      currentTab={{ parentTab: currentTab }}
                      tabList={[
                        { id: 1, name: "Preview" },
                        {
                          id: 2, name: "Location Info", disabled: !checkCoordinate(
                            dataAttach.latitude,
                            dataAttach.longitude
                          )
                        },
                      ]}
                      onClickTab={({ parentTab }) => setCurrentTab(parentTab)}
                    />
                  </div>
                  <div className="preview-tabs-header-item">
                    <DownloadAttachmentButton
                      matterId={projectId}
                      attachmentId={attachmentId}
                    />
                    <TagSingleButton
                      matterId={projectId}
                      attachmentId={attachmentId}
                    />
                  </div>
                </div>
                <div className={currentTab === 1 ? "attachment-tab" : "hidden-tab"}>
                  {renderAttachments()}
                </div>
                {currentTab === 2 && (
                  <div className="location-info-tab">
                    {checkCoordinate(
                      dataAttach.latitude,
                      dataAttach.longitude
                    ) ? (
                      <>
                        <div className="location-info-map">
                          <AzureMaps
                            dataAttach={[
                              {
                                latitude: dataAttach.latitude,
                                longitude: dataAttach.longitude,
                                address: dataAttach.address,
                              },
                            ]}
                            isMultiple={false}
                          />
                        </div>
                        <div
                          className={clsx("location-info-detail")}
                        >
                          <h5 className="location-info-detail-label">Location Info</h5>
                          {locationInfo.map((item) => (
                            <ItemResultInline
                              key={item.label}
                              label={item.label}
                              value={item.value}
                            />
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className='location-not-found'>
                        <img
                          src="/images/image-notfound.png"
                          alt="Attachment file"
                        />
                        <p className="font-weight-bold">
                          Location Information is not available.
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Row>
          )}
        </Modal.Body>
      )}
    </Modal>
  );
};

AttachmentReview.propTypes = {
  projectId: PropTypes.number.isRequired,
  attachmentId: PropTypes.string.isRequired,
  onHide: PropTypes.func,
  show: PropTypes.bool
};

export { AttachmentReview };
