import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import FadeLoader from "react-spinners/FadeLoader";

// Components
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import EmptyPage from "components/shared/empty-page/EmptyPage";

// Constants
import { COLORS } from "constants/Common";

// Styles
import styles from "./ViewLog.module.scss";

const ViewLog = (props) => {
  const {
    show = true,
    onClose,
    logs = [],
    loading = false,
    isImporting = false,
  } = props;

  useEffect(() => {
    if (loading) return;
    const element = document.getElementById(`log-${logs.length - 1}`);
    if (element)
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
  }, [logs, loading]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      className={styles["modal-container"]}
      dialogClassName={styles["modal-dialog"]}
      contentClassName={styles["modal-content"]}
    >
      <Modal.Header closeButton className={styles["modal-header"]}>
        <span className={styles["title"]}>Recent Logs</span>
      </Modal.Header>
      <Modal.Body className={styles["modal-body"]}>
        {loading ? (
          <SpinnerLoading />
        ) : logs.length > 0 ? (
          logs.map((item, index) => (
            <p
              className={item.isFailed ? styles["err"] : ""}
              key={index}
              id={`log-${index}`}
            >
              {item.content}
            </p>
          ))
        ) : (
          <EmptyPage size="md" messages="No logs yet. Please import data." />
        )}
      </Modal.Body>
      <Modal.Footer className={styles["modal-footer"]}>
        {isImporting && !loading && logs.length > 0 && (
          <div className={styles["loading"]}>
            <FadeLoader
              className={styles["fade-loader"]}
              color={COLORS.blue}
              height={5}
              width={2}
              margin={-10}
            />
            <span>Loading...</span>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ViewLog.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  isImporting: PropTypes.bool,
  onClose: PropTypes.func,
  logs: PropTypes.arrayOf(
    PropTypes.shape({ content: PropTypes.string, isFailed: PropTypes.bool })
  ),
};

export default ViewLog;
