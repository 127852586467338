import axios, { authHeader, getAbortController } from "./ApiConfig";
import { METHOD_API } from "constants/Common";

const getSourceInfo = (projectId, isImported = false) =>
  axios.get(
    `/importmanagement/${projectId}/sourceInfo?isImported=${isImported}`,
    {
      headers: authHeader(),
      signal: getAbortController().signal,
    }
  );

const collectionTypeApi = () =>
  axios.get("/importmanagement/collectionType", {
    headers: authHeader(),
  });

const updateImportInfo = (params = {}) => {
  const { projectId, dataSourceID, blobCount, blobBytes } = params;
  return axios.get(`/importmanagement/${projectId}/updateImportInfo`, {
    headers: authHeader(),
    params: {
      projectId,
      dataSourceID,
      blobCount,
      blobBytes,
      signal: getAbortController().signal,
    },
  });
};

const cancelImportApi = (projectId, params) =>
  axios.get(`/importmanagement/${projectId}/cancelImport`, {
    headers: authHeader(),
    params,
  });

const blobImportApi = (data) =>
  axios(`/blobImport`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data,
  });

const getLogImportApi = (dataSourceID) =>
  axios(`/blobImport/${dataSourceID}`, {
    headers: authHeader(),
    method: METHOD_API.get,
  });

export {
  getSourceInfo,
  updateImportInfo,
  collectionTypeApi,
  cancelImportApi,
  blobImportApi,
  getLogImportApi,
};
