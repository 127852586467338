import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useToken } from "hook/auth";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import PropTypes from "prop-types";
import clsx from "clsx";

// Constants
import { UserProfileValidation as Message } from "constants/UserProfileValidation";
import { FILE_SIZE, IMAGE_TYPE_ACCEPTED_STRING } from "constants/Constants";
import { FIELD_VALIDATION } from "constants/RegexConstant";
import { AUTH_ROLE, ROLE_LIST_ADMIN, YUP_METHOD, YUP_TEST } from "constants/AuthConstant";

// Services
import { getManageUserByID } from "services/UserAdministrationService";
import { getClients } from "services/ProjectAdministrationService";

// Components
import { Button } from "components/shared/button/Button";
import { DropdownSelector } from "components/shared/dropdown-selector/DropdownSelector";
import UserAvatar from "components/shared/file-components/user-avatar/UserAvatar";

// Helpers
import { getFileTypeFromName } from "helpers/GetFileNameHelper";

// Constant
import { DEFAULT_NAME } from "constants/Common";

// Styles
import "react-phone-input-2/lib/bootstrap.css";
import styles from "./EditUserModal.module.scss";

export const EditUserModal = (props) => {
  const {
    isShow = false,
    handleClose,
    handleSubmit,
    userId,
    errors,
    onClearErrorText,
  } = props;
  const { isAdmin } = useToken();

  const [loading, setLoading] = useState(false);
  const [initValue, setInitValue] = useState({});
  const [isInitValue, setIsInitValue] = useState(false);
  const [clients, setClients] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);

  Yup.addMethod(Yup.string, YUP_METHOD.phoneLength, function (errorMessage) {
    return this.test(YUP_TEST.minMax, errorMessage, function (value) {
      const { path, createError } = this;
      return (
        (value &&
          value.match(/[0-9]/g).length >= 9 &&
          value.match(/[0-9]/g).length <= 20) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  Yup.addMethod(Yup.string, YUP_METHOD.emailValid, function (errorMessage) {
    return this.test(YUP_TEST.specialKeyword, errorMessage, function (value) {
      return value?.match(FIELD_VALIDATION.emailUserModal);
    });
  });

  const selectAccountId = (name) => {
    const clientsList = clients.find((item) => item.name === name);
    return clientsList ? clientsList.id : "";
  };

  const formik = useFormik({
    initialValues: initValue,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .max(100, Message.firstName.max_length)
        .required(Message.firstName.required),
      lastName: Yup.string()
        .trim()
        .max(100, Message.lastName.max_length)
        .required(Message.lastName.required),
      email: Yup.string()
        .trim()
        .emailValid(Message.email.invalid)
        .max(100, Message.email.max_length)
        .required(Message.email.required),
      phoneNumber: Yup.string()
        .trim()
        .phoneLength(Message.phoneNumber.length)
        .matches(FIELD_VALIDATION.phoneNumber, Message.phoneNumber.invalid)
        .required(Message.phoneNumber.required),
      image: Yup.mixed()
        .test({
          message: Message.avatar.file_type,
          test: (image) =>
            image
              ? IMAGE_TYPE_ACCEPTED_STRING.includes(
                  getFileTypeFromName(image.name)
                )
              : true,
        })
        .test({
          message: Message.avatar.file_size,
          test: (image) => (image ? image.size <= FILE_SIZE.MB20 : true),
        })
        .test({
          message: Message.avatar.required,
          test: (image) => (image ? image.size !== 0 : true),
        }),
    }),
    onSubmit: (values) => {
      const accountId =
        selectAccountId(values.clientName) || formik.values.accountId;
      handleSubmit({ ...values, accountId });
    },
  });

  const fetchDataClient = async () => {
    try {
      const result = await getClients();
      if (!result || !result.data) return;
      const clientList = result.data.map((item) => ({
        name: item.clientName,
        id: item.clientId,
      }));
      setClients(clientList);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataManageUserByID = async () => {
    try {
      setLoading(true);
      const result = await getManageUserByID(userId);
      if (!result || !result.data) return;
      const initState = {
        firstName: result.data.firstName,
        lastName: result.data.lastName,
        email: result.data.email,
        role: result.data.roles[0],
        clientName: result.data.clientName
          ? result.data.clientName
          : DEFAULT_NAME.downstreemAdmin,
        areaCode: result.data.areaCode,
        countryCode: result.data.countryCode,
        phoneNumber: result.data.phoneNumber,
        image: null,
        accountId: result.data.accountId,
      };
      setInitValue(initState);
      formik.setValues(initState);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const choosePhoto = () => {
    document.getElementById("image").click();
  };

  const onChangeImgInput = (event) => {
    const { files } = event.currentTarget;
    if (files.length > 0) formik.setFieldValue("image", files[0]);
  };

  const onChangePhoneNumber = (e) => {
    const phone = e.target.value;
    if (FIELD_VALIDATION.phoneUserModal.test(phone)) formik.handleChange(e);
    else !phone && formik.handleChange(e);
  };

  const onSelectRole = (event) => {
    formik.setFieldValue("role", event.name);
    let clientName;
    if (event.name === AUTH_ROLE.admin)
      clientName = DEFAULT_NAME.downstreemAdmin;
    else if (initValue.clientName === DEFAULT_NAME.downstreemAdmin)
      clientName = clients[0].name;
    else clientName = initValue.clientName;
    formik.setFieldValue("clientName", clientName);
  };

  useEffect(() => {
    fetchDataClient();
    fetchDataManageUserByID();
  }, []);

  useEffect(() => {
    onClearErrorText();
    if (formik.values.image)
      setPreviewImage(URL.createObjectURL(formik.values.image));

    setIsInitValue(JSON.stringify(formik.values) !== JSON.stringify(initValue));
  }, [formik.values]);

  return (
    <Modal
      className={styles["modal-container"]}
      show={isShow}
      onHide={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Modal.Header className={styles["modal-header"]}>
            <p className={styles["modal-header-title"]}>Edit user</p>
            <div onClick={handleClose} className={styles["header-close"]}>
              <img src="/images/close-modal-icon.png" alt="Close" />
            </div>
          </Modal.Header>
          {loading ? (
            <div
              className={
                isAdmin() ? styles["loading-admin"] : styles["loading"]
              }
            >
              <Spinner
                className="spinner-loading"
                animation="border"
                variant="primary"
              />
            </div>
          ) : (
            <Modal.Body className={styles["modal-body"]}>
              <div className={styles["user-information__photo"]}>
                <figure className={styles["user-information__photo-img"]}>
                  {previewImage ? (
                    <img src={previewImage} alt="User Avatar" />
                  ): (
                    <UserAvatar userId={userId} />
                  )}
                </figure>
                <div className={styles["user-information__photo-create"]}>
                  <input
                    type="file"
                    hidden
                    id="image"
                    name="image"
                    onChange={onChangeImgInput}
                  />
                  <Button
                    type="button"
                    name="Upload Photo"
                    iconUrl="/images/ic-upload.svg"
                    handleClick={choosePhoto}
                  />
                  {(formik.errors.image || formik.touched.image) && (
                    <span className="error text-danger">
                      {formik.errors.image}
                    </span>
                  )}
                </div>
              </div>

              <div className="mar_b24 d-flex justify-content-between w-100">
                <div className={clsx("mar_r16", styles["w-50"])}>
                  <label
                    htmlFor="firstName"
                    className={styles["modal-body-label"]}
                  >
                    First Name <span className="error text-danger">*</span>
                  </label>
                  <input
                    className={clsx("form-control", styles["modal-body-input"])}
                    type="text"
                    placeholder="Enter First Name"
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.firstName && formik.touched.firstName && (
                    <span className="error text-danger">
                      {formik.errors.firstName}
                    </span>
                  )}
                </div>
                <div className={styles["w-50"]}>
                  <label
                    htmlFor="lastName"
                    className={styles["modal-body-label"]}
                  >
                    Last Name <span className="error text-danger">*</span>
                  </label>
                  <input
                    className={clsx("form-control", styles["modal-body-input"])}
                    type="text"
                    placeholder="Enter Last Name"
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.lastName && formik.touched.lastName && (
                    <span className="error text-danger">
                      {formik.errors.lastName}
                    </span>
                  )}
                </div>
              </div>
              <div className="mar_b24">
                <label htmlFor="email" className={styles["modal-body-label"]}>
                  Email Address <span className="error text-danger">*</span>
                </label>
                <input
                  className={clsx("form-control", styles["modal-body-input"])}
                  type="text"
                  placeholder="Enter Email Address"
                  id="email"
                  name="email"
                  value={formik.values.email?.trim()}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && formik.touched.email ? (
                  <span className="error text-danger">
                    {formik.errors.email}
                  </span>
                ) : (
                  errors === Message.email.exists && (
                    <span className="error text-danger">{errors}</span>
                  )
                )}
              </div>
              <div className="mar_b24">
                <label htmlFor="role" className={styles["modal-body-label"]}>
                  Role
                </label>
                {isAdmin() ? (
                  <DropdownSelector
                    widthStyle="auto"
                    size="md"
                    className={clsx("form-select", styles["modal-body-select"])}
                    itemList={ROLE_LIST_ADMIN}
                    id="role"
                    name="role"
                    selectedItem={formik.values.role}
                    onSelect={onSelectRole}
                    isNormalLetter
                  />
                ) : (
                  <input
                    className={clsx("form-control", styles["modal-body-input"])}
                    type="text"
                    value={formik.values.role}
                    id="role"
                    name="role"
                    disabled
                  />
                )}
              </div>
              <div className="mar_b24">
                <label
                  htmlFor="clientName"
                  className={styles["modal-body-label"]}
                >
                  Client
                </label>
                {isAdmin() ? (
                  <DropdownSelector
                    widthStyle="auto"
                    size="md"
                    className={clsx("form-select", styles["modal-body-select"])}
                    itemList={clients}
                    id="clientName"
                    name="clientName"
                    selectedItem={
                      formik.values.role === AUTH_ROLE.admin
                        ? DEFAULT_NAME.downstreemAdmin
                        : formik.values.clientName
                    }
                    onSelect={(event) => {
                      formik.setFieldValue("clientName", event.name);
                    }}
                    isLoading={loading}
                    isNormalLetter
                    isDisable={formik.values.role === AUTH_ROLE.admin}
                  />
                ) : (
                  <input
                    id="clientName"
                    name="clientName"
                    className={clsx("form-control", styles["modal-body-input"])}
                    type="text"
                    value={formik.values.clientName}
                    disabled
                  />
                )}
              </div>
              <div className={styles["phone-input"]}>
                <label
                  htmlFor="phoneNumber"
                  className={styles["modal-body-label"]}
                >
                  Phone Number
                  <div className={styles["tooltip-info-icon"]}>
                    <img src="/images/icons/info-icon.svg" alt="Info icon" />
                    <div className={styles["tooltip-info"]}>
                      <p>
                        Please use your Cell Phone Number or Mobile Phone Number
                      </p>
                    </div>
                  </div>
                </label>
                <div className="d-flex justify-content-between">
                  <div className={styles["area-code-group"]}>
                    <PhoneInput
                      className="d-flex align-items-center"
                      country={formik.values.countryCode || "us"}
                      id="areaCode"
                      name="areaCode"
                      value={formik.values.areaCode}
                      onChange={(event, value) => {
                        formik.setFieldValue("areaCode", `+${event}`);
                        formik.setFieldValue("countryCode", value.countryCode);
                      }}
                    />
                    <span className={styles["area-code"]}>
                      {formik.values.areaCode}
                    </span>
                  </div>
                  <div className={styles["phone-number"]}>
                    <input
                      className={clsx(
                        "form-control",
                        styles["modal-body-input"]
                      )}
                      type="text"
                      placeholder="xxx-xxx-xxxx"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={formik.values.phoneNumber}
                      onChange={onChangePhoneNumber}
                    />
                    {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                      <span className="error text-danger">
                        {formik.errors.phoneNumber}
                      </span>
                    ) : (
                      errors === Message.phoneNumber.exists && (
                        <span className="error text-danger">
                          Phone number has already existed.
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
          )}
          <Modal.Footer className={styles["modal-footer"]}>
            <Button name="Cancel" handleClick={handleClose} />
            <Button
              name="Save"
              isBorder={false}
              className="btn-primary-fill"
              type="submit"
              isDisabled={!!(formik.errors.image || !isInitValue)}
            />
          </Modal.Footer>
        </div>
      </form>
    </Modal>
  );
};

EditUserModal.propTypes = {
  isShow: PropTypes.bool,
  userId: PropTypes.string,
  errors: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  onClearErrorText: PropTypes.func,
};
