import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import { ParticipantsInformation } from "components/chat-email-information/participants/ParticipantsInformation";
import DataSourceInformation from "components/chat-email-information/data-source-info/DataSourceInformation";

// Styles
import styles from "./InfoChatEmail.module.scss";

const InfoChatEmail = ({
  isLoading = false,
  dataSources = [],
  participants = [],
  isPreviewSearch = false,
}) => {
  return (
    <div
      className={clsx(
        styles["wrap"],
        isPreviewSearch ? styles["wrap-preview"] : ""
      )}
    >
      <div className={styles["data-source-info"]}>
        <DataSourceInformation
          isLoading={isLoading}
          dataSources={dataSources}
          isShowCollapse={false}
        />
      </div>
      <div className={styles["participants-info"]}>
        <ParticipantsInformation
          isLoading={isLoading}
          participants={participants}
          isRedColor={true}
          isShowCollapse={false}
        />
      </div>
    </div>
  );
};

InfoChatEmail.propTypes = {
  isPreviewSearch: PropTypes.bool,
  isLoading: PropTypes.bool,
  dataSources: PropTypes.arrayOf(
    PropTypes.shape({
      dataSourceID: PropTypes.number,
      custodianName: PropTypes.string,
      dataSourceTypeName: PropTypes.string,
      deviceName: PropTypes.string,
    })
  ),
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      messageCount: PropTypes.number,
      name: PropTypes.string,
      identifier: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
        })
      ),
    })
  ),
};

export default InfoChatEmail;
