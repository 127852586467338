import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Components
import ImportProcessItem from "./import-process-item/ImportProcessItem";
import { Button } from "components/shared/button/Button";

// Constants
import { STATUS_IMPORT_BLOB } from "constants/Common";
import { IMPORT_BLOB_ACTION } from "constants/DataSourceConstant";

// Helpers
import { findIdCanImport, getTotalSizeInFiles } from "helpers/DataSourceHelper";
import {
  displaySmallBorderDrag,
  displayLargeBorderDrag,
  clearBorderDrag,
} from "helpers/CommonHelper";
import { uploadFileToBlob } from "helpers/ImportUploadHelpers";

// Reducers
import {
  removeListImportItemById,
  setAction,
  setCurrentImportId,
  setIsCancelImport,
  addListImportItem,
  updateListImportItem,
} from "store/ImportBlobReducer";

// Styles
import styles from "./ImportProcessManagement.module.scss";

const ImportProcessManagement = (props) => {
  const { canImport = true, dataSourceID = "", deviceName = "" } = props;

  const sourcePathId = "sourcePathId";
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const { currentImportId, listImport, loading } = useSelector(
    (state) => state.importBlob
  );

  const totalImportOfSource = listImport.filter(
    (item) => item.projectId === projectId && item.dataSourceID === dataSourceID
  ).length;

  const addImportListItem = (id, files, folderName) => {
    dispatch(
      addListImportItem(
        {
          id,
          folderName,
          files,
          status: STATUS_IMPORT_BLOB.ready,
          isDisable: true,
          isPause: false,
          projectId,
          dataSourceID,
        },
      )
    );
  };

  const addNewImport = (files, folderName) => {
    const importId = uuidv4();
    addImportListItem(importId, Object.values(files), folderName);
    !currentImportId && dispatch(setCurrentImportId(importId));
    dispatch(setAction(""));
  };

  const handleSelectFile = (event) => {
    const { files = [] } = event.target;
    if (files.length === 0) return;
    const extensionFolder = files[0]?.webkitRelativePath?.split("/");
    const folderName = extensionFolder[0];
    addNewImport(Object.values(files), folderName);
  };

  const checkMultiFolder = (files = []) => {
    if (files.length === 0) return false;
    const firstFolder = files[0].path.split("/")[1];
    if (files.find((item) => item.path.split("/")[1] !== firstFolder))
      return true;
    return false;
  };

  const onDrop = (files = []) => {
    // not allow drag drop file
    displaySmallBorderDrag(sourcePathId);
    if (
      files.length === 0 ||
      !files[0].path.includes("/") ||
      checkMultiFolder(files)
    ) {
      return;
    }
    const folderName = files[0].path.split("/")[1];
    addNewImport(files, folderName);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragOver: () => displayLargeBorderDrag(sourcePathId),
    onDragLeave: () => displaySmallBorderDrag(sourcePathId),
  });

  const onDeleteImport = (importId) => {
    const imports = listImport.filter((item) => item.id !== importId);
    if (importId === currentImportId) {
      uploadFileToBlob({}, true);
      dispatch(setIsCancelImport(true));
      dispatch(setCurrentImportId(findIdCanImport(imports, currentImportId)));
    }
    dispatch(removeListImportItemById(importId));
    dispatch(setAction(""));
  };

  const renderNoImport = () => (
    <div className={styles["no-data"]}>
      <img src="/images/icons/not-process.svg" alt="" />
      <p>
        {canImport
          ? "Don't have any process running at this moment"
          : "Can not import any process"}
      </p>
    </div>
  );

  const listImportOfSource = listImport.filter(
    (item) => item.projectId === projectId && item.dataSourceID === dataSourceID
  );

  const handleImportBlob = (importId, status) => {
    const statusRequest =
      status === STATUS_IMPORT_BLOB.inprogress
        ? STATUS_IMPORT_BLOB.pause
        : STATUS_IMPORT_BLOB.inprogress;

    dispatch(
      updateListImportItem({
        id: importId,
        status: statusRequest
      })
    );
    if (statusRequest === STATUS_IMPORT_BLOB.pause) {
      dispatch(setAction(IMPORT_BLOB_ACTION.pause));
      uploadFileToBlob({}, true);
      dispatch(setIsCancelImport(true));
      dispatch(setCurrentImportId(findIdCanImport(listImport, currentImportId)));
    } else {
      dispatch(setCurrentImportId(importId));
      dispatch(setAction(IMPORT_BLOB_ACTION.resume));
    }
  };

  useEffect(() => {
    if (canImport) displaySmallBorderDrag(sourcePathId);
    else clearBorderDrag(sourcePathId);
  }, [canImport]);

  return (
    <div className={styles["wrap"]}>
      <div className={styles["process-header"]}>
        <p className={styles["title"]}>Import Process</p>
        <span className={styles["total"]}>{`${totalImportOfSource} ${
          totalImportOfSource > 1 ? "Processes" : "Process"
        }`}</span>
      </div>
      <p
        className={styles["description"]}
        title={`Importing data for ${deviceName}`}
      >
        Importing data for {deviceName}
      </p>
      <div className={styles["process-body"]}>
        <div
          className={clsx(
            styles["import-file-container"],
            canImport ? "" : styles["cannot-import"],
            "app-form-group"
          )}
        >
          <div className={styles["file-button"]}>
            <Button
              isDisabled={!canImport}
              name="Browse..."
              className="btn-medium"
            />
            <input
              type="file"
              name="file"
              multiple
              webkitdirectory=""
              title=""
              onClick={(e) => {
                e.target.value = null;
              }}
              onChange={handleSelectFile}
            />
            <input
              type="file"
              name="file"
              multiple
              webkitdirectory=""
              onChange={handleSelectFile}
              {...getInputProps()}
            />
          </div>
          <Form.Control
            type="text"
            className={
              canImport ? "app-form-input-md" : "app-form-input-md-disable"
            }
            defaultValue={"Or drop folder here"}
            id={sourcePathId}
            {...getRootProps()}
            onClick={() => {}}
          />
        </div>
        <div className={styles["import-list"]}>
          {listImportOfSource.length > 0
            ? listImportOfSource.map((item, index) => (
                <div
                  className={clsx(
                    styles["process-item"],
                    index < listImport.length - 1 ? styles["bottom-border"] : ""
                  )}
                  key={item.id}
                >
                  <ImportProcessItem
                    folderName={item.folderName}
                    totalBytes={getTotalSizeInFiles(item.files)}
                    onDeleteImport={() => onDeleteImport(item.id)}
                    status={item.status}
                    isDisable={item.isDisable}
                    percent={item.percent}
                    bytesImported={getTotalSizeInFiles(item.listByte)}
                    isFailed={item?.isFailed}
                    isPause={item?.isPause}
                    handleImportBlob={() =>
                      handleImportBlob(item.id, item.status)
                    }
                    listImport={listImport}
                    loading={loading && currentImportId === item.id}
                  />
                </div>
              ))
            : renderNoImport()}
        </div>
      </div>
    </div>
  );
};

ImportProcessManagement.propTypes = {
  canImport: PropTypes.bool,
  dataSourceID: PropTypes.number,
  deviceName: PropTypes.string,
};

export default ImportProcessManagement;
