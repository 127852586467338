import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listImport: [],
  currentImportId: "",
  isCancelImport: false,
  isSuccess: false,
  action: "",
  loading: 0,
};

const crSlice = createSlice({
  name: "importBlob",
  initialState,
  reducers: {
    addListImportItem: (state, action) => {
      state.listImport = [...state.listImport, action.payload];
    },
    updateListImportItem: (state, action) => {
      state.listImport = state.listImport.map((item) => {
        if (item.id === action.payload.id) return { ...item, ...action.payload };
        return item;
      });
    },
    removeListImportItemById: (state, action) => {
      state.listImport = state.listImport.filter((item) => item.id !== action.payload);
    },
    setCurrentImportId: (state, action) => {
      state.currentImportId = action.payload;
    },
    setIsCancelImport: (state, action) => {
      state.isCancelImport = action.payload;
    },
    setIsSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
    setAction: (state, action) => {
      state.action = action.payload;
    },
    setImportLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addListImportItem,
  updateListImportItem,
  removeListImportItemById,
  setCurrentImportId,
  setIsCancelImport,
  setIsSuccess,
  setAction,
  setImportLoading,
} = crSlice.actions;
export const ImportBlobReducer = crSlice.reducer;
