import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import camelCase from "lodash/camelCase";
import PropTypes from "prop-types";
import clsx from "clsx";

// Services
import * as MassTagService from "services/MassTagService";

// Components
import { DateSelector } from "components/shared/date-picker/DateSelector";
import { PaginationResult } from "components/shared/paging/PaginationResult";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";
import Toggle from "components/shared/toggle/Toggle";
import SourceTypeList from "components/shared/source-type-list/SourceTypeList";
import Checkbox from "components/shared/checkbox/Checkbox";
import SearchResultTagging from "./SearchResultTagging";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";


// Store
import { setPaging } from "store/GeneralReviewReducer";

// Helpers
import { getDeletedStatus } from "helpers/TextHelper";
import { getDuration, handleStringToolong } from "helpers/FormatterHelper";
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { getDataSourceNameFromID, renderClassSort } from "helpers/CommonHelper";
import { getTagShowNamesFromIds } from "helpers/TagHelper";

// Constants
import { DATE_TIME_TYPE, SORT_BY, TAG_ACTION } from "constants/Constants";
import { COMMON_TEXT, PAGINATION } from "constants/Common";
import {
  CHAT_EMAIL_TYPE,
  DATA_TYPE
} from "constants/DataType";
import { COLUM_RESULT, INITIAL_SORT } from "constants/StreemConstant";
import {
  GetMassTagToastId,
  GetMassTagConfirmationMsg,
  MASS_TAG_TOAST_MSG,
} from "constants/MassTagConstants";

// Styles
import styles from "./SearchResult.module.scss";
import { toast } from "react-toastify";
import { setSearchContext, setSearchContextQuickFilter } from "store/MassTagReducer";

export const SearchResult = (props) => {
  const {
    loading,
    onClickPagingHandle,
    isResultsData,
    onSubmitSearch,
    inputRef,
    onSubmitDate,
    onSort,
    onSwitchRecord,
    paging,
    searchInput,
    handleToggle,
    isShowRecent,
    searchInputRef,
    dataSourceList,
  } = props;

  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [sortColumns, setSortColumns] = useState(INITIAL_SORT);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [showConfirmMassTag, setShowConfirmMassTag] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagAction, setTagAction] = useState(null);

  const { searchContext } = useSelector((state) => state.massTag);

  const datePickerData = useSelector(
    (state) => state.datePicker
  );

  const searchError = useSelector(
    (state) => state.generalRV.searchError
  )

  const { showTags } = useSelector((state) => state.tag.tags);

  const onShowDateFilter = () => setShowDateFilter(true);
  const onHideDateFilter = () => setShowDateFilter(false);

  // Handle paging
  const onClickPagingHandleChild = (data) => {
    setSelectedItems([]);
    setIsSelectedAll(false);
    onClickPagingHandle(data);
    dispatch(setPaging({ recordNumber: 0 }));
  };

  const onHandleSubmitDate = (data) => {
    onSubmitDate(data);
    onHideDateFilter();
    dispatch(setSearchContextQuickFilter({
      ...searchContext.quickFilter,
      start: data.dateTimeDataStart,
      end: data.dateTimeDataEnd,
      includeBlankDate: data.includeBlankDate
    }));
  };

  // Handle sort table
  const onSortTable = (column, type) => {
    setSelectedItems([]);
    // Only sort one column per time
    const sortType =
      type === ""
        ? SORT_BY.asc
        : type === SORT_BY.asc
          ? SORT_BY.desc
          : SORT_BY.asc;
    setSortColumns({ ...INITIAL_SORT, [column]: sortType });
    if (
      column !== COLUM_RESULT.dateTime.key ||
      column !== COLUM_RESULT.dateLast.key
    )
      setSortColumns({
        ...INITIAL_SORT,
        DateTime: SORT_BY.asc,
        DateLast: SORT_BY.asc,
        [column]: sortType,
      });
    else setSortColumns({ ...INITIAL_SORT, [column]: sortType });
    onSort({
      orderBy: sortType,
      columnSort: column,
    });
  };

  // Handle change icon sort
  const classSort = (columnName) => renderClassSort(columnName, styles);

  const getDescription = (data) => {
    const { description, type } = data;
    if (description) {
      if ([DATA_TYPE.callLog, DATA_TYPE.voicemail].includes(type))
        return getDuration(description);
      else {
        if (type === DATA_TYPE.chatGroup)
          return handleStringToolong(description, 39);
        else return <span>{description}</span>;
      }
    } else return COMMON_TEXT.default;
  };

  const handleCheckboxClick = (event, index) => {
    event.stopPropagation(); // Stop the row "onClick" from firing
    if (isSelectedAll) {
      setIsSelectedAll(false);
    }
    if (selectedItems.includes(index)) {
      setSelectedItems(selectedItems.filter(item => item !== index))
    } else {
      setSelectedItems([...selectedItems, index]);
    }
  };

  const handleSelectAll = () => {
    const newSelectedItems = [];
    isResultsData.forEach((item, index) => {
      newSelectedItems.push(index);
    });
    setSelectedItems(newSelectedItems);
    setIsSelectedAll(true);
  }

  const handleUnselectAll = () => {
    setSelectedItems([]);
    setIsSelectedAll(false);
  };

  const handleSaveSelectTags = (tags, action) => {
    setSelectedTags(tags);
    setTagAction(action);
    setShowConfirmMassTag(true);
  };

  const applyMassTags = async () => {
    setShowConfirmMassTag(false);

    let requestData;

    if (isSelectedAll) {
      requestData = {
        MassTagType: 1,
        TagIds: [...selectedTags],
        MassTagSearch: {
          FilterSearchQuery: searchContext?.quickFilter?.filterSearchQuery,
          IntelligentSearchQuery: searchContext?.quickFilter?.intelligentSearchQuery,
          DataSourceIds: searchContext?.quickFilter?.dataSourceIds,
          Entities: searchContext?.quickFilter?.entities,
          Participants: searchContext?.quickFilter?.participants,
          DataTypes: searchContext?.quickFilter?.dataTypes,
          TagIds: searchContext?.quickFilter?.tags,
          Start: searchContext?.quickFilter?.start,
          End: searchContext?.quickFilter?.end,
          IncludeBlankDate: searchContext?.quickFilter?.includeBlankDate,
          IsShowRecents: searchContext?.isShowRecents,
          SavedSearchId: searchContext?.savedSearchId ?? 0,
          SelectAllUntagged: searchContext?.quickFilter.selectAllUntagged,
        },
      }

    } else {
      const selectedItemsData = selectedItems.map(index => isResultsData[index]);

      const Items = selectedItemsData
        .map(item => ({ Id: item.id, Type: item.type }))
        .filter(item => !isChatGroupOrEmailThread(item.Type));

      const Groups = selectedItemsData
        .map(item => ({ Id: item.id, Type: item.type }))
        .filter(item => isChatGroupOrEmailThread(item.Type));

      requestData = {
        MassTagType: 0,
        Items,
        Groups,
        TagIds: [...selectedTags]
      }
    }

    let jobId;
    try {
      const isApply = tagAction === TAG_ACTION.tag;
      if (isApply) {
        jobId = (await MassTagService.applyMassTag(projectId, requestData)).data.jobId;
      } else {
        jobId = (await MassTagService.applyMassUntag(projectId, requestData)).data.jobId;
      }

      toast.loading(`${isApply
        ? "Applying"
        : "Removing "
        } tags: ${getTagShowNamesFromIds(selectedTags, showTags)} ${isApply
          ? "to"
          : "from"} ${isSelectedAll
            ? paging.totalRecords
            : selectedItems.length} results...`, {
        autoClose: false,
        toastId: GetMassTagToastId(jobId)
      });
    } catch (err) {
      console.log(err);
      toast.update(GetMassTagToastId(jobId), {
        render: MASS_TAG_TOAST_MSG.APPLY_ERR,
        type: toast.TYPE.ERROR,
        autoClose: false,
      });
    } finally {
      handleUnselectAll();
    }
  };

  const isChatGroupOrEmailThread = (type) => {
    return type === 'ChatGroup' || type === 'Chat' || type === 'EmailThread' || type === 'Email';
  };

  const handleIncompleteThreadsToggle = () => {
    dispatch(setSearchContext({ ...searchContext, isShowRecents: !isShowRecent }));
    handleToggle();
  };

  const onSubmitSearchAction = () => {
    dispatch(setSearchContextQuickFilter({ ...searchContext.quickFilter, filterSearchQuery: inputRef?.current?.value }));
    onSubmitSearch();
  };

  const onClickSelectAll = () => {
    if (isSelectedAll) {
      handleUnselectAll();
    } else {
      handleSelectAll();
    }
  }

  useEffect(() => {
    dispatch(setSearchContextQuickFilter({ ...searchContext.quickFilter, filterSearchQuery: inputRef?.current?.value }));
  }, [inputRef]);

  return (
    <div className={styles["is-result"]}>
      <PopupConfirm
        isShow={showConfirmMassTag}
        handleClose={() => {
          setShowConfirmMassTag(false);
        }}
        handleSubmit={async () => applyMassTags()}
        content={GetMassTagConfirmationMsg(isSelectedAll ? paging.totalRecords : selectedItems.length)}
        textConfirm="Apply"
      />
      <DateSelector
        showDatePicker={showDateFilter}
        handleClose={onHideDateFilter}
        handleSubmit={onHandleSubmitDate}
        data={{
          startDate: datePickerData.datePicker.dateTimeDataStart,
          endDate: datePickerData.datePicker.dateTimeDataEnd
        }}
      />
      <div className={styles["is-result-head"]}>
        <h5 className={styles["is-result-head-title"]}>
          Results
        </h5>
        <div className={styles["is-result-search-control"]}>
          <div className={styles["is-show-recent"]}>
            <Toggle checked={isShowRecent} onChange={handleIncompleteThreadsToggle} />
            <span className={styles["is-show-recent__title"]}>
              Show Incomplete Threads
            </span>
          </div>
          <div className="mar_r10 cursor-pointer">
            <img
              src={
                datePickerData?.datePicker?.dateTimeDataStart != null || datePickerData?.datePicker?.dateTimeDataEnd != null
                  ? "/images/calendar-icon-checked.svg"
                  : "/images/calendar-icon.svg"
              }
              alt="icon"
              onClick={onShowDateFilter}
            />
          </div>
          <SearchInputGeneral
            searchInput={searchInput || ""}
            isAutoComplete={true}
            placeholder="Search"
            name="search"
            onSubmitSearch={onSubmitSearchAction}
            inputRef={inputRef}
            ref={searchInputRef}
          />
        </div>
      </div>
      {loading ? (
        <Spinner variant="primary" animation="border" />
      ) : (
        <>
          {paging.totalRecords > 0 ? (
            <div className={styles["is-result-list"]}>
              <div className={styles["is-result-table"]}>
                <table>
                  <thead>
                    <tr>
                      <th
                        key="select"
                        onClick={onClickSelectAll}
                        className={clsx(
                          styles["disable-pointer"]
                        )}
                      >
                        <Checkbox
                          isChecked={isSelectedAll}
                          isIndeterminate={selectedItems.length > 0 && !isSelectedAll}
                          size="lg"
                        />
                      </th>
                      {Object.values(COLUM_RESULT).map((item) => (
                        <th key={item.key}>
                          <a
                            className={clsx(
                              classSort(sortColumns[item.key]),
                              [
                                COLUM_RESULT.type.key,
                                COLUM_RESULT.dataSourceID.key,
                                COLUM_RESULT.participants.key,
                              ].includes(item.key)
                                ? styles["disable-pointer"]
                                : ""
                            )}
                            onClick={() =>
                              onSortTable(item.key, sortColumns[item.key])
                            }
                          >
                            {item.label}
                          </a>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {isResultsData.map((item, index) => (
                      <tr
                        key={index}
                        onClick={() => onSwitchRecord({ ...item, index })}
                        className={
                          paging.recordNumber === index
                            ? styles["conv-select"]
                            : ""
                        }
                      >
                        <td
                          onClick={(event) => handleCheckboxClick(event, index)}
                        >
                          <Checkbox
                            isChecked={selectedItems.includes(index)}
                            size="lg"
                          />
                        </td>
                        <td>
                          <img
                            src={`/images/icons/${camelCase(item.type)}.svg`}
                            alt="img-message"
                          />
                        </td>
                        <td>
                          {item?.source ? (
                            <SourceTypeList
                              sourceList={item.source.split(", ")}
                              limitSource={3}
                            />
                          ) : (
                            COMMON_TEXT.default
                          )}
                        </td>
                        <td>
                          {item.docID || COMMON_TEXT.default}
                        </td>
                        <td
                          title={
                            item.dataSourceID &&
                            getDataSourceNameFromID(
                              dataSourceList,
                              item.dataSourceID.split(/,\s*/)
                            )
                          }
                        >
                          {item.dataSourceID
                            ? getDataSourceNameFromID(
                              dataSourceList,
                              item.dataSourceID.split(/,\s*/)
                            )
                            : COMMON_TEXT.default}
                        </td>
                        <td>
                          {item.participants ? (
                            <div className={styles["participant"]}>
                              <span
                                title={item.participants}
                                className={styles["background"]}
                              >
                                <span>{item.participants}</span>
                              </span>
                            </div>
                          ) : (
                            COMMON_TEXT.default
                          )}
                        </td>
                        <td>
                          {item.dateTime
                            ? formatDateTime({
                              dateTime: item.dateTime,
                              type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
                              mst: false,
                            })
                            : COMMON_TEXT.default}
                        </td>
                        <td>
                          {item.dateLast
                            ? formatDateTime({
                              dateTime: item.dateLast,
                              type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
                              mst: false,
                            })
                            : COMMON_TEXT.default}
                        </td>
                        <td
                          title={item.description}
                          className={styles["description"]}
                        >
                          {getDescription(item)}
                        </td>
                        <td>
                          {CHAT_EMAIL_TYPE.includes(item.type)
                            ? item?.itemsCount
                            : COMMON_TEXT.default}
                        </td>
                        <td>{item.attachmentCount || COMMON_TEXT.default}</td>
                        <td>{getDeletedStatus(item.deleted)}</td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={styles["footer"]}>
                <div className={styles["tagging"]}>
                  <SearchResultTagging
                    isLoadingData={loading}
                    disableSelectButton={false}
                    handleBtnCancelSelect={() => { }}
                    disableTagButton={selectedItems.length === 0 && !isSelectedAll}
                    disableUnTagButton={selectedItems.length === 0 && !isSelectedAll}
                    handleSaveSelectTags={handleSaveSelectTags}
                    isSelectedAll={isSelectedAll}
                    handleIsShowTagging={() => { }}
                    handleIsAddTag={() => { }}
                    disableTag={false}
                  />
                </div>
                <div className={styles["pagination-results"]}>
                  <PaginationResult
                    perPage={PAGINATION.recordPerPageTable}
                    totalRecord={paging.totalRecords}
                    forcePage={paging.pageIndex}
                    pageItems={isResultsData.length}
                    handlePagingClick={onClickPagingHandleChild}
                    isSmall={true}
                    selectedLabel={(selectedItems.length > 0 || isSelectedAll)
                      ? (isSelectedAll
                        ? `${paging.totalRecords} selected`
                        : `${selectedItems.length} selected`)
                      : ""}
                  />
                </div>
              </div>
            </div>
          ) : (
            <EmptyPage
              messages={searchError !== '' ? searchError : "No results found. Please try again."}
              isImgnotFound={true}
            />
          )}
        </>
      )}
    </div>
  );
};

SearchResult.propTypes = {
  searchInput: PropTypes.string,
  loading: PropTypes.bool,
  isShowRecent: PropTypes.bool,
  paging: PropTypes.object,
  isResultsData: PropTypes.array,
  participantSuggest: PropTypes.array,
  inputRef: PropTypes.any,
  dataSourceList: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      custodianName: PropTypes.string,
      dataSourceID: PropTypes.number,
    })
  ),
  onClickPagingHandle: PropTypes.func,
  onChangeIsResultDetail: PropTypes.func,
  onSubmitSearch: PropTypes.func,
  onSubmitDate: PropTypes.func,
  onSort: PropTypes.func,
  onSwitchRecord: PropTypes.func,
  handleToggle: PropTypes.func,
  searchInputRef: PropTypes.any,
};
