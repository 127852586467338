import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { get, camelCase, isEmpty } from "lodash";
import PropTypes from "prop-types";
import clsx from "clsx";
import useKeyDownListener from "hook/KeyDownListener";

// Components
import { DropdownChat } from "components/shared/dropdown-selector/dropdown-chat/DropdownChat";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import TagExecuteMultiItems from "components/tag-management/tag-execute-multi-items/TagExecuteMultiItems";
import RenderAttachment from "components/shared/attachment-view/RenderAttachment";
import MessageHeader from "components/shared/message-header/MessageHeader";
import SourceTypeList from "components/shared/source-type-list/SourceTypeList";
import { AttachmentReview } from "components/communication-review/AttachmentReview";
import ChatTable from "../chat-table/ChatTable";
import { RenderTagSelected } from "components/shared/render-tag-selected/RenderTagSelected";
import Accordion from "components/shared/accordion/Accordion";
import { PreviewChatControl } from "components/intelligent-search/search-result/preview/preview-chat-control/PreviewChatControl";

// Constants
import {
  DISPLAY_TYPE,
  TYPE_CLICK,
  OPTION_SELECT_MESSAGE,
  PAGINATION,
  EXPORT_TAG,
} from "constants/Common";
import {
  CONVERSATION_ACTION,
  DATE_TIME_TYPE,
  TAG_ACTION,
  TAG_RESPONSE_MESSAGE,
} from "constants/Constants";
import { RESPONSE_STATUS } from "constants/StatusCodeConstant";
import { 
  GetFilterTagToastId,
  FILTER_TAG_TOAST_MSG
} from "constants/FilterTagConstants";

// Services
import { postSelectTags } from "services/GeneralReviewService";
import { getMessageRange } from "services/SearchService";
import { 
  applyFilterTag, 
  applyFilterUntag 
} from "services/FilterTagService";

// Store
import { setCanExecuteHotKey, setIsExecutingTag } from "store/TagReducer";
import { 
  setSelectedTags, 
  setActionType, 
  resetFilterTagState 
} from "store/FilterTagReducer";

// Helpers
import { displayTextHighlighted } from "helpers/TextHelper";
import { changeAllEmojiToArray } from "helpers/HighLightTextHelper";
import { showToast } from "helpers/ToastHelper";
import {
  scrollElementToBottom,
  scrollElementToTop,
} from "helpers/ScrollHelper";
import { handleUpdateTag } from "helpers/CommonHelper";
import { handlePressHotKey } from "helpers/HotKeyHelper";
import { isCheckJoinLeave, onConvertMessages } from "helpers/ConversationHelper";
import { urlify } from "helpers/TextHelper";
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { getTagShowNamesFromIds } from "helpers/TagHelper";

// Styles
import styles from "./PreviewChat.module.scss";

const PreviewChat = (props) => {
  const {
    loading,
    index,
    paging,
    setIndex,
    listIndex,
    setListIndex,
    data = {
      id: "",
      type: "",
      chatID: "",
      instantMessages: [],
      totalMessage: 0,
      pageNumbers: 1,
      source: "",
    },
    totalSearchCount,
    setTotalSearchCount,
    listPageNumber,
    setListPageNumber,
    dateTime: { dateTimeDataStart, dateTimeDataEnd, includeBlankDate },
    onHandlePagingTable,
    searchMsgInput,
    setSearchMsgInputStore,
    setInstantMessagesStore,
    isSwitchChat,
    isDisablePrevious,
    isDisableNext,
    displayType = "",
    setIsShowPreviewModal,
    isShowFooter = true,
    fetchChatDetailApi,
    allFirstLoadMessages = [],
    titleActivity = "",
    isFirstLoad = true,
    setIsFirstLoad = () => {},
    idDoc = "",
    currentID = "",
    reviewType = "",
    isShowChangeOption = true,
    isShowCheckBox = true,
    participants = [],
    isTable = false,
    messageExpansionLength = 2,
  } = props;

  const dispatch = useDispatch();
  const { projectId } = useParams();

  const [isShow, setIsShow] = useState(true);
  const [isLoading, setIsLoading] = useState(loading);
  const [checkedMessage, setCheckedMessage] = useState([]);
  const [identifier, setIdentifier] = useState("");
  const [participantIdentifiers, setParticipantIdentifiers] = useState([]);
  const [canUntag, setCanUntag] = useState(false);
  const [isSelectedAllMessage, setIsSelectedAllMessage] = useState(false);
  const [isCanLoadMore, setIsCanLoadMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [optionSelected, setOptionSelected] = useState(
    OPTION_SELECT_MESSAGE.all
  );
  const [willScrollMessage, setWillScrollMessage] = useState(false);
  // messages to show on layout
  const [messages, setMessages] = useState([]);
  const [allMessages, setAllMessages] = useState(allFirstLoadMessages || []);

  // state for search high light text
  const [listEmojis, setListEmojis] = useState([]);
  const [isAllEmoji, setIsAllEmoji] = useState(false);
  const [typeClick, setTypeClick] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [topPageNumber, setTopPageNumber] = useState(
    listPageNumber[0] ? listPageNumber[0] - 1 : 0
  );
  const [bottomPageNumber, setBottomPageNumber] = useState(
    listPageNumber[0] ? listPageNumber[0] + 1 : 2
  );
  const [listPageNumberLoaded, setListPageNumberLoaded] = useState([1]);
  const [lastScrollMessageIndex, setLastScrollMessageIndex] = useState(0); // used for keep position of view when load more message
  const [isShowSearch, setIsShowSearch] = useState(false); // show search box

  // For tagging
  const [isTagging, setIsTagging] = useState(false);
  // when we click up/down, this state will be plused 1 when option selected is not all
  const [showMessageWithOption, setShowMessageWithOption] = useState(0);
  const [typeLoadMore, setTypeLoadMore] = useState(null); // type: up, down to show spinner
  const [isScrollToLoadMessage, setIsScrollToLoadMessage] = useState(false);
  const [isAddTag, setIsAddTag] = useState(false);
  const [maxPageNumber, setMaxPageNumber] = useState(0);
  const [disableGoFirst, setDisableGoFirst] = useState(true);
  const [disableGoLast, setDisableGoLast] = useState(true);
  const [checkScrollTopBottom, setScrollTopBottom] = useState(false);
  const [attachmentId, setAttachmentId] = useState("");
  const [showAttach, setShowAttach] = useState(false);

  const {
    tags: { selectTags, showTags },
    canExecuteHotKey,
    isExecutingTag,
  } = useSelector((state) => state.tag);

  const {
    selectedTags = {},
    actionType = '',
    isError = false
  } = useSelector((state) => state.filterTag);

  const {
    resultMessageCount,
    filteredTags
  } = useSelector((state) => state.generalRV);

  const {
    iSResultMessageCount,
    iSFilteredTags
  } = useSelector((state) => state.is);

  const GetTotalMessageCount = () => {
    return resultMessageCount !== 0 
      ? resultMessageCount 
      : iSResultMessageCount
  }

  // Code for hight light text
  const getHighlightedText = (data) => {
    const { text, highlight, instantMessageID, showThumbnail = true } = data;
    const element = displayTextHighlighted({
      text,
      highlight: highlight.trim(),
      id: instantMessageID,
      showThumbnail,
      isAllEmoji,
      listEmojis,
      index,
      listIndex,
    });
    return element;
  };

  const filterForLoadMessage = (
    dateTimeDataStart,
    dateTimeDataEnd,
    pageNumber,
    search,
    includeBlankDate = true
  ) => {
    const filter = {
      Search: search,
      Start: dateTimeDataStart,
      End: dateTimeDataEnd,
      PageNumber: pageNumber,
      includeBlankDate,
      ChatID: data.chatID
    };
    return filter;
  };

  // fetch api Message List
  const fetchMessageReview = async (filter, isSearchByDate = false) => {
    setIsLoading(true);
    const params = {
      ...filter,
      Search: filter.Search?.trim(),
      includeBlankDate: !isSearchByDate
        ? includeBlankDate
        : filter.includeBlankDate,
    };
    try {
      const typeChat = camelCase(data.type || reviewType);
      const dataResult = await fetchChatDetailApi(
        typeChat,
        data.id || currentID,
        params
      );
      if (!dataResult.data) return;
      const {
        searchCount,
        pageNumbers,
        instantMessages,
        totalMessage,
      } = dataResult.data;
      if (params.PageNumber === 1 && params.Search) {
        setTotalSearchCount(searchCount);
        setListPageNumber([...pageNumbers]);
        setListPageNumberLoaded([pageNumbers[0]]);
      }

      //if response return no data pageNumbers, get field pageNumber from params
      const messageData = onConvertMessages(
        instantMessages,
        pageNumbers[0] - 1 || params.PageNumber - 1
      );
      setSearchMsgInputStore(params?.Search?.trim());
      setInstantMessagesStore(messageData, totalMessage);
      setAllMessages([...messageData]);
      return messageData;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  // handle btn Tag All Message
  const handleSelectAllMessage = () => {
    setCheckedMessage(messages.map((mess) => mess.instantMessageID));
  };

  // handle btn Un-select all Message
  const handleUnselectAllMessage = () => {
    setCheckedMessage([]);
    setIsSelectedAllMessage(false);
  };

  // handle checked Message
  const handleCheckedMessage = (event) => {
    const { checked, value } = event.currentTarget;

    setCheckedMessage((prev) =>
      checked ? [...prev, value] : prev.filter((val) => val !== value)
    );
  };

  //Update message with new tag
  const updateMessageAfterTag = (
    sourceMessages,
    checkedMessage,
    tagToExecutes,
    type
  ) => {
    const messagesTemp = [...sourceMessages]; // clone message list
    sourceMessages.forEach((message, index) => {
      if (checkedMessage.includes(message.instantMessageID)) {
        messagesTemp[index] = {
          ...messagesTemp[index],
          tags: handleUpdateTag(message.tags, tagToExecutes, type),
        };
      }
    });
    return messagesTemp;
  };

  //Tag/Untag Function
  const executeTag = async (tagsToExecute, type) => {
    if (isEmpty(data?.chatID)) return;

    let result;
    let tagAllSelected = isSelectedAllMessage 
      && optionSelected === OPTION_SELECT_MESSAGE.all;

    dispatch(setIsExecutingTag(true));

    try {
      if (tagAllSelected) {
        // Save state to use after job completes
        dispatch(setActionType(type));
        dispatch(setSelectedTags(tagsToExecute));

        result = await executeFilterTag(tagsToExecute, type);

        toast.loading(`${type === TAG_ACTION.unTag
            ? "Removing"
            : "Applying"
          } tags: ${getTagShowNamesFromIds(tagsToExecute, showTags)} ${type === TAG_ACTION.unTag
            ? "from"
            : "to"
          } selected messages`, {
          autoClose: false,
          toastId: GetFilterTagToastId(result.data.jobId)
        });
      } else {
        result = await executeMessageTag(tagsToExecute, type);
      }

      if (result.status === RESPONSE_STATUS.success && !tagAllSelected)
        setCheckedMessages(tagsToExecute, type);
    } catch (e) {
      if (tagAllSelected) {
        toast.update(GetFilterTagToastId(result.data.jobId), {
          render: FILTER_TAG_TOAST_MSG.APPLY_ERR,
          type: toast.TYPE.ERROR,
          autoClose: false,
        });
      } else {
        showToast(
          "error",
          toast,
          type === TAG_ACTION.unTag
            ? TAG_RESPONSE_MESSAGE.unTagFailed
            : TAG_RESPONSE_MESSAGE.tagFailed,
          "toast-01"
        );
      }

      console.log(e);
    } finally {
      if (!tagAllSelected) {
        dispatch(setIsExecutingTag(false));
        setIsTagging(false);
      }
    }
  };

  const setCheckedMessages = (tagsToExecute, type) => {
    const messagesTemp = updateMessageAfterTag(
      messages,
      checkedMessage,
      tagsToExecute,
      type
    );
    //get all message to update option selection
    const allMessagesTemp = updateMessageAfterTag(
      allMessages,
      checkedMessage,
      tagsToExecute,
      type
    );
    // Prevent scroll to index when message changes
    setIsTagging(true);
    setLastScrollMessageIndex(0);

    //update instantMessages store
    setInstantMessagesStore(allMessagesTemp);
    //setAllMessage to select option
    setAllMessages(allMessagesTemp);
    // Update new messages list not recall api
    setMessages(messagesTemp);
    // Hide select tag bar
    setIsShow(true);
    //Reset data
    setCheckedMessage([]);
  }

  const executeFilterTag = async (tagsToExecute, type) => {
    const params = {
      groupId: data.chatID,
      tagIds: tagsToExecute,
      tagQuery: {
        start: dateTimeDataStart,
        end: dateTimeDataEnd,
        tagFilter: filteredTags,
        search: searchMsgInput
      }
    };

    if (type === TAG_ACTION.unTag)
      return await applyFilterUntag(projectId, params);
    else
      return await applyFilterTag(projectId, params);
  }

  const executeMessageTag = async (tagsToExecute, type) => {
    const params = {
      chatID: data.chatID,
      tags: tagsToExecute,
      ids: checkedMessage,
      isRemove: type === TAG_ACTION.unTag,
      titleActivity
    };

    return await postSelectTags(projectId, params);
  }

  // handle Save Selected Tags
  const handleSaveSelectTags = (selectedTag, type) => {
    if (selectedTag && checkedMessage) {
      executeTag(selectedTag, type);
    }
  };

  const handleKeyPressSelectTag = async (e, key, tag) => {
    if (e.keyCode === EXPORT_TAG.keyCode) return;
    if (e.keyCode === key) {
      e.preventDefault();
      if (checkedMessage.length > 0) {
        executeTag([tag]?.flat(1), TAG_ACTION.tag);
      }
    }
  };

  const onSearchByDate = async (response) => {
    const filter = {
      Start: response.dateTimeDataStart,
      End: response.dateTimeDataEnd,
      PageNumber: 1,
      Search: searchMsgInput,
      includeBlankDate: response.includeBlankDate,
      ChatID: data.chatID,
    };
    try {
      setMessages([]);
      resetStateSearchKeyword();
      resetState();
      await fetchMessageReview(filter, true);
      setOptionSelected(OPTION_SELECT_MESSAGE.all);
      setIsCanLoadMore(true);
    } catch (e) {
      console.log(e);
    }
  };

  const mapIdentifiers = (participant) => {
    if (!participant || participant.identifier.length === 0)
      return [];
    else
      return participant.identifier.map((identifier) => identifier.value);
  };
  
  //handle Dropbox participantList
  const onSelectParticipant = (participantData) => {
    var entityId = participantData.id;
    setIdentifier(entityId);

    var participantIdentifiers = participantData.identifiers;
    setParticipantIdentifiers(participantIdentifiers);
  };

  const checkIsCanLoadMore = (isScrollBottom = true) => {
    let result =
      data.id &&
      projectId &&
      !isLoadingMore &&
      !isLoading &&
      !isSwitchChat &&
      optionSelected === OPTION_SELECT_MESSAGE.all;
    if (isScrollBottom) result = result && isCanLoadMore;
    return result;
  };

  const getFilterLoadMsg = ({
    pageNumber,
    keyword = null
  }) =>
    filterForLoadMessage(
      dateTimeDataStart,
      dateTimeDataEnd,
      pageNumber,
      keyword,
      includeBlankDate
    );

  const handleSetOptionSelected = async (option, isHandleBySelect = true) => {
    if (isHandleBySelect) setOptionSelected(option);
    if (totalSearchCount === 0) return;
    let listAllMessages = [...new Set(allMessages)];
    let listMessages = [];
    if (option !== OPTION_SELECT_MESSAGE.all) {
      const messageAtIndex = listIndex.find((item) => item.index === index);
      if (messageAtIndex) {
        const messageInChat = listAllMessages.find(
          (item) => item.instantMessageID === messageAtIndex.id
        );
        const indexOfMessage = listAllMessages.indexOf(messageInChat);
        option = parseInt(option);
        let indexBegin = indexOfMessage - option;
        if (indexOfMessage < option) {
          if (
            topPageNumber > 0 &&
            data.id &&
            projectId &&
            !isLoadingMore &&
            !isLoading &&
            !isSwitchChat
          ) {
            const filter = getFilterLoadMsg({ pageNumber: topPageNumber });
            const messagesLoadedMore =
              (await loadMoreMessage(filter, TYPE_CLICK.up)) || [];
            listAllMessages = [...messagesLoadedMore, ...listAllMessages];
            setAllMessages(listAllMessages);
            indexBegin = indexBegin + PAGINATION.perPage;
          }
        } else if (indexOfMessage > listAllMessages.length - 1 - option) {
          if (
            data.id &&
            projectId &&
            isCanLoadMore &&
            !isLoadingMore &&
            !isLoading &&
            !isSwitchChat
          ) {
            const filter = getFilterLoadMsg({ pageNumber: bottomPageNumber });
            const messagesLoadedMore =
              (await loadMoreMessage(filter, TYPE_CLICK.down)) || [];
            listAllMessages = [...listAllMessages, ...messagesLoadedMore];
            setAllMessages(listAllMessages);
          }
        }

        for (let i = indexBegin; i < indexBegin + option * 2 + 1; i++) {
          if (listAllMessages[i] !== undefined)
            listMessages.push(listAllMessages[i]);
        }
      }
    } else listMessages = listAllMessages;
    if (optionSelected === OPTION_SELECT_MESSAGE.all)
      setInstantMessagesStore(listMessages);
    setMessages(listMessages);
  };

  // check height for enable/disable go last/first message
  const handleButtonGoToLastFirst = (element) => {
    const { scrollTop, scrollHeight, clientHeight } = element;
    setDisableGoFirst(scrollTop === 0);
    setDisableGoLast(
      scrollHeight - scrollTop >= clientHeight - 1 &&
        scrollHeight - scrollTop <= clientHeight + 1
    );
  };

  const handleScroll = async (e) => {
    const element = e.target;
    const { scrollTop, scrollHeight, clientHeight } = element;

    handleButtonGoToLastFirst(element);
    setLastScrollMessageIndex(0);

    // check height for load more message
    if (scrollHeight - scrollTop <= clientHeight + 10 && messages?.length > 0) {
      const filter = getFilterLoadMsg({ pageNumber: bottomPageNumber });

      if (!checkIsCanLoadMore()) 
        return;

      await loadMoreMessage(filter, TYPE_CLICK.down);

      if (typeClick === TYPE_CLICK.last && bottomPageNumber >= maxPageNumber) {
        setMessages(allMessages);
        setTimeout(() => {
          scrollElementToBottom("message-list-view");
        }, 500);
      }
    } else if (scrollTop === 0 && topPageNumber > 0 && messages?.length > 0) {
      if (!checkIsCanLoadMore(false)) 
        return;

      const filter = getFilterLoadMsg({ pageNumber: topPageNumber });
      await loadMoreMessage(filter, TYPE_CLICK.up);
    }
  };

  const GetMessageRange = async (param) => {
    try {
      var dataResult = await getMessageRange(param);
      return dataResult.data;
    } catch (err) {
      console.log(err);
    }
  }

  const loadMoreMessage = async (filter, scrollType) => {
    if (!data.chatID) return;
    setTypeLoadMore(scrollType);
    setIsLoadingMore(true);
    setWillScrollMessage(true);
    setIsScrollToLoadMessage(true);
    try {
      const typeChat = camelCase(data.type || reviewType);
      const dataResult = await fetchChatDetailApi(typeChat, data.id, filter);
      let messageData = [];
      const instantMessages = get(dataResult, "data.instantMessages", []);
      if (instantMessages.length === 0) setIsCanLoadMore(false);
      else {
        if (scrollType === TYPE_CLICK.down) {
          messageData = onConvertMessages(
            instantMessages,
            bottomPageNumber - 1
          );
          setBottomPageNumber(bottomPageNumber + 1);
          setListPageNumberLoaded([...listPageNumberLoaded, bottomPageNumber]);
          setInstantMessagesStore([...messages, ...messageData]);
          setAllMessages([...allMessages, ...messageData]);
        } else {
          messageData = onConvertMessages(instantMessages, topPageNumber - 1);
          setTopPageNumber(topPageNumber - 1);
          setListPageNumberLoaded([topPageNumber, ...listPageNumberLoaded]);
          setInstantMessagesStore([...messageData, ...messages]);
          setAllMessages([...messageData, ...allMessages]);
        }
      }
      return messageData;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingMore(false);
    }
  };

  //load more message previous page if view message can not scroll when click button go to last message
  const loadMoreOnePageForMessage = async (filter, listMessages, page) => {
    setIsLoading(true);
    try {
      const dataResult = await fetchChatDetailApi(
        camelCase(data.type || reviewType),
        data.id || currentID,
        filter
      );
      const messageData = onConvertMessages(
        dataResult.data?.instantMessages,
        page - 1
      );
      setMessages([...messageData, ...listMessages]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSearchKeywordHandle = async (
    keyword,
    pageNumber = 1,
    isSecond = false
  ) => {
    setIsTagging(false);
    setSearchMsgInputStore(keyword, isSecond);
    setIsScrollToLoadMessage(false);
    try {
      if ((data.id || currentID) && projectId && reviewType) {
        const filter = getFilterLoadMsg({
          pageNumber,
          keyword: keyword?.trim(),
        });
        resetState();
        const listMessages = (await fetchMessageReview(filter)) || [];
        if (pageNumber !== 1) {
          const filter = getFilterLoadMsg({ pageNumber: pageNumber - 1 });
          await loadMoreOnePageForMessage(filter, listMessages, pageNumber - 1);
          setTimeout(() => {
            scrollElementToBottom("message-list-view");
          }, 1000);
        }
        setOptionSelected(OPTION_SELECT_MESSAGE.all);
        setIsCanLoadMore(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const scrollToMessage = () => {
    if (isTagging) 
      return;

    if (searchMsgInput && messages.length > 0)
      setWillScrollMessage(true);
    else
      setWillScrollMessage(false);
  };

  const keydownListener = (event) => {
    const condition =
      !canExecuteHotKey ||
      checkedMessage.length === 0 ||
      isAddTag ||
      isExecutingTag;
    if (condition || !event) return;
    handlePressHotKey(event, selectTags, handleKeyPressSelectTag);
  };

  // Check and load to last message
  const onLoadHandle = () => {
    if (messages?.length <= 0 || lastScrollMessageIndex === 0 || isTagging)
      return;
    const element = document.getElementById(
      `position-${lastScrollMessageIndex}`
    );
    element?.scrollIntoView({
      behavior: "auto",
      block: "nearest",
      inline: "nearest",
    });
    setWillScrollMessage(true);
    setLastScrollMessageIndex(0);
  };

  const resetState = (isChangeConversation = false) => {
    setIndex(0);
    setListPageNumberLoaded([1]);
    if (!isChangeConversation) {
      setTopPageNumber(0);
      setBottomPageNumber(2);
    }
    setPageIndex(0);
    setListIndex([]);
    setTypeClick("");
  };

  const resetStateSearchKeyword = () => {
    setTotalSearchCount(0);
  };

  const onClearSearch = () => {
    resetStateSearchKeyword();
    resetState();
  };

  const resetStateWhenGoLastFirst = () => {
    resetStateSearchKeyword();
    setOptionSelected(OPTION_SELECT_MESSAGE.all);
    setIsCanLoadMore(true);
    resetState(true);
    setSearchMsgInputStore("");
  };

  const handleShowFirstOrLastMsg = async (option) => {
    if (isShowSearch) setIsShowSearch(false);
    if (option === TYPE_CLICK.last && bottomPageNumber >= maxPageNumber) {
      scrollElementToBottom("message-list-view");
      resetStateWhenGoLastFirst();
      return;
    }
    if (option === TYPE_CLICK.first && topPageNumber <= 0) {
      setScrollTopBottom(true);
      setMessages(allMessages);
      scrollElementToTop("message-list-view");
      resetStateWhenGoLastFirst();
      return;
    }
    let page = 1;
    if (option === TYPE_CLICK.last) page = maxPageNumber;
    await onSearchKeywordHandle("", page);
    const topPage = page - (option === TYPE_CLICK.last ? 2 : 1);
    setTopPageNumber(topPage);
    setBottomPageNumber(page + 1);
    resetStateWhenGoLastFirst();
  };

  const fetchMessageFollowIndex = async () => {
    if (!typeClick) return;
    let currentPageNumber = listPageNumber[pageIndex + 1];
    let currentPageIndex = pageIndex;

    const filter = getFilterLoadMsg({
      pageNumber: currentPageNumber,
      keyword: searchMsgInput,
    });
    setIsScrollToLoadMessage(false);

    if (
      typeClick === TYPE_CLICK.down &&
      pageIndex < listPageNumber.length - 1
    ) {
      currentPageIndex = pageIndex + 1;
    } else if (typeClick === TYPE_CLICK.up && pageIndex > 0) {
      currentPageIndex = pageIndex - 1;
      currentPageNumber = listPageNumber[currentPageIndex];
      filter.PageNumber = listPageNumber[currentPageIndex];
    }
    setPageIndex(currentPageIndex);

    if (
      listPageNumber[pageIndex] !== listPageNumber[currentPageIndex] &&
      listPageNumberLoaded.indexOf(listPageNumber[currentPageIndex]) === -1
    ) {
      setListPageNumberLoaded([listPageNumber[currentPageIndex]]);
      setTopPageNumber(currentPageNumber - 1);
      setBottomPageNumber(currentPageNumber + 1);
      const listMessages = (await fetchMessageReview(filter)) || [];
      if (currentPageNumber !== 1) {
        const filter = getFilterLoadMsg({ pageNumber: currentPageNumber - 1 });
        await loadMoreOnePageForMessage(
          filter,
          listMessages,
          currentPageNumber - 1
        );
      }
    }
    setIsTagging(false);
    if (optionSelected !== OPTION_SELECT_MESSAGE.all)
      setShowMessageWithOption(showMessageWithOption + 1);
    scrollToMessage();
  };

  //fetch more message if current message no scroll bar
  const fetchMoreMessageData = async () => {
    if (!messages) return;
    const countMessage = messages.length;
    if (countMessage > 0 && countMessage < 10 && topPageNumber > 0) {
      const filter = getFilterLoadMsg({ pageNumber: topPageNumber });
      const lastPos = PAGINATION.perPage * topPageNumber + 2;
      setLastScrollMessageIndex(lastPos);
      await loadMoreMessage(filter, TYPE_CLICK.up);
      setIsScrollToLoadMessage(true);
    }
  };

  //Check page when search keyword and load more message when view message can not scroll
  const checkPageFirstLoad = async () => {
    if (!data) return;
    const { id, totalMessage } = data;
    const firstPageLoaded = listPageNumber[0];
    const divisionWithRemainder = totalMessage % PAGINATION.perPage;
    const division = totalMessage / PAGINATION.perPage;
    let totalPageNumber = 0;
    if (divisionWithRemainder !== 0) totalPageNumber = parseInt(division) + 1;
    else totalPageNumber = division;
    const isCanLoadMore =
      id &&
      projectId &&
      !isLoadingMore &&
      optionSelected === OPTION_SELECT_MESSAGE.all;
    if (
      firstPageLoaded === totalPageNumber &&
      topPageNumber > 0 &&
      isCanLoadMore
    ) {
      const filter = getFilterLoadMsg({ pageNumber: topPageNumber });
      await loadMoreMessage(filter, TYPE_CLICK.up);
      scrollToMessage();
    }
  };

  const onOpenAttachment = (instantMessageID) => {
    setShowAttach(true);
    setAttachmentId(instantMessageID);
  };

  const renderDayDivider = (messageItem, index, array) => {
    const previousMessage = index > 0 ? array[index - 1] : null;
  
    if (previousMessage) {
      const currentDateConverted = new Date(formatDateTime({
        dateTime: messageItem.timeStamp,
        type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
      }));
      const previousDateConverted = new Date(formatDateTime({
        dateTime: previousMessage.timeStamp,
        type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
      }));
  
      const isNewDay = (
        currentDateConverted.getFullYear() > previousDateConverted.getFullYear() ||
        (currentDateConverted.getFullYear() === previousDateConverted.getFullYear() &&
        currentDateConverted.getMonth() > previousDateConverted.getMonth()) ||
        (currentDateConverted.getFullYear() === previousDateConverted.getFullYear() &&
        currentDateConverted.getMonth() === previousDateConverted.getMonth() &&
        currentDateConverted.getDate() > previousDateConverted.getDate())
      );

      if (isNewDay) {
        const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };
        const fullDateDisplayString = currentDateConverted.toLocaleDateString('en-US', options);
  
        const formattedFullDateDisplayString = fullDateDisplayString.replace(/,/g, ', ');
  
        return (
            <>
              <div className={clsx(styles["divider-line"])}></div>
              <div className={clsx(styles["divider-text"])}>{formattedFullDateDisplayString}</div>
            </>
        );
      }
    }
  
    return null;
  };

  const renderMessages = () => {
    return messages.map((messageItem, index, array) => {
      if (isCheckJoinLeave(messageItem.label))
        return renderJoinLeaveMessage(messageItem, null, array);
      else
        return renderNormalMessage(messageItem, null, identifier, index, array);
    })
  }

  const renderJoinLeaveMessage = (messageItem, searchMsgInput, array) => (
    <>
      <div className={clsx(styles["divider"])}>
        {renderDayDivider(messageItem, index, array)}    
      </div>
      <div
        className={styles["conversation-action"]}
        key={messageItem.instantMessageID}
      >
        <span
          className={clsx(
            styles["content"],
            messageItem.label === CONVERSATION_ACTION.leave
              ? styles["leave"]
              : ""
          )}
        >
          {searchMsgInput
            ? getHighlightedText({
                text: messageItem.body,
                highlight: searchMsgInput,
                instantMessageID: messageItem.instantMessageID,
                showThumbnail: true,
              })
            : messageItem.body}
        </span>
        <span className={styles["date"]}>
          {formatDateTime({
            dateTime: messageItem.timeStamp,
            type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
          })}
        </span>
        {renderTagSelected(messageItem)}
      </div>
    </>
  );
  
  const renderNormalMessage = (messageItem, searchMsgInput, identifier, index, array) => (
    <>
      <div className={clsx(styles["divider"])}>
        {renderDayDivider(messageItem, index, array)}    
      </div>
      <div
        key={messageItem.instantMessageID}
        className={clsx(
          styles["messages-items"],
          (messageItem.identifier !== identifier &&
            !participantIdentifiers?.some((id) => id && id.toLowerCase() === messageItem.identifier?.toLowerCase()))
            ? styles["messages-others"]
            : styles["messages-participant"]
        )}
      >
        <div className={styles["card-message"]}>
          {(messageItem.identifier !== identifier &&
            !participantIdentifiers?.some((id) => id && id.toLowerCase() === messageItem.identifier?.toLowerCase())) && (
            <img
              src="/images/person-vector.svg"
              className={clsx(styles["bg-avatar"], styles["bg-blue"])}
              alt="icon"
            />
          )}
          <div
            className={clsx(styles["person-chat"])}
            id={`position-${messageItem.id}`}
          >
            <MessageHeader
              name={messageItem.name}
              dateTime={messageItem.timeStamp}
              sourceType={messageItem.source}
              subject={messageItem.source?.toLowerCase() === 'teams' ? messageItem.subject : null}
            />
            <div className={styles["chat-content"]}>
              {searchMsgInput
                ? getHighlightedText({
                    text: messageItem.body,
                    highlight: searchMsgInput,
                    instantMessageID: messageItem.instantMessageID,
                    showThumbnail: true,
                  })
                : urlify(messageItem.body, styles["link-message"])}
            </div>
            {messageItem.attachments &&
              messageItem.attachments.map((attachment, index2) => (
                <div key={attachment.attachmentID || index2}>
                  <RenderAttachment attachment={attachment} />
                </div>
              ))}
          </div>
        </div>
        {renderTagSelected(messageItem)}
      </div>
    </>
  );

  const renderSearchMessages = () => {
    var sequencedMessageItems = [];

    return messages.map((messageItem, index, array) => {
      if (sequencedMessageItems.length === 0)
        sequencedMessageItems.push(messageItem);

      var nextMessageItem = index + 1 < array.length ? array[index + 1] : null;
      var messageItemsAreGrouped = nextMessageItem ? (messageItem.order + 1) === nextMessageItem.order : false;
      
      if (messageItemsAreGrouped)
      {
        sequencedMessageItems.push(nextMessageItem);
        return null;
      }

      var hiddenMessageCount = getHiddenMessageCount(array, sequencedMessageItems);
      var lastHiddenMessageCount = getLastHiddenMessageCount(array, sequencedMessageItems);

      var renderedMessageItems = renderExpansionMessages(sequencedMessageItems, hiddenMessageCount, lastHiddenMessageCount);

      sequencedMessageItems = [];
      return renderedMessageItems;
    })
  };

  const renderExpansionMessages = (messageItems, hiddenMessageCount = 0, lastHiddenMessageCount = 0) => {
    var hiddenMessageString = getHiddenMessageString(hiddenMessageCount, messageItems);

    var lastMessageItem = messageItems[messageItems.length - 1].order === messages[messages.length - 1].order;
    var lastHiddenMessageString = getLastHiddenMessageString(lastHiddenMessageCount, lastMessageItem);

    return (
      <>
        {hiddenMessageString !== null && (
          <div className={clsx(styles["hidden-messages-container"])}>
            <span className={clsx(styles["hidden-messages-text"])}>
              {hiddenMessageString}
            </span>
          </div>
        )}
        <div className={clsx(styles["search-message-container"])}>
          {hiddenMessageCount !== 0 && (
            <div className={clsx(styles["top-accordion-container"])}>
              <Accordion 
                handleExpansion={() => expandMessageLeft(messageItems, messageExpansionLength) }
                accordionText="View More"
                accordionImage="/images/up-arrow-2.svg"
              />
            </div>
          )}
          {messageItems.map((messageItem, index, array) => {
            if (isCheckJoinLeave(messageItem.label)) 
              return renderJoinLeaveMessage(messageItem, null, array);
            else
              return renderNormalMessage(messageItem, searchMsgInput, identifier, index, array);
          })}
          {(!lastMessageItem || lastHiddenMessageCount !== 0) && (
            <div className={clsx(styles["bottom-accordion-container"])}>
              <Accordion 
                handleExpansion={() => expandMessageRight(messageItems, messageExpansionLength) }
                accordionText="View More"
                accordionImage="/images/down-arrow-2.svg"
              />
            </div>
          )}
        </div>
        {lastHiddenMessageString !== null && (
          <div className={clsx(styles["hidden-messages-container"])}>
            <span className={clsx(styles["hidden-messages-text"])}>
              {lastHiddenMessageString}
            </span>
          </div>
        )}
      </>
    );
  };

  const getHiddenMessageCount = (messageItems, sequencedMessageItems) => {
    var firstMessageItemOrder = sequencedMessageItems[0].order;
      var firstMessageItemIndex = messages.findIndex(item => item.order === firstMessageItemOrder);

      var previousMessageItemOrder = firstMessageItemIndex === -1 ? null 
        : firstMessageItemIndex === 0 ? 1 
        : messages[firstMessageItemIndex - 1].order;

      var messageCountOffset = (sequencedMessageItems.includes(messageItems[0])) ? 0 : 1;
      var hiddenMessageCount = (firstMessageItemOrder - previousMessageItemOrder) - messageCountOffset;

      return hiddenMessageCount;
  }

  const getLastHiddenMessageCount = (messageItems, sequencedMessageItems) => {
    var lastHiddenMessageCount = 0
      if (sequencedMessageItems.includes(messageItems[messageItems.length - 1]))
      {
        var lastMessageItemOrder = sequencedMessageItems[sequencedMessageItems.length - 1].order;
        var totalMessagesInConversation = GetTotalMessageCount();
        lastHiddenMessageCount = totalMessagesInConversation - lastMessageItemOrder
      }

    return lastHiddenMessageCount;
  }

  const getHiddenMessageString = (hiddenMessageCount, messageItems) => {
    return hiddenMessageCount === 0 && messageItems.length > 0 && messageItems[0].order === 1
      ? "Beginning of conversation"
      : hiddenMessageCount === 0
      ? null
      : hiddenMessageCount > 1
      ? `${hiddenMessageCount} hidden messages`
      : `${hiddenMessageCount} hidden message`;
  }

  const getLastHiddenMessageString = (lastHiddenMessageCount, lastMessageItem) => {
    return lastHiddenMessageCount === 0 && lastMessageItem
      ? "End of conversation"
      : lastHiddenMessageCount === 0
      ? null
      : lastHiddenMessageCount > 1
      ? `${lastHiddenMessageCount} hidden messages`
      : `${lastHiddenMessageCount} hidden message`;
  }

  const expandMessageLeft = (messageItems, count) => {
    var currentMessageOrder = messageItems[0].order;
    var endValue = currentMessageOrder - 1;
    var startValue = messageItems[0].order - count;

    if (startValue < 1)
      startValue = 1;
    
    if (endValue < 1)
      endValue = 1;

    expandMessage(data.chatID, startValue, endValue);
  }

  const expandMessageRight = (messageItems, count) => {
    var currentMessageOrder = messageItems[messageItems.length - 1].order;
    var startValue = currentMessageOrder + 1;
    var endValue = currentMessageOrder + count;

    expandMessage(data.chatID, startValue, endValue);
  }

  const expandMessage = async (groupId, startValue, endValue) => {
    var param = {
      ProjectId: projectId,
      GroupId: groupId,
      StartValue: startValue,
      EndValue: endValue
    }

    var messageRange = await GetMessageRange(param);

    var uniqueInstantMessages = messageRange.instantMessages.filter(message =>
      !messages.some(existingMessage => existingMessage.order === message.order)
    );

    var combinedMessages = messages.concat(uniqueInstantMessages);
      combinedMessages.sort((a, b) => a.order - b.order);

    setMessages(combinedMessages);
  }

  const renderTagSelected = (messageItem) => (
    <Fragment>
      <div className={styles["card-thumb"]}>
        <div className={styles["tag-thumb"]}>
          {(checkedMessage?.some(
            (val) => val === messageItem.instantMessageID
          ) ||
            messageItem.tags?.length > 0) && (
            <RenderTagSelected tagsSelected={messageItem.tags} />
          )}
        </div>
      </div>
      {!isShowCheckBox
        ? false
        : isShow && (
            <div className={styles["chat-select"]}>
              <input
                className={styles["form-check-input"]}
                type="checkbox"
                value={messageItem.instantMessageID}
                checked={checkedMessage?.some(
                  (val) => val === messageItem.instantMessageID
                )}
                onChange={handleCheckedMessage}
                id={`${messageItem.instantMessageID}`}
              />
              <label
                className="form-check-label"
                htmlFor={`${messageItem.instantMessageID}`}
              />
            </div>
          )}
    </Fragment>
  );

  useEffect(() => {
    if (checkScrollTopBottom) {
      setScrollTopBottom(false);
      return;
    }
    // No need scroll when message list change, only scroll when index has changed
    if (!isScrollToLoadMessage) scrollToMessage();
    // Check is select all message will update list
    if (isSelectedAllMessage) handleSelectAllMessage();
    onLoadHandle();
    checkPageFirstLoad();
    // Hide select tag when messages list empty
    messages?.length === 0 && setIsShow(true);
    if (!isTagging && isFirstLoad) {
      //find message with instant message id
      const scrollId =
        messages.find((item) => item.instantMessageID === data.id)?.id || 0;
      setLastScrollMessageIndex(scrollId);
    }
  }, [messages]);

  useEffect(() => {
    //Load more up message if page is 2 and hasn't scrollbar
    if (data?.pageNumbers === 2) checkPageFirstLoad();
  }, [loading]);

  useEffect(() => {
    if (isLoading || !optionSelected) return;
    if (willScrollMessage) scrollToMessage();
    const element = document.getElementById("message-list-view");
    if (!element) return;
    //check if view scrollTop = 0 and page !== 1. Can scroll to Top
    if (topPageNumber > 0 && element.scrollTop === 0)
      element.scroll({
        top: 10,
      });
    handleButtonGoToLastFirst(element);
  }, [isLoading, optionSelected]);

  // Scroll for screen chat details
  useEffect(() => {
    if (
      messages?.length <= 0 ||
      isLoading ||
      willScrollMessage ||
      isTagging ||
      lastScrollMessageIndex === 0
    )
      return;
    setTimeout(() => {
      const element = document.getElementById(
        `position-${lastScrollMessageIndex}`
      );
      element?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }, 500);
  }, [messages, isLoading]);

  useEffect(() => {
    setWillScrollMessage(false);
    setIsTagging(false);
  }, [paging]);

  useEffect(() => {
    fetchMessageFollowIndex();
  }, [index]);

  useEffect(() => {
    handleSetOptionSelected(optionSelected, false);
  }, [showMessageWithOption]);

  useEffect(() => {
    if (searchMsgInput) {
      const result = changeAllEmojiToArray(searchMsgInput);
      setIsAllEmoji(result.isAllEmoji);
      if (result.isAllEmoji) setListEmojis(result.data);
    }
  }, [searchMsgInput]);

  // Reset all data when id of mess list has change
  useEffect(() => {
    if (!searchMsgInput) resetStateSearchKeyword();
    setMessages([]);
    setCheckedMessage([]);
    setIsShow(true);
    setDisableGoFirst(true);
    setDisableGoLast(true);
    setIsCanLoadMore(true);
    setIsLoadingMore(false);
    resetState(true);
    setLastScrollMessageIndex(0);
    setIsSelectedAllMessage(false);
  }, [data?.id]);

  //GetInstantMessage from data
  useEffect(() => {
    if (!data?.instantMessages) return;
    setMessages(data.instantMessages || []);
    setIsCanLoadMore(true);
  }, [data?.instantMessages]);

  useEffect(() => {
    if (data?.totalMessage) {
      const maxPage =
        Math.floor((data.totalMessage - 1) / PAGINATION.perPage) + 1;
      setMaxPageNumber(maxPage);
    }
  }, [data?.totalMessage]);

  useEffect(() => {
    if (data?.pageNumbers) setTopPageNumber(data.pageNumbers - 1);
  }, [data?.pageNumbers]);

  // set top and bottom page number when search success
  useEffect(() => {
    setTopPageNumber(listPageNumber[0] ? listPageNumber[0] - 1 : 0);
    setBottomPageNumber(listPageNumber[0] ? listPageNumber[0] + 1 : 2);
  }, [listPageNumber]);

  useEffect(() => {
    setAllMessages(allFirstLoadMessages);
    setOptionSelected(OPTION_SELECT_MESSAGE.all);
  }, [allFirstLoadMessages]);

  useKeyDownListener(keydownListener, [keydownListener]);

  useEffect(() => {
    setIdentifier(participants[0]?.id || "");
    setParticipantIdentifiers(mapIdentifiers(participants[0]));
  }, [JSON.stringify(participants), data?.id]);

  useEffect(() => {
    if (!loading && !isFirstLoad) {
      setIsFirstLoad(true);
      setTypeLoadMore(TYPE_CLICK.up);
      fetchMoreMessageData();
    }
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    const isSelectAll =
      messages &&
      messages.length === checkedMessage.length &&
      checkedMessage.length > 0;
    setIsSelectedAllMessage(isSelectAll);
    if (checkedMessage.length > 0) dispatch(setCanExecuteHotKey(true));
    else dispatch(setCanExecuteHotKey(false));
    // Check user can un-tag and tag messages
    const canUntag = messages?.some(
      (message) =>
        checkedMessage.includes(message.instantMessageID) &&
        message.tags?.length > 0 &&
        showTags?.some((tag) => message.tags.includes(tag.tagID))
    );
    setCanUntag(
      isSelectAll && data?.totalMessage > PAGINATION.perPage ? true : canUntag
    );
  }, [checkedMessage]);

  useEffect(() => {
    if (searchMsgInput) {
      setIsScrollToLoadMessage(false);
      return;
    }
    handleShowFirstOrLastMsg(TYPE_CLICK.first);
  }, [isTable]);

  useEffect(() => {
    if (!isExecutingTag && selectedTags !== 0) {
      if (!isError)
        setCheckedMessages(selectedTags, actionType);

      setIsSelectedAllMessage(false);
      setIsTagging(false);

      dispatch(resetFilterTagState());
    }
  }, [isExecutingTag]);

  const renderChatView = (component) =>
    isTable ? <div className={styles["wrap"]}>{component}</div> : component;

  return renderChatView(
    <>
      <div className={styles["is-result-preview-head"]}>
        <div className={styles["is-result-preview-select"]}>
          <h5 className={styles["is-result-preview-title"]}>
            {data?.source?.length > 0 && Array.isArray(data.source) && (
              <SourceTypeList sourceList={data.source} />
            )}
            {isTable ? "Detail View" : "Preview"}
          </h5>
          <div className="d-flex align-items-center">
            {participants?.length > 0 && !isTable && (
              <DropdownChat
                size="md"
                options={participants.map((item) => ({
                  id: item.id,
                  identifiers: mapIdentifiers(item),
                  name: item.name,
                }))}
                selectedId={identifier}
                onSelect={onSelectParticipant}
                isNormalLetter
                allowAutoScroll={false}
              />
            )}
          </div>
        </div>
        <PreviewChatControl
          searchInput={searchMsgInput || ""}
          onSearchByDate={onSearchByDate}
          onSearchKeyword={(keyword) =>
            onSearchKeywordHandle(keyword, 1, true)
          }
          isLoading={isLoading}
          onClearSearch={onClearSearch}
          setIsShowPreviewModal={setIsShowPreviewModal}
        />
      </div>

      {!isTable ? (
        <div
          className={`${styles["message-detail"]} ${
            isShowSearch ? "mar_t35" : ""
          }`}
        >
          <div className={styles["message-body"]}>
            {isLoading || loading ? (
              <div className={styles["loading-preview"]}>
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <div className={styles["review-wrap"]}>
                <div
                  id="message-list-view"
                  onScroll={handleScroll}
                  className={styles["review-wrap-card-body"]}
                >
                  {isLoadingMore && typeLoadMore === TYPE_CLICK.up && (
                    <Spinner animation="border" variant="primary" />
                  )}

                  {messages?.length > 0 ? (
                    (searchMsgInput === '' || searchMsgInput === undefined) 
                    && (filteredTags.length === 0 && iSFilteredTags.length === 0) 
                    ? (
                      renderMessages()
                    ) : (
                      renderSearchMessages()
                    )
                  ) : (
                    <EmptyPage
                      messages={
                        searchMsgInput
                          ? "No results found. Please try again."
                          : "No messages found."
                      }
                      size={displayType === DISPLAY_TYPE.preview ? "md" : ""}
                    />
                  )}
                  {isLoadingMore && typeLoadMore === TYPE_CLICK.down && (
                    <Spinner animation="border" variant="primary" />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <ChatTable
          checkedMessage={checkedMessage}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          messages={messages}
          searchMsgInput={searchMsgInput}
          onOpenAttachment={onOpenAttachment}
          setCheckedMessage={setCheckedMessage}
          getHighlightedText={getHighlightedText}
          handleCheckedMessage={handleCheckedMessage}
          handleScroll={handleScroll}
        />
      )}

      {isShowFooter && (
        <div
          className={clsx(
            styles["select-tag"],
            styles["select-tag-chat"],
            displayType === DISPLAY_TYPE.preview ? "hide-element" : ""
          )}
        >
          <TagExecuteMultiItems
            isLoadingData={isLoading}
            isShow={isShow}
            disableTag={messages?.length === 0}
            disableSelectButton={!messages?.length || isLoading}
            handleBtnCancelSelect={() => setCheckedMessage([])}
            disableTagButton={!checkedMessage?.length}
            disableUnTagButton={!checkedMessage?.length || !canUntag}
            handleSaveSelectTags={handleSaveSelectTags}
            isSelectedAll={isSelectedAllMessage}
            handleSelectAll={handleSelectAllMessage}
            handleUnselectAll={handleUnselectAllMessage}
            isDisabledPrev={isDisablePrevious || isLoading}
            isDisabledNext={isDisableNext || isLoading}
            onSwitchHandle={onHandlePagingTable}
            handleIsShow={(value) => setIsShow(value)}
            handleIsAddTag={(value) => setIsAddTag(value)}
            isShowThreadNumber
            threadNumber={idDoc}
            handleShowFirstOrLastMsg={handleShowFirstOrLastMsg}
            setTypeClick={setTypeClick}
            isShowGoFirstLast
            isDisabledGoFirst={
              (topPageNumber <= 0 && disableGoFirst) || isLoading
            }
            isDisabledGoLast={
              (bottomPageNumber >= maxPageNumber && disableGoLast) || isLoading
            }
            isShowChangeOption={isShowChangeOption}
          />
        </div>
      )}
      {showAttach && (
        <AttachmentReview
          onHide={() => setShowAttach(false)}
          attachmentId={attachmentId}
          show={showAttach}
          projectId={projectId}
        />
      )}
    </>
  );
};

PreviewChat.propTypes = {
  displayType: PropTypes.string,
  idDoc: PropTypes.string,
  titleActivity: PropTypes.string,
  currentID: PropTypes.string,
  reviewType: PropTypes.string,
  searchMsgInput: PropTypes.string,
  index: PropTypes.number,
  pageNumber: PropTypes.number,
  totalItemOfPage: PropTypes.number,
  totalSearchCount: PropTypes.number,
  loading: PropTypes.bool,
  isSwitchChat: PropTypes.bool,
  isDisableNext: PropTypes.bool,
  isDisablePrevious: PropTypes.bool,
  isTable: PropTypes.bool,
  isShowFooter: PropTypes.bool,
  isFirstLoad: PropTypes.bool,
  isShowChangeOption: PropTypes.bool,
  isShowCheckBox: PropTypes.bool,
  data: PropTypes.object,
  paging: PropTypes.object,
  dateTime: PropTypes.object,
  allFirstLoadMessages: PropTypes.array,
  listPageNumber: PropTypes.array,
  listIndex: PropTypes.array,
  participants: PropTypes.array,
  setTotalSearchCount: PropTypes.func,
  onHandlePagingTable: PropTypes.func,
  setListIndex: PropTypes.func,
  setIndex: PropTypes.func,
  setListPageNumber: PropTypes.func,
  setSearchMsgInputStore: PropTypes.func,
  setInstantMessagesStore: PropTypes.func,
  setIsShowPreviewModal: PropTypes.func,
  fetchChatDetailApi: PropTypes.func,
  setIsFirstLoad: PropTypes.func,
};

export default PreviewChat;