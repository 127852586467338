import { STATUS_IMPORT_BLOB } from "constants/Common";

export const convertDataSourceInfo = (allDataSource = [], listSourceId = []) =>
  allDataSource?.filter((item) =>
    listSourceId?.includes(item.dataSourceID.toString())
  );

export const handleTotalFileImport = (files = []) => {
  if (files.length === 0) return;
  const bytes = files.map((item) => item?.size || 0);
  const blobBytes = bytes?.reduce((x, y) => x + y) || 0;
  const blobCount = bytes?.length || 0;
  return { blobBytes, blobCount };
};

export const getTotalSizeInFiles = (files = []) => {
  let count = 0;
  files?.length > 0 && files?.forEach((item) => (count += item?.size || 0));
  return count;
};

export const getPercent = (id, listByte, listImport) => {
  const currentPercent =
    (getTotalSizeInFiles(listByte) /
      getTotalSizeInFiles(
        listImport.find((item) => item.id === id)?.files || []
      )) *
    100;
  return currentPercent <= 100 ? currentPercent : 100;
};

export const findIdCanImport = (listImport = [], currentImportId) => {
  const importsReady = listImport.filter(
    (item) => item.status === STATUS_IMPORT_BLOB.ready && item.id !== currentImportId
  );
  return importsReady.length > 0 ? importsReady[0].id : "";
};
