//Intelligent Search Reducer

import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { PAGINATION } from "constants/Common";
import { splitWordSearch } from "helpers/TextHelper";

const isResultDataInit = {
  quickSelect: {
    dataSources: [],
    dataTypes: [],
    entities: [],
    participants: [],
    tags: [],
  },
  paramSearch: {
    searchInput: null,
    searchMsgInput: null,
    dataSources: [],
    dataTypes: [],
    entities: [],
    participants: [],
    tags: [],
    start: null,
    end: null,
    includeBlankDate: true,
    paginationParams: {
      pageNumber: PAGINATION.pageNumberDefault,
      pageSize: PAGINATION.recordPerPageTable,
    },
    isNewest: false,
    isShowRecents: false,
    isPII: true,
    isSecondSearch: false,
  },
  generalReviews: [],
  generalInfoPreview: null,
  paging: {
    totalRecords: 0,
    recordNumber: 0,
    pageIndex: 0,
  },
};

const isSlice = createSlice({
  name: "intelligentSearch",
  initialState: {
    originalISData: {},
    isData: {
      dataSources: [],
      entities: [],
      dataTypes: [],
      startDate: "",
      endDate: "",
      typeFilter: 0,
      globalSearch: [],
      queryType: [],
    },
    queriesRemoved: "",
    idDoc: "",
    isResultData: isResultDataInit,
    isLoading: false,
    isShowPreviewModal: false,
    iSResultMessageCount: 0,
    iSFilteredTags: [],
    selectAllUntagged: false,
  },
  reducers: {
    setOriginalISData: (state, action) => {
      state.originalISData = { ...state.originalISData, ...action.payload };
    },
    setIsData: (state, action) => {
      state.isData = { ...state.isData, ...action.payload };
    },
    setRemovedQueries: (state, action) => {
      state.queriesRemoved = action.payload;
    },
    setQueryType: (state, action) => {
      state.queryType = action.payload;
    },
    setIdDoc: (state, action) => {
      state.idDoc = action.payload;
    },
    setIsLoadingIntelligent: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsShowModalPreview: (state, action) => {
      state.isShowPreviewModal = action.payload;
    },
    resetIsData: (state) => {
      state.isData = {
        ...state.isData,
        ...{
          dataSources: [],
          entities: [],
          dataTypes: [],
          startDate: "",
          endDate: "",
          typeFilter: 0,
          globalSearch: [],
        },
      };
      state.queriesRemoved = "";
    },

    // Intelligent search result
    setQuickSelect: (state, action) => {
      state.isResultData.quickSelect = {
        ...state.isResultData.quickSelect,
        ...action.payload,
      };
    },
    setParamSearch: (state, action) => {
      state.isResultData.paramSearch = isEmpty(action.payload)
        ? {}
        : {
            ...state.isResultData.paramSearch,
            ...action.payload,
            searchMsgInput: splitWordSearch(
              action.payload.searchMsgInput || "",
              state.isResultData.paramSearch.isSecondSearch
            ),
          };
    },
    setGeneralReviews: (state, action) => {
      state.isResultData.generalReviews = action.payload;
    },
    setGeneralInfoPreview: (state, action) => {
      state.isResultData.generalInfoPreview = {
        ...state.isResultData.generalInfoPreview,
        ...action.payload,
      };
    },
    resetGeneralInfoPreview: (state) => {
      state.isResultData.generalInfoPreview = {};
    },
    setPaging: (state, action) => {
      state.isResultData.paging = {
        ...state.isResultData.paging,
        ...action.payload,
      };
    },
    resetParamSearchResult: (state) => {
      state.isResultData.paramSearch = isResultDataInit.paramSearch;
    },
    resetQuickSelectParam: (state) => {
      state.isResultData.paramSearch = {
        ...state.isResultData.paramSearch,
        dataTypes: [],
        entities: [],
        participants: [],
        tags: [],
      };
    },
    resetStoreSearchResult: (state) => {
      state.isResultData = isResultDataInit;
    },
    setISResultMessageCount: (state, action) => {
      state.iSResultMessageCount = action.payload;
    },
    setISFilteredTags: (state, action) => {
      state.iSFilteredTags = action.payload;
    },
    setSelectAllUntagged: (state, action) => {
      state.selectAllUntagged = action.payload;
    }
  },
});
export const {
  setOriginalISData,
  setIsData,
  setRemovedQueries,
  setIsLoadingIntelligent,
  setIsShowModalPreview,
  resetIsData,
  setQuickSelect,
  setParamSearch,
  setGeneralReviews,
  setGeneralInfoPreview,
  resetGeneralInfoPreview,
  setPaging,
  resetParamSearchResult,
  resetStoreSearchResult,
  resetQuickSelectParam,
  setIdDoc,
  setQueryType,
  setISResultMessageCount,
  setISFilteredTags,
  setSelectAllUntagged,
} = isSlice.actions;
export const ISReducer = isSlice.reducer;
