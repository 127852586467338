import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

// Helpers
import { displayTextHighlighted } from "helpers/TextHelper";
import { getTotalSearchEmail } from "helpers/EmailReviewHelper";
import {
  createIndexHighlight,
  changeAllEmojiToArray,
} from "helpers/HighLightTextHelper";
import {
  scrollElementToTop,
  scrollElementToBottom,
} from "helpers/ScrollHelper";

// Constants
import {
  TYPE_CLICK,
  OPTION_SORT,
  DISPLAY_TYPE,
  OPTION_SELECT_MESSAGE,
  COLORS,
} from "constants/Common";
import { SLACK_SOURCE } from "constants/Constants";

// Components
import { EmailItem } from "../email-item/EmailItem";
import { FilterControl } from "components/communication-review/FilterControl";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import TagExecuteMultiItems from "components/tag-management/tag-execute-multi-items/TagExecuteMultiItems";
import PIIBubble from "../PIIBubble/PIIBubble";
import SourceIcon from "components/shared/source-icon/SourceIcon";

// Styles
import styles from "./EmailPreviewSelectTag.module.scss";

const EmailPreviewSelectTag = ({
  emailList,
  handleChecked,
  handleSelectAll,
  handleUnselectAll,
  emailSelected,
  isSelectAll,
  onSaveTag,
  loading,
  onHandlePagingTable,
  emailResultTotal,
  onSearchByDate,
  canUntag,
  handleIsAddTag,
  handleIsShow,
  isShowTag = true,
  handleBtnCancelSelect,
  isDisabledPrev,
  isDisabledNext,
  isHideBorderWrap,
  title,
  displayType = "",
  onSetParamIsNewestStore = () => { },
  idDoc = "",
  isShowFooter = true,
  totalRecord = 1,
  isTaggingEmail = false,
  isShowChangeOption = true,
  setIsTaggingEmail = () => { },
  setIsShowTag = () => { },
  onClearSearchMsg = () => { },
  searchEmailInput,
  setSearchMsgInputStore = () => { },
  setIndex,
  index,
  setTotalSearchCount,
  totalSearchCount,
}) => {
  const { state } = useLocation();

  const [emailListCurrent, setEmailListCurrent] = useState(emailList || []);
  const [optionSortSelected, setOptionSortSelected] = useState(
    OPTION_SORT.oldToNew
  );
  const [listIndex, setListIndex] = useState([]);
  const [typeClick, setTypeClick] = useState("");
  const [isShowSearch, setIsShowSearch] = useState(false); // show search box
  const [totalEmailContainKeyWord, setTotalEmailContainKeyWord] = useState(0);
  const [isAllEmoji, setIsAllEmoji] = useState(false);
  const [listEmojis, setListEmojis] = useState([]);
  const [disableGoFirst, setDisableGoFirst] = useState(true);
  const [disableGoLast, setDisableGoLast] = useState(false);

  const sortEmail = (emails, option) => {
    const listEmails = [...emails];
    return listEmails?.sort((a, b) => {
      const dateA = new Date(a.dateSend);
      const dateB = new Date(b.dateSend);
      return option === OPTION_SORT.newToOld ? dateB - dateA : dateA - dateB;
    });
  };

  const handleSetCurrentEmailList = () => {
    const sortListEmail = sortEmail([...emailList], optionSortSelected);
    setEmailListCurrent(sortListEmail);
  };

  const getHighlightedText = (data) => {
    const {
      text,
      highlight,
      emailID,
      isBold = false,
      key,
      showThumbnail = false,
    } = data;
    const element = displayTextHighlighted({
      text,
      highlight: highlight.trim(),
      id: emailID,
      isAllEmoji,
      listEmojis,
      index,
      listIndex,
      isBold,
      key,
      showThumbnail,
    });
    return element;
  };

  const onSearchEmojisHandle = (list) => {
    let totalSearchCount = 0;
    let totalEmailContainKeyword = 0;
    emailList?.forEach((email) => {
      let checkEmailContainEmoji = 0;
      list.forEach((emoji) => {
        const splitSubject = email?.subject?.split(emoji);
        if (splitSubject?.length > 1) {
          checkEmailContainEmoji++;
          totalSearchCount += splitSubject.length - 1;
        }
        const splitBody = email?.body?.split(emoji);
        if (splitBody?.length > 1) {
          checkEmailContainEmoji++;
          totalSearchCount += splitBody.length - 1;
        }
      });
      if (checkEmailContainEmoji > 0) totalEmailContainKeyword++;
    });
    return {
      totalEmailContainKeyword,
      totalSearchCount,
    };
  };

  const focusFirstSearch = () => {
    setTimeout(() => {
      const ele = document.getElementById(`element-pos-0`);
      if (ele) ele.style.backgroundColor = COLORS.blue;
    }, 100);
  };

  const onSearchKeywordHandle = (keyword) => {
    setIsTaggingEmail(false);
    setListIndex([]);
    setIndex(0);
    try {
      const searchInput = keyword?.trim();
      if (searchInput) {
        const result = createIndexHighlight(emailList, searchInput);
        setTotalSearchCount(result.totalIndex);
        // check is all emojis
        const emoji = changeAllEmojiToArray(searchInput);
        setIsAllEmoji(emoji.isAllEmoji);
        if (emoji.isAllEmoji) setListEmojis(emoji.data);
        let totalEmailContainKeyword = 0;
        // search with search text
        const data = !emoji.isAllEmoji
          ? getTotalSearchEmail(searchInput, emailList)
          : onSearchEmojisHandle(emoji.data);
        totalEmailContainKeyword = data.totalEmailContainKeyword;
        setTotalEmailContainKeyWord(totalEmailContainKeyword);
        if (totalSearchCount === 0) setEmailListCurrent([]);
        setEmailListCurrent(result.data);
        focusFirstSearch();
        handleScrollToEmail(0);
      } else {
        if (emailList) handleSetCurrentEmailList();
        setTotalSearchCount(0);
        setTotalEmailContainKeyWord(0);
        setSearchMsgInputStore();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleScrollToEmail = (firstIndex = -1) => {
    if (!searchEmailInput || !emailListCurrent?.length || isTaggingEmail)
      return;
    const element = document.getElementById(
      `element-pos-${firstIndex === -1 ? index : firstIndex}`
    );
    element?.scrollIntoView({ block: "center" });
  };

  const handleScrollToEmailGoToOrigin = ({ docID, attachmentId }) => {
    if (isTaggingEmail) return;
    setTimeout(() => {
      const element = document.getElementById(
        `email-item-${docID ? docID : attachmentId}`
      );
      element?.scrollIntoView({
        behavior: "smooth",
        block: `${docID ? "start" : "center"}`,
      });
    }, 500);
  };

  const handleSetOptionSortSelected = (option) => {
    if (option === optionSortSelected) return;
    const sortListEmail = sortEmail([...emailListCurrent], option);
    onSetParamIsNewestStore(option === OPTION_SORT.newToOld);
    setOptionSortSelected(option);
    setIndex(0);
    setListIndex([]);
    setEmailListCurrent(sortListEmail);
  };

  const onClearSearch = () => {
    setIndex(0);
    setListIndex([]);
    setTotalEmailContainKeyWord(0);
    setTotalSearchCount(0);
    if (emailList) handleSetCurrentEmailList();
  };

  const handleShowFirstOrLastEmail = (option) => {
    if (option === TYPE_CLICK.last) scrollElementToBottom("email-detail");
    else scrollElementToTop("email-detail");
  };

  const handleButtonGoToLastFirst = (element) => {
    const { scrollTop, scrollHeight, clientHeight } = element;
    setDisableGoLast(scrollHeight - scrollTop === clientHeight);
    setDisableGoFirst(scrollTop === 0);
  };

  const onScrollToFirstOrLastEmail = (e) => handleButtonGoToLastFirst(e.target);

  const handleTypeForScrollEmail = () => {
    if (totalSearchCount > 0) {
      handleScrollToEmail(); // scroll email when search email
      return;
    }
    if (state) {
      // Scroll to view email if have docID or attachmentID
      handleScrollToEmailGoToOrigin({
        docID: state.docID,
        attachmentId: state.attachmentId,
      });
      return;
    }
  };

  // search when we change search email input
  useEffect(() => {
    searchEmailInput
      ? onSearchKeywordHandle(searchEmailInput)
      : onClearSearch();
  }, [searchEmailInput]);

  // set emailList in redux to emailListCurrent for show on layout
  useEffect(() => {
    if (emailList?.length > 0 && !searchEmailInput) handleSetCurrentEmailList();
    else {
      setDisableGoFirst(true);
      setDisableGoLast(true);
      onSearchKeywordHandle(searchEmailInput);
    }
  }, [emailList]);

  useEffect(() => {
    const element = document.getElementById("email-detail");
    element && handleButtonGoToLastFirst(element);
    if (loading || !emailListCurrent?.length) {
      setDisableGoLast(true);
      setDisableGoFirst(true);
      return;
    }
  }, [loading, emailListCurrent]);

  useEffect(() => {
    handleTypeForScrollEmail();
    setIsTaggingEmail(false);
  }, [loading]);

  useEffect(() => {
    if (!typeClick) return;
    setIsTaggingEmail(false);
    handleScrollToEmail();
  }, [index]);

  useEffect(() => {
    handleScrollToEmail(0);
    listIndex?.length === 0 && setIsShowTag(true);
  }, [listIndex]);

  return (
    <div
      className={clsx(
        styles["email-wrap"],
        !isHideBorderWrap ? styles["border-wrap"] : "",
        displayType === DISPLAY_TYPE.preview ? styles["email-preview"] : ""
      )}
    >
      <div
        className={clsx(
          styles["email-result-preview-head"],
          isShowSearch ? styles["show-filter-control"] : ""
        )}
      >
        <div className={styles["email-result-preview-select"]}>
          <h5 className={styles["email-result-preview-title"]}>
            {totalRecord > 0 && emailListCurrent?.[0]?.source && (
              <SourceIcon
                sourceType={
                  emailListCurrent[0]?.snippet === SLACK_SOURCE
                    ? "slack"
                    : emailListCurrent[0]?.source
                }
                defaultIcon="email"
              />
            )}
            <span>{title}</span>
          </h5>
        </div>
        <div className={styles["email-result-preview-search"]}>
          <FilterControl
            onSearchByDate={onSearchByDate}
            onSearchKeyword={(keyword) =>
              setSearchMsgInputStore(keyword.trim())
            }
            searchInput={searchEmailInput || ""}
            index={index}
            setIndex={setIndex}
            totalSearchCount={totalSearchCount}
            totalMessageContainKeyWord={totalEmailContainKeyWord}
            optionSelected={OPTION_SELECT_MESSAGE.all}
            setOptionSelected={() => { }}
            optionSortSelected={optionSortSelected}
            setOptionSortSelected={handleSetOptionSortSelected}
            onClearSearch={() => {
              onClearSearchMsg();
              onClearSearch();
            }}
            isLoadingMore={false}
            isLoading={loading}
            setTypeClick={setTypeClick}
            isShowSearch={isShowSearch}
            setIsShowSearch={setIsShowSearch}
            isEmailReview={true}
          />
        </div>
      </div>
      <PIIBubble />
      <div
        onScroll={onScrollToFirstOrLastEmail}
        id="email-detail"
        className={styles["message-detail"]}
      >
        {loading ? (
          <div className={styles["message-detail-loading"]}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <>
            {totalRecord === 0 ||
              emailResultTotal === 0 ||
              (totalSearchCount === 0 && searchEmailInput?.length > 0) ||
              emailListCurrent?.length === 0 ? (
              <div className={styles["empty-page"]}>
                <EmptyPage messages="No results found. Please try again." />
              </div>
            ) : (
              <div className={styles["message-body"]}>
                <>
                  {emailListCurrent?.sort((a, b) => {
                    // Handle case where a.dateSend or b.dateSend is null
                    if (!a.dateSend && !b.dateSend) {
                      return 0; // Both null, keep original order
                    } 
                    if (!a.dateSend) {
                      return 1; // a is null, place it after b
                    }
                    if (!b.dateSend) {
                      return -1; // b is null, place a before b
                    }

                    return new Date(b.dateSend) - new Date(a.dateSend);
                  }).map((item, idx) => (
                    <EmailItem
                      key={item.emailID}
                      data={item}
                      isShowCheck={isShowTag}
                      handleChecked={handleChecked}
                      checkedEmail={emailSelected}
                      index={idx}
                      getHighlightedText={getHighlightedText}
                      displayType={displayType}
                      searchEmailInput={searchEmailInput}
                    />
                  ))}
                </>
              </div>
            )}
          </>
        )}
      </div>
      <div
        className={clsx(
          styles["tag-select"],
          displayType === DISPLAY_TYPE.preview ? "hide-element" : ""
        )}
      >
        {isShowFooter && (
          <TagExecuteMultiItems
            isShow={isShowTag}
            disableSelectButton={
              !emailList?.length || !emailListCurrent?.length || loading
            }
            handleBtnCancelSelect={handleBtnCancelSelect}
            disableTagButton={!emailSelected?.length}
            disableUnTagButton={!emailSelected?.length || !canUntag}
            handleSaveSelectTags={onSaveTag}
            isSelectedAll={isSelectAll}
            handleUnselectAll={handleUnselectAll}
            handleSelectAll={handleSelectAll}
            isDisabledPrev={isDisabledPrev || loading}
            isDisabledNext={isDisabledNext || loading}
            onSwitchHandle={onHandlePagingTable}
            handleIsShow={handleIsShow}
            handleIsAddTag={handleIsAddTag}
            isShowThreadNumber
            threadNumber={idDoc}
            isShowGoFirstLast
            isDisabledGoFirst={disableGoFirst || loading}
            isDisabledGoLast={disableGoLast || loading}
            handleShowFirstOrLastMsg={handleShowFirstOrLastEmail}
            isLoadingData={loading}
            isShowChangeOption={isShowChangeOption}
            disableTag={emailListCurrent?.length === 0}
          />
        )}
      </div>
    </div>
  );
};

export default EmailPreviewSelectTag;

EmailPreviewSelectTag.propTypes = {
  title: PropTypes.string,
  displayType: PropTypes.string,
  searchEmailInput: PropTypes.string,
  idDoc: PropTypes.string,
  emailResultTotal: PropTypes.number,
  totalRecord: PropTypes.number,
  isHideBorderWrap: PropTypes.bool,
  isDisabledPrev: PropTypes.bool,
  isDisabledNext: PropTypes.bool,
  isShowTag: PropTypes.bool,
  canUntag: PropTypes.bool,
  isSelectAll: PropTypes.bool,
  loading: PropTypes.bool,
  isShowFooter: PropTypes.bool,
  isTaggingEmail: PropTypes.bool,
  isShowChangeOption: PropTypes.bool,
  emailList: PropTypes.array,
  emailSelected: PropTypes.array,
  handleChecked: PropTypes.func,
  handleSelectAll: PropTypes.func,
  handleUnselectAll: PropTypes.func,
  onSaveTag: PropTypes.func,
  onHandlePagingTable: PropTypes.func,
  onSearchByDate: PropTypes.func,
  getHighlightedText: PropTypes.func,
  handleIsAddTag: PropTypes.func,
  handleIsShow: PropTypes.func,
  handleBtnCancelSelect: PropTypes.func,
  onSetParamIsNewestStore: PropTypes.func,
  setIsTaggingEmail: PropTypes.func,
  setIsShowTag: PropTypes.func,
  onClearSearchMsg: PropTypes.func,
  setSearchMsgInputStore: PropTypes.func,
  setIndex: PropTypes.func,
  index: PropTypes.number,
  setTotalSearchCount: PropTypes.func,
  totalSearchCount: PropTypes.number,
};
