const COLUMN_NAME_SOURCE = {
  deviceName: {
    value: "dataSourceDescription",
    label: "Device Name",
  },
  custodian: {
    value: "custodianName",
    label: "Custodian",
  },
  jobCategory: {
    value: "jobCategory",
    label: "Job Category",
  },
  createdDate: {
    value: "createdDate",
    label: "Created Date/Time",
  },
  importedDate: {
    value: "importedDate",
    label: "Added Date/Time",
  },
  status: {
    value: "importProcess",
    label: "Status",
  },
  action: {
    value: "action",
    label: "",
  },
};

const JOB_CATEGORY = {
  draft: { label: "Draft", value: 1 },
  basic: { label: "Basic", value: 2 },
  full: { label: "Full", value: 3 },
};

const IMPORT_PROCESS = {
  draft: {
    label: "Draft",
    value: 1,
    borderColor: "#D3D3D3",
    backgroundColor: "#F1F1F1",
  },
  inprogress: {
    label: "In Progress",
    value: 2,
    borderColor: "#1580EF",
    backgroundColor: "#DCECFD",
  },
  failed: {
    label: "Failed",
    value: 3,
    borderColor: "#FD6561",
    backgroundColor: "#FDEAE9",
  },
  completed: {
    label: "Completed",
    value: 4,
    borderColor: "#62B58F",
    backgroundColor: "#E7F4EE",
  },
  canceling: {
    label: "Canceling",
    value: 5,
    borderColor: "#FFC533",
    backgroundColor: "rgba(255, 197, 51, 0.15)",
  },
  ready: {
    label: "Ready",
    value: 6,
    borderColor: "#29C3BE",
    backgroundColor: "#DFF6F5",
  },
};

const IMPORT_TYPE = {
  datTeams: {
    value: 1,
    label: "DAT (Teams MSG)",
    typeExtension: "dat",
  },
  datAutoStreem: {
    value: 2,
    label: "DAT (AutoStreem)",
    typeExtension: "dat",
  },
  xmlCellebrite: {
    value: 3,
    label: "XML (Cellebrite)",
    typeExtension: "xml",
  },
  datSlackChat: {
    value: 4,
    label: "DAT (Slack - Chat)",
    typeExtension: "dat",
  },
};

const POPUP_TYPE = {
  delete: 1,
  cancel: 2,
  import: 3,
};

const IMPORT_BLOB_ACTION = {
  resume: 1,
  pause: 2,
};

export {
  COLUMN_NAME_SOURCE,
  JOB_CATEGORY,
  IMPORT_PROCESS,
  IMPORT_TYPE,
  POPUP_TYPE,
  IMPORT_BLOB_ACTION,
};
