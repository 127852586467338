import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import LooseFileInfoContainer from "../loose-file-info-container/LooseFileInfoContainer";

// Constants
import { TIMELINE_INFORMATION } from "constants/DataType";
import { PARAMETERS } from "constants/Common";

// Helpers
import { getDurationByUrl } from "helpers/UrlHelper";

const InfoAudio = ({ audioInfo, duration }) => {
  const [calculatedDuration, setGetDuration] = useState();
  const [infoResultList, setInfoResultList] = useState([]);

  const getDurationWhenNotExists = async () => {
    const newDuration = await getDurationByUrl(audioInfo.url);
    setGetDuration(newDuration);
  };

  useEffect(() => {
    if (duration) setGetDuration(duration);
    else getDurationWhenNotExists();

    setInfoResultList(TIMELINE_INFORMATION.video);
    const durationIndex  = infoResultList.indexOf((item) => item.key === PARAMETERS.duration);
    if (durationIndex !== -1) {
      infoResultList[durationIndex].value = calculatedDuration;
    }
  }, [duration]);

  return (
    <LooseFileInfoContainer
      infoResultLabelList={infoResultList}
      dataType={audioInfo[PARAMETERS.type]}
      itemInfo={audioInfo}    
    />
  );
};

InfoAudio.propTypes = {
  audioInfo: PropTypes.object,
  duration: PropTypes.string,
};

export default InfoAudio;
