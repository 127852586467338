import React from "react";
import PropTypes from "prop-types";

// Components
import LooseFileInfoContainer from "../loose-file-info-container/LooseFileInfoContainer";

// Constants
import { PARAMETERS } from "constants/Common";
import { TIMELINE_INFORMATION } from "constants/DataType";

const InfoDatabase = ({ databaseInfo }) => {
  return (
    <LooseFileInfoContainer
      infoResultLabelList={TIMELINE_INFORMATION.database}
      dataType={databaseInfo[PARAMETERS.type]}
      itemInfo={databaseInfo}
    />
  )
};

InfoDatabase.propTypes = {
  databaseInfo: PropTypes.object,
};

export default InfoDatabase;
