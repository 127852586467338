import { PATHNAME_ITEMS } from "./Common";

const ERROR_STATUS = {
  badRequest: 400,
  unAuthorized: 401,
  accessDenied: 403,
  pageNotFound: 404,
  requestTimeOut: 408,
  expectationFailed: 417,
  locked: 423,
  serverError: 500,
  databasePaused: 501,
};

const RESPONSE_STATUS = {
  success: 200,
  created: 201,
};

const ERROR_PAGES = [
  {
    path: PATHNAME_ITEMS.error400,
    code: ERROR_STATUS.badRequest,
    info: {
      status: "Bad Request",
      content:
        "Your verify link via email has expired. Please use Reset Password to access the website",
      imgErr: "/images/400-err.svg",
      textHyperLink: "Please click on this link to reset your password.",
    },
  },
  {
    path: PATHNAME_ITEMS.error500,
    code: ERROR_STATUS.serverError,
    info: {
      status: "Server Error!",
      content:
        "This page isn't working. Currently unable to handle this request. Please refresh this page or try again later.",
      imgErr: "/images/500-err.svg",
    },
  },
  {
    path: PATHNAME_ITEMS.error404,
    code: ERROR_STATUS.pageNotFound,
    info: {
      status: "Page not found!",
      content:
        "Sorry, but the page you are looking for was either not found or does not exist. Try refreshing the page or click back button to go back to Previous page.",
      imgErr: "/images/404-err.svg",
    },
  },
  {
    path: PATHNAME_ITEMS.error403,
    code: ERROR_STATUS.accessDenied,
    info: {
      status: "Access denied!",
      content: "You don't have permission to access on this page",
      imgErr: "/images/403-err.svg",
    },
  },
  {
    path: PATHNAME_ITEMS.error423,
    code: ERROR_STATUS.locked,
    info: {
      status: "Locked!",
      content: "The source or destination resource of a method is locked.",
      imgErr: "/images/423-err.svg",
    },
  },
  {
    path: PATHNAME_ITEMS.leftPath,
    info: {
      status: "Page not found!",
      content:
        "Sorry, but the page you are looking for was either not found or does not exist. Try refreshing the page or click back button to go back to Previous page.",
      imgErr: "/images/404-err.svg",
    },
  },
];

export { ERROR_STATUS, ERROR_PAGES, RESPONSE_STATUS };
