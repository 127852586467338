import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import InfoResultItem from "../info-result-item/InfoResultItem";
import TabList from 'components/shared/tab-list/TabList';

//Constants and Helpers
import { PARAMETERS } from "constants/Common";
import { getInformationByKey } from "helpers/RenderInformationHelper";

// Styles
import styles from "./LooseFileInfoContainer.module.scss";

const LooseFileInfoContainer = (props) => {
    const {
        infoResultLabelList,
        dataType,
        itemInfo,
    } = props;

    const tabs = [
        { id: 1, name: "General" },
        { id: 2, name: "Extended" },
    ];

    const [currentTab, setCurrentTab] = useState(1);

    return (
        <div className={styles["info-data-container"]}>
            <div className={styles["info-tab-list"]}>
                <TabList
                    tabList={tabs}
                    currentTab={{ parentTab: currentTab, childTab: null }}
                    size="md"
                    onClickTab={({ parentTab, childTab }) => setCurrentTab(parentTab)}
                />
            </div>
            <div className={styles["info-result-list"]}>
                {currentTab === 1 &&
                    infoResultLabelList?.map((item) => (
                        <InfoResultItem
                            key={item.label}
                            label={item.label}
                            value={getInformationByKey(item.key, itemInfo)}
                            isIcon={item.key === PARAMETERS.typeLabel}
                            type={dataType}
                        />
                    ))
                }
                {currentTab === 2 &&
                    (itemInfo?.extendedMetadataFields?.length > 0
                        ? (itemInfo?.extendedMetadataFields?.filter((field) => {
                            return field.fieldValue !== null && field.fieldValue !== "";
                        })?.map((field) => (
                            <InfoResultItem
                                key={field.fieldName}
                                label={field.fieldName}
                                value={field.fieldValue}
                                isIcon={false}
                                type={dataType}
                            />
                        )))
                        : (
                            <div className={styles["no-data"]}>
                                No extended information available.
                            </div>
                        ))
                }
            </div>
        </div>);
};

LooseFileInfoContainer.propTypes = {
    infoResultLabelList: PropTypes.array,
    dataType: PropTypes.string,
    itemInfo: PropTypes.object,
};

export default LooseFileInfoContainer;
