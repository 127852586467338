import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { camelCase, get } from "lodash";
import clsx from "clsx";

// Services
import {
  getGeneralReviewDetailApi,
  getGeneralReviewQuickSelect,
  exportGeneralReviewToExcel,
  getGroupMsgInfo,
} from "services/GeneralReviewService";
import { exportGroupMessageToExcel } from "services/ISService";
import { handleAbortRequest } from "services/ApiConfig";
import {
  exportEmailDetailToExcelApi,
  getEmailReviewDetailApi,
} from "services/EmailReviewService";
import { 
  searchStreems, 
  getSearchHits 
} from "services/SearchService";

//Helpers
import { onConvertMessages } from "helpers/ConversationHelper";
import {
  calcRecordNumber,
  nextRecord,
  onSwitchRecord,
  previousRecord,
} from "helpers/PagingHelper";
import {
  convertContact,
  convertFilter,
  convertTag,
  convertTypeToObject,
  getFilterValue,
  getFilterLabel,
  setPathToLocalStorage,
} from "helpers/CommonHelper";
import { convertDataSourceInfo } from "helpers/DataSourceHelper";
import { injectLinkToHtml } from "helpers/TextHelper";
import { getEmailWordClouds } from "helpers/EmailReviewHelper";
import { getCurrentTime } from "helpers/DateTimeFormatterHelper";

// Components
import { SearchResult } from "components/intelligent-search/search-result/result/SearchResult";
import PrintExport from "components/shared/print-export/PrintExport";
import InformationContainer from "components/intelligent-search/search-result/information/InformationContainer";
import PreviewResultContainer from "components/intelligent-search/search-result/preview/PreviewResultContainer";
import QuickFilter from "components/shared/quick-filter/QuickFilter";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import PIIBubble from "components/review/email/PIIBubble/PIIBubble";
import { ParticipantsInformation } from "components/chat-email-information/participants/ParticipantsInformation";
import WordCloud from "components/word-cloud/WordCloud";
import EmojiCloud from "components/emoji-cloud/EmojiCloud";
import DataSourceInformation from "components/chat-email-information/data-source-info/DataSourceInformation";
import Tags from "components/tags/Tags";

// constants
import {
  PATH_NAME,
  PRINT_SCREEN_NAME,
  QUICK_SELECT_ITEM,
  TYPE_CLICK,
  PAGE_NAME,
  PAGINATION,
} from "constants/Common";
import {
  CHAT_EMAIL_TYPE,
  DATA_TYPE,
  DATA_TYPE_DISPLAY_PII,
} from "constants/DataType";
import { breadCrumbStreems } from "constants/BreadCrumbConstants";
import { LOCAL_STORAGE } from "constants/LocalStorage";

// Store
import {
  updateDateTime,
  clearDatePickerData,
  resetDateTimeMess,
} from "store/DatePickerReducer";
import {
  setQuickSelect,
  setParamSearchReview,
  setGeneralInfoPreview,
  setPaging,
  setResultMessageCount,
  setFilteredTags,
  resetGeneralInfoPreview,
  setIdDoc,
  setGeneralReviews,
  resetStoreReview,
  paramReviewInit,
  setAllUntagged,
  setSearchError
} from "store/GeneralReviewReducer";
import { fetchTagsList } from "store/TagReducer";
import { clearHeatmapData } from "store/HeatMapReducer";
import { setIsGoToOrigin } from "store/CommonReducer";
import { fetchDataSourceList } from "store/DataSourceReducer";
import { mapSearchError } from "helpers/SearchErrorHelper"

// Styles
import styles from "./GeneralReviewPage.module.scss";
import { resetSearchContext, setSearchContextQuickFilter } from "store/MassTagReducer";

const GeneralReviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const inputRef = useRef();
  const searchInputRef = useRef();
  const { projectId } = useParams();

  const pageSize = PAGINATION.recordPerPageTable;
  const pageNumberDefault = PAGINATION.pageNumberDefault;
  const [isResultLoading, setIsResultLoading] = useState(false);
  const [reviewType, setReviewType] = useState("");
  const [isQuickFilterLoading, setIsQuickFilterLoading] = useState(true);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [isSwitchChat, setIsSwitchChat] = useState(false);
  const [totalSearchCount, setTotalSearchCount] = useState(0);
  const [totalMessageContainKeyWord, setTotalMessageContainKeyWord] =
    useState(0);
  const [listPageNumber, setListPageNumber] = useState([]);
  const [currentID, setCurrentID] = useState(null);
  const [isPaging, setIsPaging] = useState(true);
  const [allFirstLoadMessages, setAllFirstLoadMessages] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isFirstLoadComponent, setIsFirstLoadComponent] = useState(true);
  const [dataQuickFilter, setDataQuickFilter] = useState([]);
  const [messageInfo, setMessageInfo] = useState({
    emojis: [],
    participants: [],
    wordClouds: [],
  });
  const filterPreviewInit = {
    End: null,
    Search: null,
    TagFilter: [],
    IsNewest: false,
    PageNumber: pageNumberDefault,
    Start: null,
    includeBlankDate: true,
  };
  const [filterPreview, setFilterPreview] = useState(filterPreviewInit);

  // Get data from store
  const { selectTags, showTags } = useSelector((state) => state.tag.tags);
  const {
    quickSelect = {},
    generalReviews = [],
    generalInfoPreview = {},
    paramSearch = {},
    paging,
    idDoc,
    filteredTags,
    selectAllUntagged
  } = useSelector((state) => state.generalRV);

  const isGoToOrigin = useSelector((state) => state.common.isGoToOrigin);
  const dataSourceList = useSelector(
    (state) => state.dataSource.dataSourceList
  );

  const {
    messageViewDatePicker: dateFilterMsg,
    datePicker: { includeBlankDate },
  } = useSelector((state) => state.datePicker);

  const {
    searchContext,
  } = useSelector((state) => state.massTag);

  const previousPath = localStorage.getItem(LOCAL_STORAGE.previousPath);

  // Fetch data display quick select
  const fetchQueryTypes = async () => {
    setIsQuickFilterLoading(true);
    try {
      const { data } = await getGeneralReviewQuickSelect(projectId);
      dispatch(
        setQuickSelect({
          dataSources: get(data, "custodianSources", []),
          dataTypes: get(data, "dataTypes", []),
          entities: get(data, "entities", []),
          participants: get(data, "participants", [])
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsQuickFilterLoading(false);
    }
  };

  // Fetch result general review list
  const fetchGeneralReview = async (body, isSubmitByDate = false) => {
    let data = body;
    if (!isSubmitByDate) data = { ...body, includeBlankDate };
    setIsResultLoading(true);
    try {
      const { data: dataResult = {} } = await searchStreems(
        projectId,
        { ...data, isMTD: state?.isMTD || false }
      );
      var reviewItems = get(dataResult, "items", []);
      dispatch(setGeneralReviews(reviewItems));
      dispatch(setSearchError(''));
      dispatch(
        setPaging({
          totalRecords: get(dataResult, "totalRecords", 0),
        })
      );
      setIsResultLoading(false);
    } catch (error) {
      const errorMessage = mapSearchError(error?.response?.data);
      dispatch(setSearchError(errorMessage))
      dispatch(setGeneralReviews([]));
      dispatch(resetGeneralInfoPreview());
      dispatch(setPaging({ totalRecords: 0 }));
      if (!error.response?.status) return;
      setIsResultLoading(false);
    }
  };

  const handleSearchEmailDetailByDate = (data) => {
    const dateFilter = {
      ...filterPreview,
      Start: data.dateTimeDataStart || null,
      End: data.dateTimeDataEnd || null,
      includeBlankDate: data.includeBlankDate,
    };
    setFilterPreview(dateFilter);
    getGeneralReviewDetail(dateFilter, false);
  };

  const handleSelectedTagsChange = (newSelectedTags) => {
    handleAbortRequest();
    const paramSearchPreview = {
      ...filterPreview,
      TagFilter: newSelectedTags,
      PageNumber: pageNumberDefault,
    };

    setIsPreviewLoading(true);
    fetchGroupMessage(currentID, paramSearchPreview, false);

    dispatch(setFilteredTags(newSelectedTags));
  };

  const onClickWord = (word) => {
    handleAbortRequest();
    dispatch(setParamSearchReview({ searchMsgInput: word.trim() }));
    const paramSearchPreview = {
      ...filterPreview,
      Search: word.trim(),
      TagFilter: filterPreview.TagFilter ?? filteredTags,
      PageNumber: pageNumberDefault,
    };
    if ([DATA_TYPE.chatGroup, DATA_TYPE.chat].includes(reviewType)) {
      setIsPreviewLoading(true);
      fetchGroupMessage(currentID, paramSearchPreview, false);
    }
  };

  const fetchGroupMessage = async (chatId, param = {}, isFetchInfo = true) => {
    try {
      if (isFetchInfo) fetchInformationChat(chatId);
      const searchFilter = param.Search ?? paramSearch?.searchMsgInput;
      const tagFilter = param.TagFilter ?? filteredTags;
      const updatedParam = { ...param, projectId, chatId, Search: searchFilter, TagFilter: tagFilter };
      const { data } = await getSearchHits(updatedParam);
      let messages = onConvertMessages(data?.instantMessages, 0);
      const chatCurrent = generalReviews.find((item) => item.id === chatId);
      setAllFirstLoadMessages(messages);
      dispatch(setResultMessageCount(chatCurrent.itemsCount));
      dispatch(
        setGeneralInfoPreview({
          deletedState: chatCurrent?.deleted,
          id: chatCurrent?.id,
          instantMessages: messages,
          type: chatCurrent?.type,
          chatID: chatCurrent?.id,
          totalMessage: data?.totalMessage,
          source: data?.sources,
        })
      );
      // set total search count when have search input
      if (
        paramSearch?.searchMsgInput ||
        generalInfoPreview?.instantMessages?.length > 0
      ) {
        setTotalSearchCount(data?.searchCount);
        setTotalMessageContainKeyWord(data?.messageCount);
      } else {
        setTotalSearchCount(0);
        setTotalMessageContainKeyWord(0);
      }
      setListPageNumber(data?.pageNumbers);
    } catch (error) {
      if (!error.response.status) return;
      dispatch(resetGeneralInfoPreview());
    } finally {
      setIsSwitchChat(false);
      setIsPreviewLoading(false);
    }
  };

  const fetchGeneralDetail = async (type, threadId, param) => {
    try {
      const response = await getGeneralReviewDetailApi(
        camelCase(type),
        projectId,
        threadId,
        param
      );
      dispatch(setGeneralInfoPreview(response.data));
    } catch (error) {
      dispatch(resetGeneralInfoPreview());
      if (!error.response.status) return;
      setIsPreviewLoading(false);
      setInfoLoading(false);
    } finally {
      setIsSwitchChat(false);
      setIsPreviewLoading(false);
      setInfoLoading(false);
    }
  };

  // Fetch data display quick select
  const fetchEmailReviewDetail = async (
    threadId,
    param,
    isUpdateInfo = true
  ) => {
    if (!threadId) return;
    isUpdateInfo && setInfoLoading(true);
    try {
      const { dateTimeDataStart, dateTimeDataEnd } = dateFilterMsg;
      const { data } = await getEmailReviewDetailApi(projectId, threadId, {
        Start: dateTimeDataStart,
        End: dateTimeDataEnd,
        PageNumber: pageNumberDefault,
        ...param,
      });
      dispatch(
        setGeneralInfoPreview({
          id: threadId,
          emails: data.email.map((item) => ({
            ...item,
            body: item.body ?
              injectLinkToHtml(item.body) : ""
          })),
        })
      );
      isUpdateInfo &&
        setMessageInfo({
          participants: data.participants,
          wordClouds: getEmailWordClouds(data),
          emojis: data.emoji,
        });
    } catch (error) {
      if (!error.response?.status) return;
      dispatch(resetGeneralInfoPreview());
    } finally {
      setIsPreviewLoading(false);
      setInfoLoading(false);
    }
  };

  // Get search result detail and dispatch to store
  const getGeneralReviewDetail = (param = {}, isFetchInfo = true) => {
    if (!currentID) return;
    if (CHAT_EMAIL_TYPE.includes(reviewType)) setInfoLoading(true);
    setIsPreviewLoading(true);
    setIsSwitchChat(true);
    if ([DATA_TYPE.chat, DATA_TYPE.chatGroup].includes(reviewType))
      fetchGroupMessage(currentID, param, isFetchInfo);
    else if ([DATA_TYPE.email, DATA_TYPE.emailThread].includes(reviewType))
      fetchEmailReviewDetail(currentID, param);
    else fetchGeneralDetail(reviewType, currentID, param);
  };

  // Handle paging
  const onClickPagingHandle = (event) => {
    handleAbortRequest();
    setIsPaging(true);
    dispatch(setPaging({ pageIndex: event.selected }));
    let dataSearch = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: event.selected + 1,
      },
    };
    fetchGeneralReview(dataSearch);
    dispatch(setParamSearchReview(dataSearch));
  };

  const applyQS = useCallback((data) => {
    // Clear searchInputRef when input search box
    if (searchInputRef.current) searchInputRef.current.clearText();
    handleAbortRequest();
    setCurrentID(null);
    const { entities, dataSources, dataTypes, participants, tags } =
      data;
    const isSelectAllParticipant =
      participants.length === quickSelect.participants.length;

    const bodyData = {
      ...paramSearch,
      dataSources: dataSources.map((item) => ({
        custodianName: item.label,
        dataSourceId: item.value,
        dataSourceType: item.sourceType,
      })),
      entities: getFilterValue(entities),
      dataTypes: getFilterValue(dataTypes),
      participants: isSelectAllParticipant
        ? []
        : getFilterValue(participants),
      tags: getFilterValue(tags),
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: pageNumberDefault,
      },
      //reset search when new action filter
      searchInput: null,
      searchMsgInput: null,
      heatmapParticipants: [], // reset heatmap participants when new apply
      selectAllUntagged: selectAllUntagged
    };
    fetchGeneralReview(bodyData);
    dispatch(setParamSearchReview(bodyData));
    dispatch(resetDateTimeMess());
    dispatch(setPaging({ pageIndex: 0, recordNumber: 0 }));
    setFilterPreview(filterPreviewInit);
  });

  const goBack = () =>
    previousPath
      ? navigate(`/${PATH_NAME.matters}/${projectId}/${previousPath}`)
      : navigate(`/${PATH_NAME.matters}`);

  // Handle breadcrumb data
  const getBreadCrumb = () =>
    previousPath ? breadCrumbStreems(previousPath) : breadCrumbStreems();

  const onSubmitSearch = () => {
    handleAbortRequest();
    setCurrentID(null);
    const inputValue = inputRef?.current?.value?.trim();
    fetchGeneralReview({
      ...paramSearch,
      searchInput: inputValue || "",
      tags: paramSearch.tags,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: pageNumberDefault,
        pageSize,
      },
    });
    dispatch(
      setParamSearchReview({
        searchInput: inputValue,
        searchMsgInput: inputValue,
        paginationParams: {
          ...paramSearch.paginationParams,
          pageNumber: pageNumberDefault,
          pageSize,
        },
        isSecondSearch: false,
      })
    );
    dispatch(setPaging({ recordNumber: 0, pageIndex: 0 }));
  };

  const onSearchByDate = (data) => {
    handleAbortRequest();
    const { dateTimeDataStart, dateTimeDataEnd } = data;
    let dataSearch = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: pageNumberDefault,
        pageSize,
      },
      start: data.dateTimeDataStart || null,
      end: data.dateTimeDataEnd || null,
      includeBlankDate: data.includeBlankDate,
    };
    fetchGeneralReview(dataSearch, true);
    dispatch(setPaging({ recordNumber: 0, pageIndex: 0 }));
    dispatch(updateDateTime({ dateTimeDataStart, dateTimeDataEnd }));
    dispatch(setParamSearchReview(dataSearch));
  };

  const onSortTable = (data) => {
    handleAbortRequest();
    const dataRequest = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        ...data,
        pageSize,
      },
    };
    fetchGeneralReview(dataRequest);
    dispatch(setParamSearchReview(dataRequest));
  };

  const onHandlePagingTable = (type) => {
    const { recordNumber = 0, totalRecords = 0, pageIndex = 0 } = paging;
    setIsPaging(false);
    dispatch(resetGeneralInfoPreview());
    // only dispatch data when type is next + current record is not the last record or type is not next
    if (
      (type === TYPE_CLICK.next &&
        calcRecordNumber(pageIndex, recordNumber) < totalRecords) ||
      type !== TYPE_CLICK.next
    ) {
      let item = null;
      if (type === TYPE_CLICK.next && nextRecord(recordNumber) < pageSize) {
        item = generalReviews[nextRecord(recordNumber)];
        dispatch(setIdDoc(item.docID));
      } else {
        item = generalReviews[previousRecord(recordNumber)];
        if (recordNumber > 0) dispatch(setIdDoc(item.docID));
      }
      if (item) {
        setCurrentID(item.id);
        setReviewType(item.type);
      }
      const pagingRequest = onSwitchRecord(
        type,
        pageIndex,
        recordNumber,
        generalReviews.length,
        totalRecords
      );
      dispatch(setPaging(pagingRequest));
    }
  };

  // Get search result detail
  const onClickRecord = (data) => {
    if (!data) return;
    if (!isPreviewLoading && currentID !== data.id) {
      dispatch(setIdDoc(data.docID));
      dispatch(resetGeneralInfoPreview());
      dispatch(setPaging({ recordNumber: data.index }));
      setCurrentID(data.id);
      setReviewType(data.type);
    }
  };

  const onSetParamIsNewestStore = (isNewest) => {
    dispatch(setParamSearchReview({ isNewest }));
  };

  const resetParamSearch = () => {
    // Clear searchInputRef when input search box
    if (searchInputRef.current) searchInputRef.current.clearText();
    handleAbortRequest();
    const bodyData = {
      ...paramSearch,
      searchInput: null,
      searchMsgInput: null,
      dataSources: [],
      dataTypes: [],
      entities: [],
      participants: [],
      heatmapParticipants: [],
      tags: [],
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: pageNumberDefault,
      },
      selectAllUntagged: false
    };
    setCurrentID(null);
    dispatch(setParamSearchReview(bodyData));
    dispatch(resetDateTimeMess());
    dispatch(setAllUntagged(false));
    dispatch(setSearchContextQuickFilter({...searchContext, selectAllUntagged: false }));
    fetchGeneralReview(bodyData);
    dispatch(setPaging({ pageIndex: 0, recordNumber: 0 }));
    setFilterPreview(filterPreviewInit);
  };

  const setSearchMsgInputStore = (keyword, isSecondSearch) => {
    if (isSecondSearch)
      dispatch(
        setParamSearchReview({ isSecondSearch, searchMsgInput: keyword })
      );
    else dispatch(setParamSearchReview({ searchMsgInput: keyword }));
  };

  const setInstantMessagesStore = (lstMessages, totalMessage = -1) => {
    dispatch(
      setGeneralInfoPreview({
        ...generalInfoPreview,
        instantMessages: [...lstMessages],
        totalMessage:
          totalMessage === -1 ? generalInfoPreview?.totalMessage : totalMessage,
      })
    );
  };

  const downloadFile = (blobData, fileName) => {
    const url = window.URL.createObjectURL(blobData);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const exportListResultsToExcel = async () => {
    setExportLoading(true);
    const bodyParams = {
      ...paramSearch,
      includeBlankDate,
    };
    try {
      const result = await exportGeneralReviewToExcel(projectId, bodyParams);
      downloadFile(result.data, `StreemView_Streems_${getCurrentTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  const exportMessageToExcel = async () => {
    setExportLoading(true);
    const { dateTimeDataStart, dateTimeDataEnd, includeBlankDate } =
      dateFilterMsg;

    try {
      const params = {
        Start: dateTimeDataStart,
        End: dateTimeDataEnd,
        PageNumber: pageNumberDefault,
        includeBlankDate,
        isNewest: paramSearch?.isNewest,
        chatID: generalInfoPreview?.chatID,
        pageName: PAGE_NAME.streems,
        Search: filterPreview.Search,
      };
      const result = await exportGroupMessageToExcel(
        projectId,
        currentID,
        params
      );
      downloadFile(result.data, `StreemView_Messages_${getCurrentTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  const exportEmailDetailToExcel = async () => {
    setExportLoading(true);
    try {
      const { dateTimeDataStart, dateTimeDataEnd, includeBlankDate } =
        dateFilterMsg;
      const params = {
        Start: dateTimeDataStart,
        End: dateTimeDataEnd,
        includeBlankDate,
        PageNumber: pageNumberDefault,
        isNewest: paramSearch?.isNewest,
        pageName: PAGE_NAME.streems,
        SearchInput: filterPreview.Search,
      };
      const result = await exportEmailDetailToExcelApi(
        projectId,
        currentID,
        params
      );
      downloadFile(result.data, `StreemView_Email_${getCurrentTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  const fetchSearchHitsApi = async (_, chatId, filter = {}) => {
    setFilterPreview(filter);
    const searchFilter = filter.Search ?? paramSearch?.searchMsgInput
    const tagFilter = filter.TagFilter ?? filteredTags;
    const updatedFilter = { ...filter, projectId, chatId, Search: searchFilter, TagFilter: tagFilter };
    return await getSearchHits(updatedFilter);
  };

  const handleToggle = () => {
    handleAbortRequest();
    dispatch(setPaging({ pageIndex: 0, recordNumber: 0 }));
    const dataRequest = {
      ...paramSearch,
      isShowRecents: !paramSearch.isShowRecents,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: pageNumberDefault,
        pageSize,
      },
    };

    fetchGeneralReview(dataRequest);
    dispatch(setParamSearchReview(dataRequest));
  };

  const getFunctionExport = () => {
    const isChatType = [DATA_TYPE.chat, DATA_TYPE.chatGroup].includes(reviewType);
    const isChatOrEmailType = CHAT_EMAIL_TYPE.includes(reviewType);
    const exportFunctions = [];

    if (isChatOrEmailType) {
      exportFunctions.push({
        exportName: "Export Threads",
        onclick: exportListResultsToExcel,
      });

      if (isChatType) {
        exportFunctions.push({
          exportName: "Export Message View",
          onclick: exportMessageToExcel,
        });
      } else {
        exportFunctions.push({
          exportName: "Export Email Details",
          onclick: exportEmailDetailToExcel,
        });
      }
    }

    return exportFunctions;
  };

  const getDataSourceInfo = () => {
    const listDataSourceId =
      generalReviews[paging.recordNumber]?.dataSourceID?.split(/,\s*/) || [];
    return convertDataSourceInfo(dataSourceList, listDataSourceId);
  };

  const getDataQuickFilter = () => {
    const { dataSources, dataTypes, entities, participants, tags } =
      quickSelect;
    setDataQuickFilter([
      {
        filterLabel: getFilterLabel(QUICK_SELECT_ITEM.dataSources.value),
        filterType: QUICK_SELECT_ITEM.dataSources.value,
        filterData: {
          dataSelected: convertFilter(
            paramSearch.dataSources,
            QUICK_SELECT_ITEM.dataSources.value
          ),
          dataUnSelect: convertFilter(
            dataSources,
            QUICK_SELECT_ITEM.dataSources.value
          ),
        },
      },
      {
        filterLabel: getFilterLabel(QUICK_SELECT_ITEM.dataTypes.value),
        filterType: QUICK_SELECT_ITEM.dataTypes.value,
        filterData: {
          dataSelected: convertTypeToObject(dataTypes, paramSearch.dataTypes),
          dataUnSelect: dataTypes,
        },
      },
      {
        filterLabel: getFilterLabel(QUICK_SELECT_ITEM.entities.value),
        filterType: QUICK_SELECT_ITEM.entities.value,
        filterData: {
          dataSelected: convertFilter(
            convertContact(entities, paramSearch.entities),
            QUICK_SELECT_ITEM.entities.value
          ),
          dataUnSelect: convertFilter(
            entities,
            QUICK_SELECT_ITEM.entities.value
          ),
        },
      },
      {
        filterLabel: getFilterLabel(QUICK_SELECT_ITEM.identifiers.value),
        filterType: QUICK_SELECT_ITEM.participants.value,
        filterData: {
          dataSelected: convertFilter(
            convertContact(participants, paramSearch.participants),
            QUICK_SELECT_ITEM.participants.value
          ),
          dataUnSelect: convertFilter(
            participants,
            QUICK_SELECT_ITEM.participants.value
          ),
        },
      },
      {
        filterLabel: getFilterLabel(QUICK_SELECT_ITEM.tags.value),
        filterType: QUICK_SELECT_ITEM.tags.value,
        filterData: {
          dataSelected: convertFilter(
            convertTag(tags, paramSearch.tags),
            QUICK_SELECT_ITEM.tags.value
          ),
          dataUnSelect: convertFilter(tags, QUICK_SELECT_ITEM.tags.value),
        },
        notSelectedOption: {
          label: "Select Not Tagged",
          onSelectCallback: (isSelected) => {
            dispatch(setAllUntagged(isSelected));
            dispatch(setSearchContextQuickFilter({...searchContext, selectAllUntagged: isSelected }));
          }
        }
      },
    ]);
  };

  useEffect(() => {
    if (isQuickFilterLoading) {
      dispatch(resetSearchContext());
      return;
    }
    getDataQuickFilter();
  }, [isQuickFilterLoading, quickSelect]);

  // Fetch data display quick select and cache general review store if back to item detail
  useEffect(() => {
    // set previous path when first load page
    setPathToLocalStorage(state?.fromScreen);

    if (isGoToOrigin && generalReviews?.length > 0) {
      setIsQuickFilterLoading(false);
      setIsFirstLoadComponent(false);
      dispatch(setIsGoToOrigin(false));
      return;
    } else fetchGeneralReview(paramSearch);

    dispatch(fetchTagsList({ projectId }));
    dispatch(fetchDataSourceList(projectId));
    fetchQueryTypes();
    setIsFirstLoadComponent(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoadComponent) {
      fetchQueryTypes();
      fetchGeneralReview(paramReviewInit);
      dispatch(resetStoreReview());
      dispatch(clearDatePickerData());
      dispatch(clearHeatmapData()); // Clear store communication analyzer
      setFilterPreview(filterPreviewInit);
      dispatch(fetchTagsList({ projectId }));
      dispatch(fetchDataSourceList(projectId));
    }
  }, [projectId]);

  useEffect(() => {
    dispatch(setQuickSelect({ tags: selectTags || [] }));
  }, [selectTags, isQuickFilterLoading]);

  useEffect(() => {
    dispatch(setFilteredTags([]));
    const fetchNewApiWhenAbort = () => {
      if (currentID && reviewType) {
        setIsFirstLoad(false);
        getGeneralReviewDetail({
          ...filterPreview,
          TagFilter: [],
          PageNumber: pageNumberDefault,
        });
      } else dispatch(resetGeneralInfoPreview());
    };
    fetchNewApiWhenAbort();
  }, [currentID]);

  useEffect(() => {
    setFilterPreview({ ...filterPreview, Search: paramSearch.searchMsgInput });
  }, [paramSearch.searchMsgInput]);

  useEffect(() => {
    setFilterPreview({ ...filterPreview, TagFilter: filteredTags });
  }, [filteredTags]);

  useEffect(() => {
    const bodyParams = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: paging.pageIndex + 1,
        pageSize,
      },
    };

    if (!isPaging) {
      fetchGeneralReview(bodyParams);
      dispatch(setParamSearchReview(bodyParams));
    }
  }, [paging.pageIndex]);

  //fetch data information of chat
  const fetchInformationChat = async (chatId) => {
    setInfoLoading(true);
    try {
      const { data } = await getGroupMsgInfo(projectId, chatId, {});
      if (!data) return;
      setMessageInfo({
        emojis: data?.emoji,
        participants: data?.participants,
        wordClouds: data?.wordCloud,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setInfoLoading(false);
    }
  };

  useEffect(() => {
    if (!isResultLoading) {
      if (generalReviews.length > 0) {
        dispatch(setIdDoc(generalReviews[paging?.recordNumber]?.docID));
        if (generalReviews[0].type === DATA_TYPE.chatGroup) {
          dispatch(
            setParamSearchReview({
              searchMsgInput: paramSearch?.searchMsgInput ?? '',
            })
          );
        }
        const item = generalReviews[paging?.recordNumber];
        if (item) {
          setCurrentID(item.id);
          setReviewType(item.type);
        }
      } else {
        setCurrentID(null);
        setReviewType(null);
      }
    }
  }, [isResultLoading]);

  return (
    <div className={clsx("main", styles["general-rw-wrap"])}>
      <div className={styles["general-rw-head"]}>
        <BreadCrumb goBack={goBack} breadCrumbData={getBreadCrumb()} />
        <div className={styles["general-rw-head-control"]}>
          <div className={styles["general-rw-head-info"]}>
            <h2 className={styles["general-rw-head-title"]}>
              {PAGE_NAME.streems}
            </h2>
          </div>
          <div className={styles["general-rw-head-option"]}>
            <PrintExport
              screenName={PRINT_SCREEN_NAME.streems}
              isDisplayTime
              exportOption={getFunctionExport()}
              handleExport={exportListResultsToExcel}
              exportLoading={exportLoading}
            />
          </div>
        </div>
      </div>
      <div className={styles["general-rw-body"]}>
        <div className={clsx(styles["general-rw-quick-select"])}>
          <QuickFilter
            data={dataQuickFilter}
            loading={isQuickFilterLoading}
            onApply={applyQS}
            onReset={resetParamSearch}
          />
        </div>
        <div className={styles["general-rw-search-result"]}>
          <SearchResult
            paging={paging}
            loading={isResultLoading}
            isResultsData={generalReviews}
            inputRef={inputRef}
            searchInput={paramSearch?.searchInput}
            onSort={onSortTable}
            onClickPagingHandle={onClickPagingHandle}
            onSwitchRecord={onClickRecord}
            onSubmitSearch={onSubmitSearch}
            onSubmitDate={onSearchByDate}
            handleToggle={handleToggle}
            isShowRecent={paramSearch.isShowRecents}
            reviewType={reviewType}
            searchInputRef={searchInputRef}
            dataSourceList={dataSourceList}
          />
          <div
            className={
              styles["general-rw-result-info-and-preview"]
            }
          >
            {!CHAT_EMAIL_TYPE.includes(reviewType) && (
              <div className={styles["general-rw-result-info"]}>
                <InformationContainer
                  loading={isPreviewLoading || isResultLoading}
                  reviewType={reviewType}
                  data={generalInfoPreview}
                  totalRecords={paging.totalRecords}
                />
              </div>
            )}

            {CHAT_EMAIL_TYPE.includes(reviewType) && (
              <div className={styles["general-rw-result-info"]}>
                <div className={styles["rw-wrap"]}>
                  <div className={styles["rw-title-wr"]}>
                    <div className={styles["rw-title"]}>
                      <h5>
                        {DATA_TYPE.emailThread === reviewType
                          ? "Email Information"
                          : "Chat Information"}
                      </h5>
                      {idDoc && <span>{idDoc}</span>}
                    </div>
                    
                  </div>
                  <div className={styles["review-info"]}>
                    <DataSourceInformation
                      isLoading={infoLoading || isResultLoading}
                      dataSources={getDataSourceInfo()}
                    />
                    <ParticipantsInformation
                      isLoading={infoLoading || isResultLoading}
                      participants={messageInfo.participants}
                      isRedColor={CHAT_EMAIL_TYPE.includes(reviewType)}
                    />
                    {reviewType === DATA_TYPE.chat && (
                      <Tags 
                        isLoading={infoLoading || isResultLoading}
                        tagList={selectTags}
                        onSelectTags={handleSelectedTagsChange}
                      />
                    )}
                    <WordCloud
                      listConversation={generalReviews}
                      isLoading={infoLoading || isResultLoading}
                      data={messageInfo.wordClouds}
                      onClick={onClickWord}
                    />
                    <EmojiCloud
                      listConversation={generalReviews}
                      isLoading={infoLoading || isResultLoading}
                      data={messageInfo.emojis}
                      onClick={onClickWord}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={styles["general-rw-result-preview"]}>
              <PreviewResultContainer
                displayType="review"
                loading={isPreviewLoading || isResultLoading || infoLoading}
                setIsResultLoading={setIsResultLoading}
                reviewType={reviewType}
                data={generalInfoPreview}
                participants={messageInfo.participants}
                paging={paging}
                totalItemOfPage={generalReviews.length}
                searchMsgInput={paramSearch?.searchMsgInput || ""}
                dateTime={dateFilterMsg}
                onHandlePagingTable={onHandlePagingTable}
                totalSearchCount={totalSearchCount}
                tagsList={selectTags}
                showTagsList={showTags}
                setTotalSearchCount={setTotalSearchCount}
                totalMessageContainKeyWord={totalMessageContainKeyWord}
                setTotalMessageContainKeyWord={setTotalMessageContainKeyWord}
                listPageNumber={listPageNumber}
                setListPageNumber={setListPageNumber}
                setSearchMsgInputStore={setSearchMsgInputStore}
                onSetParamIsNewestStore={onSetParamIsNewestStore}
                setInstantMessagesStore={setInstantMessagesStore}
                isSwitchChat={isSwitchChat}
                handleSearchEmailDetailByDate={handleSearchEmailDetailByDate}
                fetchChatDetailApi={fetchSearchHitsApi}
                allFirstLoadMessages={allFirstLoadMessages}
                titleActivity={PAGE_NAME.streems}
                isFirstLoad={isFirstLoad}
                setIsFirstLoad={setIsFirstLoad}
                idDoc={idDoc}
                currentID={currentID}
                onClearSearchMsg={() =>
                  dispatch(setParamSearchReview({ searchMsgInput: null }))
                }
              />
            </div>
            {DATA_TYPE_DISPLAY_PII.includes(reviewType) ? <PIIBubble /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralReviewPage;
