import React from "react";
import PropTypes from "prop-types";

// Components
import LooseFileInfoContainer from "../loose-file-info-container/LooseFileInfoContainer";

//Constants and Helpers
import { PARAMETERS } from "constants/Common";
import { TIMELINE_INFORMATION } from "constants/DataType";

const InfoImage = ({ imageInfo }) => {
  return (
    <LooseFileInfoContainer
      infoResultLabelList={TIMELINE_INFORMATION.image}
      dataType={imageInfo[PARAMETERS.type]}
      itemInfo={imageInfo}
    />)
};

InfoImage.propTypes = {
  imageInfo: PropTypes.object,
};

export default InfoImage;
