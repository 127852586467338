import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

// Styles
import styles from "./Tags.module.scss";

const Tags = ({
  isLoading,
  tagList,
  onSelectTags
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [updateTags, setUpdateTags] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (tagID) => {
    if (selectedTags.includes(tagID))
      setSelectedTags((prevSelectedTags) => prevSelectedTags.filter((id) => id !== tagID));
    else 
      setSelectedTags((prevSelectedTags) => [...prevSelectedTags, tagID]);

    setUpdateTags(true);
  };

  useEffect(() => {
    if (updateTags)
    {
        onSelectTags(selectedTags);
        setUpdateTags(false);
    }
  }, [selectedTags]);

  useEffect(() => {
    setSelectedTags([]);
  }, [isLoading]);

  const renderTags = (tags) => {
    return tags.map((tag) => (
      <div key={tag.tagID} className={clsx(styles["tag-item"])}>
        <input
          type="checkbox"
          className={clsx(styles["tag-checkbox"])}
          value={tag.tagID}
          id={`tagCheckbox_${tag.tagID}`}
          checked={selectedTags.includes(tag.tagID)}
          onChange={() => handleCheckboxChange(tag.tagID)}
        />
        <span
            className={clsx(styles["tag-color"])}
            style={{ backgroundColor: tag.color }}
            title={tag.name}
          />
        <label htmlFor={`tagCheckbox_${tag.tagID}`}>
          <span className={clsx(styles["tag-name"])}>
            {tag.name}
          </span>
        </label>
      </div>
    ));
  };
  
  return (
    <>
    <div className={styles["tags-label"]}>
        <p className={styles["tags-title"]}>Tags</p>
        <img
          src="/images/icons/collapse-icon.svg"
          alt="collapse-icon"
          className={clsx(
            "cursor-pointer",
            styles[isOpen ? "collapse-icon" : "expand-icon"]
          )}
          onClick={handleToggle}
        />
      </div>
      {isOpen && (
        <>
        {!isLoading ? (
          <div className={clsx(styles["container"])}>
            {renderTags(tagList)}
          </div>
        ) : (
          <div className={styles["spinner-container"]}>
            <Spinner
              className={styles["spinner-loading"]}
              animation="border"
              variant="primary"
              role="status"
            />
          </div>
        )}
      </>
      )}
    </>
  );
};

Tags.propTypes = {
  isLoading: PropTypes.bool,
  tagList: PropTypes.array,
  onSelectTags: PropTypes.func,
};

export default Tags;