import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";
import { toast } from "react-toastify";

// Components
import { Button } from "components/shared/button/Button";
import TagModal from "components/tag-management/tag-modal/TagModal";
import MenuSelectTag from "../menu-select-tag/MenuSelectTag";

// Constants and Helpers
import {
  COMMON_TEXT,
  EXPORT_TAG,
} from "constants/Common";
import { TAG_ACTION } from "constants/Constants";

// Services
import { applyAttachmentTag, applyAttachmentUntag } from "services";

// Styles
import styles from "./TagSingleButton.module.scss";

const TagSingleButton = (props) => {
  const {
    matterId,
    attachmentId,
    disabled = false,
  } = props;

  const [showTagMenu, setShowTagMenu] = useState(false);
  const [tagSelected, setTagSelected] = useState([]);
  const [showAddTagTab, setShowAddTagTab] = useState(false);

  const { tags, isExecutingTag } = useSelector((state) => state.tag);

  const selectTags = tags.selectTags.filter(
    (tag) => tag.tagID !== EXPORT_TAG.id
  );

  const handleSelectTag = (event, tagID) => {
    const { checked } = event.currentTarget;
    setTagSelected((prev) =>
      checked ? [...prev, tagID] : prev.filter((val) => val !== tagID)
    );
  };

  const handleSaveSelectTags = async (selectedTags, action) => {
    const isApply = action === TAG_ACTION.tag;
    const toastId = toast.loading(`${isApply ? "Applying" : "Removing"} tag...`);

    try {
      const body = { tagIds: selectedTags };
      if (isApply) {
        await applyAttachmentTag(matterId, attachmentId, body);
      } else {
        await applyAttachmentUntag(matterId, attachmentId, body);
      }
      toast.update(toastId, {
        render: `${isApply ? "Applied" : "Removed"} tag successfully!`,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        closeOnClick: true,
        isLoading: false
      });
    } catch (error) {
      console.log(error);
      toast.update(toastId, {
        render: `Failed to ${isApply ? "apply" : "remove"} tag!`,
        type: toast.TYPE.ERROR,
        closeOnClick: true,
        isLoading: false
      })
    }
  };

  useEffect(() => {
    setTagSelected([]);
  }, [showTagMenu]);

  return (
    <div className={clsx(styles["tag-execute-single"])}>
      {!disabled && (
        <>
          <Dropdown
            className="dropup"
            onToggle={() => setShowTagMenu(!showTagMenu)}
            show={showTagMenu}
          >
            <Dropdown.Toggle
              type="button"
              className={clsx(
                "mar_r16",
                styles["btn"],
                styles["btn-select-tag"],
                styles["active"]
              )}
              id="dropdown-basic2"
              disabled={false}
            >
              Tag
            </Dropdown.Toggle>
            {showTagMenu && (
              <Dropdown.Menu
                className={clsx(
                  styles["dropdown-menu"],
                  styles["dropdown-menu-tag"]
                )}
              >
                {showAddTagTab ? (
                  <TagModal
                    handleClose={() => setShowAddTagTab(false)}
                    isShow={showAddTagTab}
                    type={COMMON_TEXT.add}
                  />
                ) : (
                  <>
                    <div className={styles["menu-header"]}>
                      <p className={styles["label-title"]}>Select Tags</p>
                      <span
                        className={clsx(
                          styles["add-hot-tag"],
                          isExecutingTag ? styles["disabled"] : ""
                        )}
                        onClick={() => setShowAddTagTab(true)}
                      >
                        Add New Tag
                      </span>
                    </div>
                    <MenuSelectTag
                      tagList={selectTags}
                      checkedList={tagSelected}
                      onChange={handleSelectTag}
                      disabled={isExecutingTag}
                    />
                    <div
                      className={clsx(styles["dropdown-menu-action"])}
                    >
                      <Dropdown.Item className={styles["dropdown-item"]}>
                        <Button
                          name="Cancel"
                          handleClick={() => setTagSelected([])}
                          className="btn-secondary-md"
                        />
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles["dropdown-item"]}
                        disabled={!tagSelected?.length}
                      >
                        <Button
                          handleClick={() =>
                            handleSaveSelectTags(tagSelected, TAG_ACTION.unTag)
                          }
                          isDisabled={!tagSelected?.length || isExecutingTag}
                          className="btn-secondary-md"
                          isBorder={false}
                          name="Untag"
                        />
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles["dropdown-item"]}
                        disabled={!tagSelected?.length}
                      >
                        <Button
                          handleClick={() =>
                            handleSaveSelectTags(tagSelected, TAG_ACTION.tag)
                          }
                          isDisabled={!tagSelected?.length || isExecutingTag}
                          className="btn-secondary-md"
                          isBorder={false}
                          name="Tag"
                        />
                      </Dropdown.Item>
                    </div>
                  </>
                )}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </>
      )}
    </div>
  );
};

TagSingleButton.propTypes = {
  matterId: PropTypes.number.isRequired,
  attachmentId: PropTypes.string.isRequired,
  onLoading: PropTypes.func,
  onFinish: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TagSingleButton;
