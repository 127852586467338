import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

// Helpers & Constants
import { formatBytes } from "helpers/FormatterHelper";
import { STATUS_IMPORT_BLOB } from "constants/Common";

// Styles
import styles from "./ImportProcessItem.module.scss";

const ImportProcessItem = (props) => {
  const {
    folderName = "",
    totalBytes = [],
    onDeleteImport,
    status = STATUS_IMPORT_BLOB.ready,
    handleImportBlob,
    isDisable = false,
    bytesImported = 0,
    percent = 0,
    isFailed = false,
    loading = false,
    listImport = [],
  } = props;

  const readyAndInprogressImports = listImport.filter(
    (item) =>
      item.status === STATUS_IMPORT_BLOB.inprogress ||
      item.status === STATUS_IMPORT_BLOB.ready
  ).length;

  const renderImg = (status) => {
    if (status === STATUS_IMPORT_BLOB.pause)
      return isDisable && readyAndInprogressImports > 0
        ? "/images/play-audio-gray-icon.svg"
        : "/images/play-audio-icon.svg";

    if (status === STATUS_IMPORT_BLOB.ready) {
      return isDisable
        ? "/images/play-audio-gray-icon.svg"
        : "/images/play-audio-icon.svg";
    }
    return isDisable
      ? "/images/pause-audio-icon-gray.svg"
      : "/images/icons/pause-icon.svg";
  };

  return (
    <div className={styles["wrap"]}>
      <div className={styles["processing-container"]}>
        <p className={styles["import-name"]}>{folderName}</p>
        <div className={styles["progress-container"]}>
          <div className={styles["progress-info"]}>
            <span>{`${formatBytes(bytesImported)} / ${formatBytes(
              totalBytes
            )}`}</span>
            <span>{percent.toFixed(0)}%</span>
          </div>
          <progress id="file" value={percent} max="100"></progress>
        </div>
      </div>
      {loading ? (
        <Spinner variant="primary" animation="border" />
      ) : (
        <div className={styles["processing-control"]}>
          {isFailed ? (
            <img src="/images/warning-icon.svg" alt="Import failed" />
          ) : (
            <img
              src={renderImg(status)}
              alt="play-audio-icon"
              onClick={handleImportBlob}
              className={
                isDisable && readyAndInprogressImports > 0
                  ? styles["disable"]
                  : ""
              }
            />
          )}

          <img
            src="/images/icons/trash-icon.svg"
            alt="pause-audio-icon"
            onClick={onDeleteImport}
          />
        </div>
      )}
    </div>
  );
};

ImportProcessItem.propTypes = {
  folderName: PropTypes.string,
  totalBytes: PropTypes.number,
  status: PropTypes.number,
  isDisable: PropTypes.bool,
  isFailed: PropTypes.bool,
  loading: PropTypes.bool,
  percent: PropTypes.number,
  listImport: PropTypes.array,
  bytesImported: PropTypes.number,
  onDeleteImport: PropTypes.func,
  handleImportBlob: PropTypes.func,
};

export default ImportProcessItem;
