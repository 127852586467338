import axios from "axios";
import { authHeader } from "./ApiConfig";

const downloadAttachmentHtml = async (projectId, attachmentId) => {
    return await axios({
        method: 'get',
        baseURL: `${process.env.REACT_APP_API_URL}/attachment/${projectId}/downloadHtml/${attachmentId}`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
        responseType: 'blob'
      });
};

const downloadNativePreview = async (projectId, attachmentId) => {
    return await axios({
        method: 'get',
        baseURL: `${process.env.REACT_APP_API_URL}/attachment/${projectId}/downloadNativePreview/${attachmentId}`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
        responseType: 'blob'
      });
};

const streamNativeMedia = async (projectId, attachmentId) => {
    return await axios({
        method: 'get',
        baseURL: `${process.env.REACT_APP_API_URL}/attachment/${projectId}/streamMedia/${attachmentId}`,
        timeout: 180000,
        headers: {
          ...authHeader(),
        },
        responseType: 'blob'
      });
    };

const downloadNativeFile = async (projectId, attachmentId) => {
  return await axios({
      method: 'get',
      baseURL: `${process.env.REACT_APP_API_URL}/attachment/${projectId}/downloadNativeFile/${attachmentId}`,
      timeout: 60000,
      headers: {
        ...authHeader(),
      },
      responseType: 'blob'
    });
};

const getSpreadsheetJson = async (projectId, attachmentId) => {
    return await axios({
        method: 'get',
        baseURL: `${process.env.REACT_APP_API_URL}/attachment/${projectId}/spreadsheetViewerJson/${attachmentId}`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
      });
};

const getDocumentJson = async (projectId, attachmentId) => {
    return await axios({
        method: 'get',
        baseURL: `${process.env.REACT_APP_API_URL}/attachment/${projectId}/documentViewerJson/${attachmentId}`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
      });
};

const convertPresentationToPdf = async (projectId, attachmentId) => {
    return await axios({
        method: 'post',
        baseURL: `${process.env.REACT_APP_API_URL}/attachment/${projectId}/convertPresentationToPdf/${attachmentId}`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
        responseType: 'blob'
      });
};

const downloadImageAttachment = async (projectId, attachmentId) => {
    return await axios({
        method: 'get',
        baseURL: `${process.env.REACT_APP_API_URL}/attachment/${projectId}/downloadImage/${attachmentId}`,
        timeout: 60000,
        headers: {
          ...authHeader(),
        },
        responseType: 'blob'
      });
}

export {
    downloadAttachmentHtml,
    downloadNativePreview,
    downloadNativeFile,
    streamNativeMedia,
    getSpreadsheetJson,
    getDocumentJson,
    convertPresentationToPdf,
    downloadImageAttachment
}
