import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import BenzAMRRecorder from "benz-amr-recorder";

// Components
import AmrConvertAudio from "components/shared/amr-convert-audio/AmrConvertAudio";
import MediaConvert from "components/shared/media-convert/MediaConvert";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";

//Constants
import { DATA_TYPE, FILE_TYPE } from "constants/DataType";
import { LIST_AUDIO_TYPE } from "constants/Constants";

// Services
import { streamNativeMedia } from "services";

// Store
import { setDuration } from "store/MediaControlReducer";

// Styles
import styles from "./PreviewAudio.module.scss";

const PreviewAudio = (props) => {
  const { matterId, attachmentId } = props;

  const dispatch = useDispatch();

  const [url, setUrl] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);
  const [contentType, setContentType] = useState("");
  const [hasFailed, setHasFailed] = useState(false);

  const amr = new BenzAMRRecorder();

  useEffect(() => {
    if (!hasLoaded && attachmentId) {
      const media = new Audio();

      streamNativeMedia(matterId, attachmentId).then(async (response) => {
        if (response) {
          media.src = URL.createObjectURL(new Blob([response.data]));
          setContentType(response.headers["content-type"]);
          setUrl(media.src);
        }
      }).catch((response) => {
        setHasFailed(true);
      }).finally(() => {
        setHasLoaded(true);
      })

      return () => {
        URL.revokeObjectURL(media.src);
        dispatch(setDuration(""));
      }
    }
  }, [attachmentId]);

  useEffect(() => {
    const initAmrAudio = async () => {
      try {
        url && amr?.initWithUrl(url);
      } catch (error) {
        setHasFailed(true);
      }
    };
    
    initAmrAudio();

    return () => {
      if (amr?.isInit()) {
        amr.stop();
      }
    };
  }, [amr]);

  const renderAudioNormal = () => (
    <audio controls controlsList="nodownload" src={url} />
  );

  const renderTypeAudio = () => {
    if (contentType?.includes(FILE_TYPE.audioAmr) && hasLoaded) {
      return <AmrConvertAudio src={url} amr={amr} />;
    }
    if (url?.includes(LIST_AUDIO_TYPE.caf) && hasLoaded) {
      return <MediaConvert url={url} type={DATA_TYPE.audio} />;
    }
    return renderAudioNormal();
  };

  return (
    <div>
      {hasLoaded ? (
        hasFailed ? (
          <div className={styles["failed"]}>Preview failed to load</div>
        ) : (<div className={styles["audio-wrap"]}>{renderTypeAudio()}</div>)
      ) : (
        <SpinnerLoading />
      )}
    </div>
  );
};

PreviewAudio.propTypes = {
  matterId: PropTypes.string,
  attachmentId: PropTypes.string,
  isVoiceMail: PropTypes.bool,
};

export default PreviewAudio;
