import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { setCanExecuteHotKey } from "store/TagReducer";

// Constants
import { REGEX_ONLY_NUMERIC } from "constants/RegexConstant";

// Styles
import "./styles.scss";

export const PaginationResult = (props) => {
  const {
    perPage,
    handlePagingClick,
    totalRecord,
    pageItems,
    forcePage = 0,
    isSmall,
    isUpdateCurrentPage = false,
    nameRecord = "",
    selectedLabel = "",
  } = props;

  const dispatch = useDispatch();
  const [pageCountContact, setPageCountContact] = useState(perPage);
  const [currentNumber, setCurrentNumber] = useState(forcePage + 1);

  const goToPage = (event) => {
    event.preventDefault();
    dispatch(setCanExecuteHotKey(true));
    if (
      currentNumber &&
      Number(currentNumber) <= pageCountContact &&
      Number(currentNumber) > 0
    )
      handlePagingClick({ selected: Number(currentNumber - 1) });
  };

  const onChangeInput = (e) => {
    if (REGEX_ONLY_NUMERIC.test(e.target.value))
      setCurrentNumber(e.target.value);
  };

  useEffect(() => {
    setPageCountContact(Math.ceil(totalRecord / perPage));
  }, [totalRecord]);

  useEffect(() => {
    setCurrentNumber(forcePage + 1);
  }, [forcePage, isUpdateCurrentPage]);

  return (
    <div className="paging_control">
      <p className="paging_control-showing">
        Showing {pageItems} of {totalRecord}{" "}
        {nameRecord || `item${totalRecord > 1 ? "s" : ""}`}
        {selectedLabel && ` (${selectedLabel})`}
      </p>
      <div className="paging_control-pagination">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCountContact}
          onPageChange={handlePagingClick}
          containerClassName={`pagination ${isSmall && "pagination-small"}`}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          disabledClassName={"disabled"}
          forcePage={forcePage}
        />
        <div className="search-paging">
          <span>Go to page</span>
          <form onSubmit={goToPage}>
            <input
              onChange={onChangeInput}
              value={currentNumber}
              maxLength={5}
              onFocus={() => dispatch(setCanExecuteHotKey(false))}
              onBlur={() => dispatch(setCanExecuteHotKey(true))}
            />
          </form>
          <img
            src="/images/search-icon.svg"
            alt="Search Icon"
            onClick={goToPage}
          />
        </div>
      </div>
    </div>
  );
};

PaginationResult.propTypes = {
  perPage: PropTypes.number,
  totalRecord: PropTypes.number,
  handlePagingClick: PropTypes.func,
  pageItems: PropTypes.number,
  forcePage: PropTypes.number,
  isSmall: PropTypes.bool,
  isUpdateCurrentPage: PropTypes.bool,
  nameRecord: PropTypes.string,
  selectedLabel: PropTypes.string,
};
