import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { registerLicense } from '@syncfusion/ej2-base';

// Stores
import {
  setCurrentImportId,
  setIsCancelImport,
  setIsSuccess,
  updateListImportItem,
  setImportLoading,
  removeListImportItemById
} from "store/ImportBlobReducer";

// Components
import LoginAuth0 from "pages/auth/LoginAuth0";
import AuthLanding from "pages/dashboard/AuthLanding";
import ToastCustom from "components/toast/ToastCustom";
import PrivateRoute from "./routes/PrivateRoute";

// Services
import { updateImportInfo } from "services/ImportManagementService";

// Constants
import {
  STATUS_IMPORT_BLOB,
  TOAST_MESSAGE_IMPORT
} from "constants/Common";
import { IMPORT_BLOB_ACTION } from "constants/DataSourceConstant";

// Helpers
import { uploadFileToBlob } from "helpers/ImportUploadHelpers";
import { clampedAll } from "helpers/ImportPromiseAllHelper";
import {
  findIdCanImport,
  getPercent,
  getTotalSizeInFiles,
  handleTotalFileImport,
} from "helpers/DataSourceHelper";

// Styles
import "../src/assets/css/common.scss";
import "../src/assets/css/_base.scss";
import "../src/assets/css/normalize.scss";

const App = () => {
  const dispatch = useDispatch();

  registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

  const { currentImportId, listImport, action } = useSelector(
    (state) => state.importBlob
  );

  const updateImportById = (importId, data = {}, otherImportData = {}) => {
    dispatch(
      updateListImportItem({ id: importId, ...data })
    );
    if (otherImportData) {
      const otherImports = listImport.filter((item) => item.id !== importId);
      otherImports.forEach((item) => {
        dispatch(
          updateListImportItem({ id: item.id, ...otherImportData })
        );
      });
    }
  };

  // Import data to Blob
  const executeImport = (data) => {
    updateImportById(
      data.id,
      { status: STATUS_IMPORT_BLOB.inprogress, isDisable: false },
      { isDisable: true }
    );
    dispatch(setImportLoading(true));
    setTimeout(() => {
      const { files, folderName, projectId, dataSourceID } = data;
      dispatch(setIsCancelImport(false));
      onUploadFolder({ files, folderName, projectId, dataSourceID });
    }, 1000);
  };

  const findImportById = (importId) =>
    listImport.find((item) => item.id === importId);

  const getBytesImporting = () => {
    return action !== IMPORT_BLOB_ACTION.resume ||
      action === IMPORT_BLOB_ACTION.pause
      ? []
      : findImportById(currentImportId)?.listByte;
  };

  const onUploadFolder = async (data) => {
    dispatch(setIsSuccess(false));
    const { files = [], folderName = "", projectId, dataSourceID } = data;
    if (files.length === 0) return;
    try {
      const cloneListFile = [...files];
      const MAX_PARALLEL_REQUEST = 10;
      const apiCalls = cloneListFile.map(
        (file) => () => uploadFileToBlob({
          matterId: projectId,
          dataSourceId: dataSourceID,
          folderName,
          file,
        }));
      dispatch(setImportLoading(false));
      const dataResult = await clampedAll({
        array: apiCalls,
        clamp: MAX_PARALLEL_REQUEST,
        setListByte: updateImportProgress,
        listBytes: getBytesImporting(),
        importId: currentImportId,
      });
      const { blobBytes, blobCount = 0 } = handleTotalFileImport(dataResult);
      if (blobBytes === getTotalSizeInFiles(cloneListFile)) {
        const dataUpdate = { projectId, dataSourceID, blobCount, blobBytes };
        await updateImportInfo(dataUpdate);
        toast.success(TOAST_MESSAGE_IMPORT.success);
        dispatch(setIsSuccess(true));
      }
    } catch (error) {
      updateImportById(currentImportId, { isFailed: true });
      console.log(error);
      toast.error(TOAST_MESSAGE_IMPORT.failed);
    }
  };

  const updateImportProgress = (updatedBytes, importId) => {
    if (importId !== currentImportId || updatedBytes?.length === 0) return;

    const currentImport = findImportById(importId);
    let percent = getPercent(importId, updatedBytes, listImport);
    if (
      percent === 100 ||
      (listImport.length > 0 && currentImport?.files.length === updatedBytes.length)
    ) {
      deleteImportDone();
    } else  {
      updateImportById(importId, { 
        percent, 
        listByte: updatedBytes,
      });
    }
  };

  const deleteImportDone = () => {
    const imports = listImport.filter((item) => item.id !== currentImportId);
    dispatch(removeListImportItemById(currentImportId));
    dispatch(setCurrentImportId(findIdCanImport(imports)));
  };

  const getFilesImporting = (totalFiles = [], importedFiles = []) => {
    return totalFiles.filter(
      (total) =>
        !importedFiles.find(
          (item) => item.name === total.name && item.size === total.size
        )
    );
  };

  useEffect(() => {
    if (currentImportId) {
      const currentImport = findImportById(currentImportId);
      const { files = [], listByte = [] } = currentImport;
      executeImport({
        ...currentImport,
        files: getFilesImporting(files, listByte),
      });
    }
  }, [currentImportId]);

  return (
    <div id="container" className="app-container">
      <ToastCustom />
      <Routes>
        <Route path="callback" element={<AuthLanding />} />
        <Route path="/login" element={<LoginAuth0 />} />
        <Route path="*" element={<PrivateRoute />} />
      </Routes>
    </div>
  );
};

export default App;
