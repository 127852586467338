import React from "react";
import PropTypes from "prop-types";

// Components
import LooseFileInfoContainer from "../loose-file-info-container/LooseFileInfoContainer";

// Constants
import { PARAMETERS } from "constants/Common";
import { TIMELINE_INFORMATION } from "constants/DataType";

const InfoFile = ({ fileInfo }) => {
  return (
    <LooseFileInfoContainer
      infoResultLabelList={TIMELINE_INFORMATION.file}
      dataType={fileInfo[PARAMETERS.type]}
      itemInfo={fileInfo}
    />
  )
};

InfoFile.propTypes = {
  fileInfo: PropTypes.object,
};

export default InfoFile;
