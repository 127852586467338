import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useToken } from "hook/auth";

// Store
import {
  paramSearchDataSourceInit,
  resetStoreDataSource,
  setDataSourceLoading,
  setEditLoading,
  setParamSearchDataSource,
  setSourceData,
  setSourceDetail,
  sourceDetailInit,
} from "store/DataSourceReducer";
import { resetDateTimeMess } from "store/DatePickerReducer";
import { setIsSuccess } from "store/ImportBlobReducer";

// Services
import {
  exportDataSourceToExcel,
  getDataSourceApi,
  createSourceApi,
  deleteSourceApi,
  updateSourceApi,
} from "services/DataSourceService";
import {
  blobImportApi,
  cancelImportApi,
  collectionTypeApi,
  getLogImportApi,
} from "services/ImportManagementService";
import { handleAbortRequest } from "services/ApiConfig";

// Components
import PrintExport from "components/shared/print-export/PrintExport";
import { Button } from "components/shared/button/Button";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import DataSourceTable from "components/data-source/data-source-table/DataSourceTable";
import DataSourceCard from "components/data-source/data-source-card/DataSourceCard";
import CreateSourceModal from "components/data-source/modal/create-data-source/CreateSourceModal";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";
import AddSourceDataModal from "components/data-source/modal/add-source-data/AddSourceDataModal";
import ImportProcessManagement from "components/import-management/import-process/ImportProcessManagement";
import ViewLog from "components/import-management/view-log/ViewLog";
import { PopupNotice } from "components/shared/popup/popup-notice/PopupNotice";
import MatterDate from "components/shared/matter-date/MatterDate";

// Constants
import {
  COMMON_TEXT,
  PAGINATION,
  PATH_NAME,
  PRINT_SCREEN_NAME,
} from "constants/Common";
import { breadCrumbDataSource } from "constants/BreadCrumbConstants";
import {
  IMPORT_PROCESS,
  JOB_CATEGORY,
  POPUP_TYPE,
} from "constants/DataSourceConstant";
import { DATE_TIME_TYPE } from "constants/Constants";
import { STATUS_UPLOAD } from "constants/UploadConstant";
import { DATA_SOURCE_TYPES } from "constants/Constants";

// Helpers
import {
  uploadLoadFile,
} from "helpers/ImportUploadHelpers";
import { trimObject } from "helpers/ObjectHelper";
import {
  convertSecondsToHMS,
  formatDateTime,
} from "helpers/DateTimeFormatterHelper";

// Styles
import styles from "./DataSourcePage.module.scss";

const DataSourcePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { isUser, isAdmin } = useToken();
  const inputRef = useRef(null);
  const searchInputRef = useRef(null);

  const [sourceTypeList, setSourceTypeList] = useState([]);
  const [showCreateSourceModal, setShowCreateSourceModal] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showAddSourceDataModal, setShowAddSourceDataModal] = useState(false);
  const [isFirstLoadComponent, setIsFirstLoadComponent] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [viewLogs, setViewLogs] = useState([{ content: "", isFailed: false }]);
  const [loadingLog, setLoadingLog] = useState(false);
  const dataSourceFilterInit = [
    {
      key: "jobCategories",
      label: "Job Category",
      options: Object.values(JOB_CATEGORY).map((item) => ({
        ...item,
        value: item.value.toString(),
      })),
      checkedList: [],
    },
    {
      key: "importProcesses",
      label: "Status",
      options: Object.values(IMPORT_PROCESS).map((item) => ({
        ...item,
        value: item.value.toString(),
      })),
      checkedList: [],
    },
  ];
  const [dataSourceFilter, setDataSourceFilter] =
    useState(dataSourceFilterInit);
  const [isEdit, setIsEdit] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [remainingTime, setRemainingTime] = useState("");

  // Get data store
  const {
    paramSearchDataSource,
    sourceData,
    sourceData: { sourceList, totalRecords },
    sourceDetail,
    loading,
    importLog,
    editLoading,
  } = useSelector((state) => state.dataSource.dataSourceManagement);
  const projectList = useSelector((state) => state.projects.projects) || [];

  const userID = useSelector((state) => state.user.userInfo.id);
  const isImportSuccess = useSelector((state) => state.importBlob.isSuccess);

  const goBack = () => navigate(`/${PATH_NAME.matters}`);

  const getSourceDetail = (data) => {
    return { ...data, sourceId: data?.sourceid };
  };

  const getFilterParamSearch = (paramSearch) => {
    const newParamSearch = {
      ...paramSearch,
      jobCategories: paramSearch.jobCategories.map((value) =>
        getJobCategoryLabel(value)
      ),
      importProcesses: paramSearch.importProcesses.map((value) =>
        Number(value)
      ),
    };
    return newParamSearch;
  };

  const resetSourceData = () => {
    dispatch(
      setSourceData({
        sourceList: [],
        totalRecords: 0,
        suggestions: [],
      })
    );
    dispatch(setSourceDetail(sourceDetailInit));
  };

  const checkIsReloadDataSourceList = () => {
    const { jobCategories, importProcesses, search } = paramSearchDataSource;
    if (jobCategories.length > 0 || importProcesses.length > 0 || search) {
      fetchDataSourceList({
        paramSearch: paramSearchDataSource,
      });
    } else {
      fetchDataSourceList({
        paramSearch: paramSearchDataSource,
        isReload: false,
        dataSourceId: sourceDetail.dataSourceId,
      });
    }
  };

  const fetchDataSourceList = async ({
    paramSearch,
    isReload = true,
    dataSourceId,
  }) => {
    try {
      isReload && dispatch(setDataSourceLoading(true));
      const result = await getDataSourceApi(
        projectId,
        getFilterParamSearch(paramSearch)
      );
      const { items, totalRecords, selectName } = result.data;

      // handle remove the last 1 record of table
      const { pageSize, pageNumber } = paramSearchDataSource.paginationParams;
      if (
        pageNumber > 1 &&
        totalRecords === 0 &&
        sourceData.totalRecords === pageSize * (pageNumber - 1) + 1
      ) {
        dispatch(
          setParamSearchDataSource({
            ...paramSearchDataSource,
            paginationParams: {
              ...paramSearchDataSource.paginationParams,
              pageNumber: pageNumber - 1,
            },
          })
        );
        return;
      }

      // normal case
      if (isReload) {
        dispatch(
          setSourceData({
            sourceList: items.map((source) => getSourceDetail(source)),
            suggestions: selectName,
            totalRecords,
          })
        );
        dispatch(
          setSourceDetail(
            totalRecords > 0 ? getSourceDetail(items[0]) : sourceDetailInit
          )
        );
      } else {
        const newSourceDetail = items.find(
          (item) => item.dataSourceId === dataSourceId
        );
        dispatch(
          setSourceData({
            sourceList: items.map((source) =>
              source.dataSourceId === dataSourceId
                ? getSourceDetail(newSourceDetail)
                : getSourceDetail(source)
            ),
          })
        );
        dispatch(setSourceDetail(getSourceDetail(newSourceDetail)));
      }
    } catch (error) {
      console.log(error);
      resetSourceData();
    } finally {
      dispatch(setDataSourceLoading(false));
    }
  };

  const fetchSourceTypeDevice = async () => {
    try {
      const { data } = await collectionTypeApi();
      if (!data) return;
      const sourceTypes = data.map((item) => ({
        label: item.name,
        value: item.dataSourceTypeId,
      }));
      setSourceTypeList(sourceTypes);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateDataSource = async (data) => {
    handleAbortRequest();
    dispatch(setEditLoading(true));
    try {
      const trimData = trimObject(data);
      await updateSourceApi(projectId, trimData);
      checkIsReloadDataSourceList();
      toast.success("Update source is successfully!");
      setIsEdit(false);
    } catch (error) {
      console.log(error);
      toast.error("Update source is failed!");
    } finally {
      setShowCreateSourceModal(false);
      dispatch(setEditLoading(false));
    }
  };

  const handleCreateDataSource = async (data) => {
    handleAbortRequest();
    dispatch(setDataSourceLoading(true));
    try {
      const trimData = trimObject(data);
      await createSourceApi(projectId, trimData);
      dispatch(
        setParamSearchDataSource({
          ...paramSearchDataSource,
          paginationParams: {
            ...paramSearchDataSource.paginationParams,
            pageNumber: PAGINATION.pageNumberDefault,
          },
        })
      );
      toast.success("Date source creation is successful!");
      setShowCreateSourceModal(false);
    } catch (error) {
      console.log(error);
      toast.error("Data source creation has failed!");
      dispatch(setDataSourceLoading(false));
    }
  };

  const onCreateOrEditSource = (data) => {
    if (isEdit)
      handleUpdateDataSource({
        ...data,
        dataSourceID: sourceDetail.dataSourceId,
      });
    else handleCreateDataSource(data);
  };

  const handleSwitchRecord = (sourceId) =>
    dispatch(
      setSourceDetail(sourceList.find((item) => item.sourceId === sourceId))
    );

  const handleSortTable = (sortParam) =>
    dispatch(
      setParamSearchDataSource({
        ...paramSearchDataSource,
        paginationParams: {
          ...paramSearchDataSource.paginationParams,
          columnSort: sortParam.columnSort,
          orderBy: sortParam.orderBy,
        },
      })
    );

  const handlePagingTable = (event) =>
    dispatch(
      setParamSearchDataSource({
        ...paramSearchDataSource,
        paginationParams: {
          ...paramSearchDataSource.paginationParams,
          pageNumber: event.selected + 1,
        },
      })
    );

  const handleSubmitSearch = () =>
    dispatch(
      setParamSearchDataSource({
        search: inputRef.current.value?.trim() || "",
        paginationParams: {
          ...paramSearchDataSource.paginationParams,
          pageNumber: PAGINATION.pageNumberDefault,
        },
      })
    );

  const handleExportToExcel = async () => {
    setExportLoading(true);
    try {
      const result = await exportDataSourceToExcel(
        projectId,
        getFilterParamSearch(paramSearchDataSource)
      );
      const url = window.URL.createObjectURL(result.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "DataSources.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Export data source to excel failed!");
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  const handleDeleteDataSource = async () => {
    setShowConfirmPopup(false);
    dispatch(setDataSourceLoading(true));
    try {
      await deleteSourceApi(projectId, sourceDetail.dataSourceId);
      let params = { ...paramSearchDataSource };
      const pagination = paramSearchDataSource.paginationParams;
      if (
        sourceList.length % pagination.pageSize === 1 &&
        pagination.pageNumber !== 1
      ) {
        params = {
          ...paramSearchDataSource,
          paginationParams: {
            ...pagination,
            pageNumber: pagination.pageNumber - 1,
          },
        };
      }
      dispatch(setParamSearchDataSource(params));
    } catch (error) {
      console.log(error);
      dispatch(setDataSourceLoading(false));
    }
  };

  const getJobCategoryLabel = (value) =>
    Object.values(JOB_CATEGORY).find((item) => item.value === Number(value))
      ?.label;

  const handleSaveFilter = (data) => {
    const params = {
      ...paramSearchDataSource,
      paginationParams: {
        ...paramSearchDataSource.paginationParams,
        pageNumber: PAGINATION.pageNumberDefault,
      },
      jobCategories: data.jobCategories,
      importProcesses: data.importProcesses,
    };
    dispatch(setParamSearchDataSource(params));
  };

  const startLoadfileImport = async (importType) => {
    try {
      const params = {
        projectId: parseInt(projectId),
        dataSourceID: sourceDetail.dataSourceId,
        importType,
        userID,
      };
      await blobImportApi(params);
      setShowAddSourceDataModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setPopupType("");
    }
  };

  const handleAddSourceData = async (data) => {
    setShowAddSourceDataModal(false);
    setPopupType(POPUP_TYPE.import);
    setRemainingTime("");
    try {
      await uploadLoadFile({
        matterId: projectId,
        dataSourceId: sourceDetail.dataSourceId,
        file: data.fileUpload,
        setRemainingTime,
      });
      startLoadfileImport(data.sourceTypeId);
    } catch (error) {
      console.log(error);
    }
  };

  // Get Status Source
  const getStatusSource = (status) => {
    const data = Object.values(IMPORT_PROCESS).find(
      (item) => item.value === status
    );
    return {
      name: data?.label,
      borderColor: data?.borderColor,
      backgroundColor: data?.backgroundColor,
    };
  };

  const handleStatusWhenImport = () => {
    const { statusLog, dataSourceId, importProcess } = importLog;
    const hasDataSourceId = sourceList.some(
      (item) => item.dataSourceId === dataSourceId
    );
    // fetch new list data source when import completed
    if (STATUS_UPLOAD.success.Status === statusLog && hasDataSourceId) {
      checkIsReloadDataSourceList();
      return;
    }

    // update status import process when import
    if (STATUS_UPLOAD.process.Status === statusLog && hasDataSourceId) {
      dispatch(
        setSourceData({
          sourceList: sourceList.map((item) =>
            getSourceDetail({
              ...item,
              importProcess:
                item.dataSourceId === dataSourceId
                  ? importProcess
                  : item.importProcess,
            })
          ),
        })
      );
      dispatch(
        setSourceDetail(
          getSourceDetail({
            ...sourceDetail,
            importProcess:
              sourceDetail.dataSourceId === dataSourceId
                ? importProcess
                : sourceDetail.importProcess,
          })
        )
      );
    }
  };

  const handleCancelImport = async () => {
    setShowConfirmPopup(false);
    try {
      const params = {
        dataSourceId: sourceDetail.dataSourceId,
        userId: userID,
      };
      await cancelImportApi(projectId, params);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetViewLogs = () => {
    if (importLog.statusLog === STATUS_UPLOAD.process.Status) return;
    if (sourceDetail.dataSourceId === importLog.dataSourceId) {
      const messageLog = importLog.messageLog
        ? importLog.messageLog
        : null;
      const getTimeUtc = formatDateTime({
        dateTime: messageLog?.TimeStamp,
        type: DATE_TIME_TYPE.YYYY_MM_DD_hhmmssA,
      });
      setViewLogs([
        ...viewLogs,
        {
          content: `[${getTimeUtc}] ${messageLog?.Message}`,
          isFailed:
            STATUS_UPLOAD.failed.Status === importLog.statusLog ? true : false,
        },
      ]);
    }
  };

  const getMessageViewLog = async () => {
    setShowLogs(true);
    setLoadingLog(true);
    try {
      const result = await getLogImportApi(sourceDetail.dataSourceId);
      setViewLogs(
        result.data.map((item) => ({
          content: `[${formatDateTime({
            dateTime: item.timeStamp,
            type: DATE_TIME_TYPE.YYYY_MM_DD_hhmmssA,
          })}] ${item.description}`,
          isFailed: STATUS_UPLOAD.failed.Status === item.title ? true : false,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingLog(false);
    }
  };

  const checkRoleCancel = (importedBy, importProcess) => {
    if (isAdmin()) return false;
    return (
      isUser() ||
      (![importLog.userId, importedBy].includes(userID) &&
        [IMPORT_PROCESS.inprogress.value, IMPORT_PROCESS.ready.value].includes(
          importProcess
        ))
    );
  };

  const infoPopup = [
    {
      value: 1,
      content: `Are you sure you want to Delete the Source "${sourceDetail.custodianName}"?`,
      textConfirm: COMMON_TEXT.delete,
      textReject: COMMON_TEXT.cancel,
      type: "remove",
      handleSubmit: handleDeleteDataSource,
    },
    {
      value: 2,
      content: "Are you sure you want to stop the import process?",
      textConfirm: COMMON_TEXT.yes,
      textReject: COMMON_TEXT.no,
      type: "confirm",
      handleSubmit: handleCancelImport,
    },
  ];

  // Get popup's information (title, content, btnName, ..)
  const getInfoPopUp = () => infoPopup.find((item) => item.value === popupType);

  const handleCountdown = () =>
    setRemainingTime(remainingTime - 1 >= 0 ? remainingTime - 1 : 0);

  useEffect(() => {
    //handle change status
    handleStatusWhenImport();
    //Handle change content view log
    handleGetViewLogs();
  }, [importLog]);

  useEffect(() => {
    if (isUser()) return;
    fetchSourceTypeDevice();
    dispatch(resetDateTimeMess());
  }, []);

  useEffect(() => {
    const interval = setInterval(handleCountdown, 1000);
    return () => clearInterval(interval);
  }, [remainingTime]);

  useEffect(() => {
    const getDataSourceFilter = dataSourceFilter.map((item) => {
      const value = paramSearchDataSource[item.key];
      return {
        ...item,
        checkedList:
          item.options.length === value.length ? value.concat(item.key) : value,
      };
    });
    setDataSourceFilter(getDataSourceFilter);
    fetchDataSourceList({ paramSearch: paramSearchDataSource });
    setIsFirstLoadComponent(false);
  }, [paramSearchDataSource]);

  useEffect(() => {
    if (isImportSuccess) {
      checkIsReloadDataSourceList();
      dispatch(setIsSuccess(false));
    }
  }, [isImportSuccess]);

  useEffect(() => {
    if (!isFirstLoadComponent) {
      dispatch(resetStoreDataSource());
      fetchDataSourceList({ paramSearch: paramSearchDataSourceInit });
      setDataSourceFilter(dataSourceFilterInit);
      if (searchInputRef.current) searchInputRef.current.clearText();
    }
  }, [projectId]);
  
  return (
    <div className={clsx(styles["data-source-page"], "main")}>
      <BreadCrumb goBack={goBack} breadCrumbData={breadCrumbDataSource} />
      <PopupNotice
        isShow={popupType === POPUP_TYPE.import}
        content={`File is uploading.\nThis might take few minutes to finish`}
        loading={true}
        remainingTime={convertSecondsToHMS(remainingTime)}
      />
      <PopupConfirm
        isShow={showConfirmPopup}
        handleClose={() => setShowConfirmPopup(false)}
        handleSubmit={getInfoPopUp()?.handleSubmit}
        content={getInfoPopUp()?.content}
        textConfirm={getInfoPopUp()?.textConfirm}
        type={getInfoPopUp()?.type}
        textReject={getInfoPopUp()?.textReject}
        handleReject={() => setShowConfirmPopup(false)}
      />
      {showCreateSourceModal && (
        <CreateSourceModal
          show={showCreateSourceModal}
          onClose={() => {
            setShowCreateSourceModal(false);
            setIsEdit(false);
          }}
          loading={editLoading}
          onSubmit={(data) => {
            onCreateOrEditSource({
              ...data,
              collectionDate: data?.collectionDate || null,
            });
          }}
          sourceTypeList={sourceTypeList}
          projectList={projectList}
          isDisabledBtn={loading || editLoading}
          isEdit={isEdit}
          jobCategory={sourceDetail?.jobCategory}
          importProcess={sourceDetail?.importProcess}
          dataDefault={{
            projectId,
            custodianName: sourceDetail?.custodianName || "",
            dataSourceTypeId: DATA_SOURCE_TYPES.find(
              (item) => item.name === sourceDetail?.dataSourceType
            )?.id,
            deviceName: sourceDetail?.dataSourceDescription || "",
            analystName: sourceDetail?.analystName || "",
            identifier: sourceDetail?.identifier || "",
            collectionDate: sourceDetail?.collectionDate
              ? new Date(
                  formatDateTime({ 
                    dateTime: sourceDetail.collectionDate, 
                    type: DATE_TIME_TYPE.MMM_DD_YYYY,
                    addZulu: false 
                  })
                )
              : null
          }}
        />
      )}
      {showAddSourceDataModal && (
        <AddSourceDataModal
          show={showAddSourceDataModal}
          onClose={() => setShowAddSourceDataModal(false)}
          onSubmit={handleAddSourceData}
        />
      )}
      <div className={styles["data-source-grid"]}>
        <div className={styles["source-header-matter-view"]}>
          <h3>Data Sources</h3>
          <div className={styles["source-header-matter-date"]}>
            <MatterDate/>
          </div>
        </div>
        <div className={styles["source-header-control"]}>
          <PrintExport
            screenName={PRINT_SCREEN_NAME.dataSources}
            handleExport={handleExportToExcel}
            isDisplayTime={true}
            exportLoading={exportLoading}
          />
          {!isUser() && (
            <Button
              name="Create Data Source"
              className="btn-primary-fill"
              handleClick={() => setShowCreateSourceModal(true)}
              isDisabled={loading}
            />
          )}
        </div>
        <div className={styles["source-table"]}>
          <DataSourceTable
            handleSwitchRecord={handleSwitchRecord}
            handleSortTable={handleSortTable}
            handlePagingTable={handlePagingTable}
            handleDeleteTable={() => {
              setShowConfirmPopup(true);
              setPopupType(POPUP_TYPE.delete);
            }}
            handleImportData={() => setShowAddSourceDataModal(true)}
            handleSubmitSearch={handleSubmitSearch}
            getStatusSource={getStatusSource}
            inputRef={inputRef}
            searchInputRef={searchInputRef}
            dataSourceFilter={dataSourceFilter}
            handleSaveFilter={handleSaveFilter}
            handleCancelImport={() => {
              setShowConfirmPopup(true);
              setPopupType(POPUP_TYPE.cancel);
            }}
            checkRoleCancel={checkRoleCancel}
          />
        </div>
        <div className={styles["source-card"]}>
          {!isUser() && (
            <ImportProcessManagement
              dataSourceID={sourceDetail.dataSourceId}
              canImport={
                sourceDetail.jobCategory !== JOB_CATEGORY.draft.label &&
                !loading &&
                totalRecords > 0
              }
              deviceName={sourceDetail.dataSourceDescription}
            />
          )}
          <DataSourceCard
            isLoading={loading}
            sourceDetail={sourceDetail}
            getStatusSource={getStatusSource}
            onViewLog={getMessageViewLog}
            totalRecord={totalRecords}
            onEditSource={() => {
              setShowCreateSourceModal(true);
              setIsEdit(true);
            }}
            hiddenBtnViewLog={isUser()}
          />
        </div>
      </div>
      <ViewLog
        loading={loadingLog}
        show={showLogs}
        logs={viewLogs}
        onClose={() => setShowLogs(false)}
        isImporting={
          sourceDetail.importProcess === IMPORT_PROCESS.inprogress.value
        }
      />
    </div>
  );
};

export default DataSourcePage;
