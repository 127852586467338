// Helper
import { clampedAll } from "./ImportPromiseAllHelper";
import store from "store/index";

import {
  uploadNativeFileChunk,
  nativeFileCommit,
  uploadLoadfileBlock,
  loadfileCommit
} from "services/ImportFileService";

let controller = new AbortController();

export const uploadFileToBlob = async (params) => {
  const handleCancelled = () => {
    const state = store.getState();
    if (state.importBlob.isCancelImport) {
      controller.abort();
      controller = new AbortController();
      return true;
    }
    return false;
  };

  const {
    matterId,
    dataSourceId,
    file,
    folderName
  } = params;

  if (!file) {
    return;
  }

  const options = {
    abortSignal: controller.signal,
    blobHTTPHeaders: { blobContentType: file.type },
  };

  const updatedFilePath = file.webkitRelativePath
    ? file.webkitRelativePath.replace(`${folderName}/`, "")
    : file.path.replace(`/${folderName}/`, "");

  try {
    if (handleCancelled()) return;

    const blocks = getFileBlocks(matterId, dataSourceId, updatedFilePath, file);
    const allBlockIds = blocks.map((block) => block.blockId);

    const uploadCalls = blocks.map((block) =>
      () => uploadNativeFileChunk(
        matterId,
        dataSourceId,
        block.filePath,
        block.blockId,
        block.data,
        options));

    await clampedAll({
      array: uploadCalls,
      clamp: 10,
      setListByte: () => { },
      listBytes: [],
    });

    if (handleCancelled()) return;

    await nativeFileCommit(matterId, dataSourceId, updatedFilePath, allBlockIds);

    return {
      name: file.name,
      size: file.size,
    }
  } catch (error) {
    console.log(error);
  }
};

export const getFileBlocks = (matterId, dataSourceId, filePath, file) => {
  const blockSize = 20 * 1024 * 1024; // 20MB
  const blockCount = Math.ceil(file.size / blockSize);
  const blocks = [];
  for (let i = 0; i < blockCount; i++) {
    const start = i * blockSize;
    const end = Math.min(start + blockSize, file.size);
    const blockId = "block-" + i.toString().padStart(13, "0");

    blocks.push({
      matterId,
      dataSourceId,
      blockId,
      filePath,
      name: file.name,
      type: file.type,
      data: file.slice(start, end),
    });
  }

  return blocks;
};

// Handle upload with file size larger
export const uploadLoadFile = async ({
  matterId,
  dataSourceId,
  file,
  setRemainingTime,
}) => {
  const fileSize = file.size;
  const blobName = file.name;
  const blockSize = 20 * 1024 * 1024; // 20MB
  const blockCount = Math.ceil(fileSize / blockSize);
  const startTime = Date.now();

  // calculate remaining time default before get remaining time real-time
  const defaultUploadRate = 2 * 1024 * 1024;
  const remainingTimeDefault = Math.floor(fileSize / defaultUploadRate);
  setRemainingTime(remainingTimeDefault);

  const blockIds = [];
  for (let i = 0; i < blockCount; i++) {
    const start = i * blockSize;
    const end = Math.min(start + blockSize, fileSize);
    const sizeAfterUp =
      (i + 1) * blockSize > fileSize ? fileSize : (i + 1) * blockSize;
    const chunk = file.slice(start, end);
    const blockId = "block-" + i.toString().padStart(6, "0");
    await uploadLoadfileBlock(matterId, dataSourceId, blobName, blockId, chunk);
    blockIds.push(blockId);

    // calculate remaining time real-time when after file size uploaded
    const endTime = Date.now();
    const elapsedTime = (endTime - startTime) / 1000;
    const uploadRate = elapsedTime / sizeAfterUp;
    const remainingTime = Math.floor((fileSize - sizeAfterUp) * uploadRate);
    setRemainingTime(remainingTime);
  }

  try {
    await loadfileCommit(matterId, dataSourceId, blobName, blockIds);
  } catch (error) {
    console.error(error);
  }
};
