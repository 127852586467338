const DATA_TYPE = {
  callLog: "CallLog",
  voicemail: "Voicemail",
  image: "Image",
  video: "Video",
  note: "Note",
  historyLink: "HistoryLink",
  file: "File",
  location: "Location",
  locations: "Locations",
  database: "Database",
  contact: "Contact",
  audio: "Audio",
  network: "Network",
  creditCard: "CreditCard",
  chat: "Chat",
  chatGroup: "ChatGroup",
  email: "Email",
  emailThread: "EmailThread",
  healthApp: "Health App",
  map: "Map",
  calendar: "Calendar",
  weather: "Weather",
  wifi: "Wifi",
  other: "Other",
  phoneNumber: "Phone number",
  iMessage: "iMessage",
  smsMms: "SMS/MMS",
  emailCamelCase: "eMail"
};

const CHAT_EMAIL_TYPE = [DATA_TYPE.chatGroup, DATA_TYPE.chat, DATA_TYPE.emailThread, DATA_TYPE.email];

const DATA_TITLE = {
  callLog: "Call Log Details",
  voiceEmail: "Voice Mail Details",
  image: "Image Details",
  video: "Video Details",
  note: "Note Details",
  historyLink: "Browsing History Details",
  file: "Document Details",
  location: "Location Details",
  database: "Database Details",
  contact: "Contact Details",
  audio: "Audio Details",
  network: "Network Connection Details",
  creditCard: "Financial Information",
  chat: "Chat Details",
  email: "Email Details",
};

/*
  Cannot using camelCase due to not match format data response
*/
const DATA_TYPE_LABEL = {
  calllog: "Call Log",
  voicemail: "Voice Mail",
  image: "Images",
  video: "Videos",
  note: "Notes",
  historylink: "Browsing History",
  file: "Document",
  location: "Location",
  database: "Database",
  contact: "Contact",
  audio: "Audio",
  network: "Network Connection",
  creditcard: "Financial Information",
  chat: "Chat",
  email: "eMail",
  allData: "All Data",
  chatIM: "Chat/IM",
  messagesChat: "Messages/Chats",
  other: "Others",
};

const DATA_TYPE_DISPLAY_PII = [DATA_TYPE.chat, DATA_TYPE.email, DATA_TYPE.note];

const MEDIA_TYPE = {
  messenger: "Messenger",
  facebookMessenger: "Facebook Messenger",
  skype: "Skype",
  linkedin: "Linkedin",
  viber: "Viber",
  instagram: "Instagram",
  weibo: "Weibo",
};

const FILE_TYPE = {
  application: "application",
  audio: "audio",
  video: "video",
  text: "text",
  image: "image",
  vCard: "vcard",
  xVcard: "x-vcard",
  xVlocation: "x-vlocation",
  amr: "amr",
  audioAmr: "audio/amr",
  pdf: "application/pdf",
};

const WORD_MIME_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.syncfusion.document.text",
  "application/vnd.ms-word",
]

const SPREADSHEET_MIME_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  "text/csv",
  "application/vnd.oasis.opendocument.spreadsheet",
  "text/tab-separated-values",
];

const PRESENTATION_MIME_TYPES = [
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.ms-powerpoint.template.macroEnabled.12",
  "application/vnd.ms-powerpoint"
];

const ZIP_MIME_TYPES = [
  "application/x-zip-compressed",
  "application/zip",
  "application/x-7z-compressed",
  "application/x-rar-compressed",
];

const DATA_TYPE_BASE = [
  { key: "docID", label: "Document ID" },
  { key: "typeLabel", label: "Data Type" },
  { key: "source", label: "Source Application" },
  { key: "dataSourceID", label: "Data Source" },
];

const TIMELINE_INFORMATION = {
  callLog: [
    ...DATA_TYPE_BASE,
    { key: "callType", label: "Call Type" },
    { key: "participants", label: "Participants" },
    { key: "dateTime", label: "Date/Time" },
    { key: "direction", label: "Direction" },
    { key: "duration", label: "Duration" },
    { key: "status", label: "Status" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  voicemail: [
    ...DATA_TYPE_BASE,
    { key: "title", label: "File Name" },
    { key: "participants", label: "Participants" },
    { key: "dateTime", label: "Date/Time" },
    { key: "duration", label: "Duration" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  email: [
    ...DATA_TYPE_BASE,
    { key: "emails", label: "eMail Count" },
    { key: "dateFirstEmail", label: "Date First" },
    { key: "dateLastEmail", label: "Date Last" },
    { key: "attachment", label: "Attachment Count" },
  ],
  historyLink: [
    ...DATA_TYPE_BASE,
    { key: "title", label: "Link Name" },
    { key: "lastVisited", label: "Last Visited" },
    { key: "visitCount", label: "Visit Count" },
    { key: "url", label: "URL" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  contact: [
    ...DATA_TYPE_BASE,
    { key: "contactType", label: "Contact Type" },
    { key: "contactName", label: "Contact Name" },
    { key: "createdDate", label: "Created Date" },
    { key: "updatedDate", label: "Updated Date" },
    { key: "profilePicture", label: "Profile Picture" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  chat: [
    ...DATA_TYPE_BASE,
    { key: "totalMessage", label: "Message Count" },
    { key: "dateFirst", label: "Date First" },
    { key: "dateLast", label: "Date Last" },
    { key: "totalAttachment", label: "Attachment Count" },
  ],
  image: [
    ...DATA_TYPE_BASE,
    { key: "contentType", label: "Content Type" },
    { key: "title", label: "File Name" },
    { key: "dateTime", label: "Date/Time" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  video: [
    ...DATA_TYPE_BASE,
    { key: "contentType", label: "Content Type" },
    { key: "title", label: "File Name" },
    { key: "dateTime", label: "Date/Time" },
    { key: "duration", label: "Duration" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  location: [
    ...DATA_TYPE_BASE,
    { key: "name", label: "Location Name" },
    { key: "address", label: "Address" },
    { key: "latitude", label: "Latitude" },
    { key: "longitude", label: "Longitude" },
    { key: "dateTime", label: "Date/Time" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  creditCard: [
    ...DATA_TYPE_BASE,
    { key: "company", label: "Company" },
    { key: "creditCardNumber", label: "Credit card number" },
    { key: "nameOnCard", label: "Name on card" },
    { key: "expirationDate", label: "Expiration date" },
    { key: "dateLastUsed", label: "Date Last Used" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  network: [
    ...DATA_TYPE_BASE,
    { key: "ssid", label: "Name" },
    { key: "dateTime", label: "Date/Time" },
    { key: "lastAutoConnection", label: "Last Auto Connection" },
    { key: "lastConnection", label: "Last Connection" },
    { key: "latitude", label: "Latitude" },
    { key: "longitude", label: "Longitude" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  note: [
    ...DATA_TYPE_BASE,
    { key: "title", label: "Title" },
    { key: "folder", label: "Folder" },
    { key: "createdDate", label: "Created Date" },
    { key: "updatedDate", label: "Updated Date" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  database: [
    ...DATA_TYPE_BASE,
    { key: "creationTime", label: "Date/Time" },
    { key: "fileName", label: "File Name" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  default: [
    ...DATA_TYPE_BASE,
    { key: "dateTime", label: "Date/Time" },
    { key: "deletedState", label: "Deleted Status" },
  ],
  file: [
    ...DATA_TYPE_BASE,
    { key: "contentType", label: "Content Type" },
    { key: "title", label: "File Name" },
    { key: "dateTime", label: "Date/Time" },
    { key: "deletedState", label: "Deleted Status" },
  ],
};

export {
  DATA_TYPE,
  DATA_TYPE_LABEL,
  DATA_TITLE,
  DATA_TYPE_DISPLAY_PII,
  MEDIA_TYPE,
  TIMELINE_INFORMATION,
  FILE_TYPE,
  CHAT_EMAIL_TYPE,
  SPREADSHEET_MIME_TYPES,
  WORD_MIME_TYPES,
  PRESENTATION_MIME_TYPES,
  ZIP_MIME_TYPES,
};
