import { SORT_BY } from "./Constants";

export const COLUM_RESULT = {
  type: {
    key: "Type",
    label: "Type",
  },
  source: {
    key: "Source",
    label: "App",
  },
  docID: {
    key: "docID",
    label: "Record ID"
  },
  dataSourceID: {
    key: "DataSourceID",
    label: "Data Source",
  },
  participants: {
    key: "Participants",
    label: "Participants",
  },
  dateTime: {
    key: "DateTime",
    label: "Date First",
  },
  dateLast: {
    key: "DateLast",
    label: "Date Last",
  },
  description: {
    key: "Description",
    label: "Description",
  },
  itemsCount: {
    key: "ItemsCount",
    label: "Message Count",
  },
  attachmentCount: {
    key: "AttachmentCount",
    label: "Attachment Count",
  },
  deleted: {
    key: "Deleted",
    label: "Deleted",
  },
};

export const INITIAL_SORT = {
  DateTime: SORT_BY.asc,
  DateLast: SORT_BY.desc,
  Description: SORT_BY.asc,
  Source: SORT_BY.asc,
  Deleted: SORT_BY.asc,
  DocID: SORT_BY.asc,
  ItemsCount: SORT_BY.asc,
  AttachmentCount: SORT_BY.asc,
};

