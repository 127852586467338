import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

// Constants & Helpers
import { CONVERSATION_ACTION, DATE_TIME_TYPE } from "constants/Constants";
import { COMMON_TEXT } from "constants/Common";
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { urlify } from "helpers/TextHelper";
import { isCheckJoinLeave } from "helpers/ConversationHelper";

// Components
import { RenderTagSelected } from "components/shared/render-tag-selected/RenderTagSelected";
import SourceIcon from "components/shared/source-icon/SourceIcon";
import EmptyPage from "components/shared/empty-page/EmptyPage";

// Styles
import styles from "./ChatTable.module.scss";
import clsx from "clsx";

const chatTableHeader = [
  "ID",
  "Select",
  "Sender",
  "Date",
  "Time",
  "Body",
  "Attachment",
  "Tag",
  "View Attachment",
  "Source",
];

const ChatTable = (props) => {
  const {
    isLoading,
    isLoadingMore,
    handleScroll,
    messages = [],
    checkedMessage = [],
    handleCheckedMessage,
    setCheckedMessage,
    getHighlightedText,
    searchMsgInput,
    onOpenAttachment,
  } = props;

  return (
    <div className={styles["table-container"]}>
      <table>
        <thead>
          <tr>
            {chatTableHeader.map((item) => (
              <th key={item}>
                <span>{item}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody id="message-list-view" onScroll={handleScroll}>
          {isLoadingMore && !isLoading && (
            <tr>
              <td>
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
          {!isLoading ? (
            <>
              {messages?.length > 0 ? (
                messages.map((message, messageIndex) => (
                  <tr key={messageIndex} id={`position-${message.id}`}>
                    <td>{message.id}</td>
                    <td>
                      <input
                        type="checkbox"
                        value={message.instantMessageID}
                        checked={checkedMessage?.some(
                          (val) => val === message.instantMessageID
                        )}
                        onChange={(e) =>
                          handleCheckedMessage(e, setCheckedMessage)
                        }
                        id={`${message.instantMessageID}`}
                      />
                    </td>
                    <td>
                      {!isCheckJoinLeave(message.label)
                        ? message.name
                        : COMMON_TEXT.default}
                    </td>
                    <td>
                      {formatDateTime({
                        dateTime: message.timeStamp,
                        type: DATE_TIME_TYPE.MM_DD_YYYY,
                      })}
                    </td>
                    <td>
                      {formatDateTime({
                        dateTime: message.timeStamp,
                        type: DATE_TIME_TYPE.LT,
                        mst: true,
                      })}
                    </td>
                    <td>
                      <div
                        className={clsx(
                          styles["message-body"],
                          isCheckJoinLeave(message.label)
                            ? message.label === CONVERSATION_ACTION.leave
                              ? styles["leave"]
                              : styles["join"]
                            : ""
                        )}
                      >
                        {searchMsgInput
                          ? getHighlightedText({
                              text: message.body,
                              highlight: searchMsgInput,
                              instantMessageID: message.instantMessageID,
                              showThumbnail: false,
                            })
                          : urlify(message.body, null, true)}
                      </div>
                    </td>
                    <td>
                      {message.attachments?.length > 0
                        ? COMMON_TEXT.yes
                        : COMMON_TEXT.no}
                    </td>
                    <td>
                      <div className={styles["tag-thumb"]}>
                        {message.tags?.length > 0 ? (
                          <RenderTagSelected tagsSelected={message.tags} />
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td>
                      {message.attachments?.length > 0
                        ? message.attachments.map(
                            (attachment, attachmentIndex) => (
                              <a
                                key={attachmentIndex}
                                onClick={() =>
                                  onOpenAttachment(attachment.attachmentID)
                                }
                                className={styles["link"]}
                              >
                                Link{" "}
                                {attachmentIndex <
                                message.attachments.length - 1
                                  ? attachmentIndex + 1 + ", "
                                  : attachmentIndex + 1}
                              </a>
                            )
                          )
                        : COMMON_TEXT.default}
                    </td>
                    <td>
                      {message?.source ? (
                        <SourceIcon
                          sourceType={message.source}
                          defaultIcon="other"
                        />
                      ) : (
                        COMMON_TEXT.default
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <EmptyPage
                      messages="No results found. Please try again."
                      isImgnotFound
                    />
                  </td>
                </tr>
              )}
            </>
          ) : (
            <tr>
              <td>
                <Spinner animation="border" variant="primary" role="status" />
              </td>
            </tr>
          )}
          {isLoadingMore && !isLoading && (
            <tr>
              <td>
                <Spinner animation="border" variant="primary" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

ChatTable.propTypes = {
  isLoading: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  checkedMessage: PropTypes.array,
  searchMsgInput: PropTypes.string,
  messages: PropTypes.array,
  handleScroll: PropTypes.func,
  handleCheckedMessage: PropTypes.func,
  setCheckedMessage: PropTypes.func,
  getHighlightedText: PropTypes.func,
  onOpenAttachment: PropTypes.func,
};

export default ChatTable;
