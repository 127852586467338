import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";

// Helpers & Constants & hook
import { KEY_CODE } from "constants/Common";
import { uppercaseFirstLetterEachWord } from "helpers/FormatterHelper";
import { useOnClickOutside } from "hook/click-outside";

// Styles
import styles from "./DropdownChat.module.scss";

export const DropdownChat = (props) => {
  const {
    isBorder = true,
    type = "button",
    className = "",
    scrollable = true,
    options,
    onSelect,
    selectedId,
    size, //sm, md, lg
    widthStyle = "", //auto
    isDisable,
    isLoading,
    isNormalLetter = false,
    allowAutoScroll = true,
    ...rest
  } = props;

  const [toggle, setToggle] = useState(false);
  const [focusItem, setFocusItem] = useState(selectedId); // used for set focus item to display when still not set value
  const [selectByUpDown, setSelectByUpDown] = useState(false); // used for handle show toggle, prevent toggle always set true when select item by enter

  const dropdownRef = useRef(null);

  const onToggleMenuHandle = () => {
    if (isDisable) return;
    if (!selectByUpDown) setToggle(!toggle);
    else setSelectByUpDown(false);
  };

  useEffect(() => {
    if (selectedId && scrollable) {
      let index =
        options && options.findIndex((item) => item.id === selectedId);
      let element = document.getElementById(`item-${index}`);
      element && allowAutoScroll && element.scrollIntoView();
      setFocusItem(selectedId);
    }
  }, [toggle]);

  const handleSelect = (data) => {
    setToggle(false);
    onSelect(data);
    setFocusItem(data?.id);
  };

  useOnClickOutside(dropdownRef, () => setToggle(false));

  const onKeyDown = (e) => {
    if (!toggle || !e.keyCode) return;
    const optionsSize = options.length;
    const currentFocusIndex = options.findIndex(
      (item) => item.id === focusItem
    );
    switch (e.keyCode) {
      case KEY_CODE.arrowUp:
        if (currentFocusIndex > 0) {
          setFocusItem(options[currentFocusIndex - 1].id);
          const element = document.getElementById(
            `item-${currentFocusIndex - 1}`
          );
          element?.scrollIntoView({
            behavior: "auto",
            block: "nearest",
            inline: "center",
          });
        }
        if (currentFocusIndex === 0) {
          setFocusItem(options[optionsSize - 1].id);
          const element = document.getElementById(`item-${optionsSize - 1}`);
          element?.scrollIntoView();
        }
        break;
      case KEY_CODE.arrowDown:
        if (currentFocusIndex < optionsSize - 1) {
          setFocusItem(options[currentFocusIndex + 1].id);
          const element = document.getElementById(
            `item-${currentFocusIndex + 1}`
          );
          element?.scrollIntoView({
            behavior: "auto",
            block: "nearest",
            inline: "center",
          });
        }
        if (currentFocusIndex === optionsSize - 1) {
          setFocusItem(options[0].id);
          const element = document.getElementById(`item-${0}`);
          element?.scrollIntoView();
        }
        break;
      case KEY_CODE.enter:
        onSelect(options[currentFocusIndex]);
        setSelectByUpDown(true);
        setToggle(false);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setFocusItem(selectedId);
  }, [selectedId]);

  return (
    <div
      className={clsx(
        styles["dropdown"],
        styles[size],
        widthStyle === "auto" ? styles["dropdown-auto"] : ""
      )}
      ref={dropdownRef}
      onKeyDown={onKeyDown}
    >
      <button
        {...rest}
        type={type}
        onClick={onToggleMenuHandle}
        className={clsx(
          className,
          isBorder ? styles["btn-border"] : styles["btn-border-none"]
        )}
        disabled={isDisable ? styles["disabled"] : ""}
      >
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <span>
            {isNormalLetter
              ? options.find((item) => item.id === selectedId)?.name
              : uppercaseFirstLetterEachWord(
                  options.find((item) => item.id === selectedId)?.name
                )}
          </span>
        )}
        <img
          src={`/images/icons/arrow-down${
            size === "lg" || size === "md" ? "" : "-blue"
          }.svg`}
          alt="Arrow down"
        />
      </button>
      {toggle && (
        <ul className={styles["dropdown-selectors"]}>
          {options &&
            options.map((item, index) => (
              <li
                id={`item-${index}`}
                key={index}
                className={focusItem === item.id ? styles.selected : ""}
                onClick={() => handleSelect(item)}
              >
                {isNormalLetter
                  ? item.name
                  : uppercaseFirstLetterEachWord(item.name)}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

DropdownChat.propTypes = {
  type: PropTypes.string,
  altIcon: PropTypes.string,
  isBorder: PropTypes.bool,
  isDisable: PropTypes.bool,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  selectedId: PropTypes.string,
  size: PropTypes.string,
  widthStyle: PropTypes.string,
  isLoading: PropTypes.bool,
  isNormalLetter: PropTypes.bool,
  allowAutoScroll: PropTypes.bool,
  onSelect: PropTypes.func,
};
