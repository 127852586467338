import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Modal, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, DateRangePicker } from "react-date-range";
import { enGB } from "react-date-range/dist/locale";

// Store
import {
  updateDateTime,
  updateDateTimeMessageViewPicker
} from "store/DatePickerReducer";
import { setCanExecuteHotKey } from "store/TagReducer";

// Components
import { Button } from "components/shared/button/Button";
import { TimePicker } from "components/shared/date-picker/TimePicker";

// Constants & Helpers
import { DATE_SELECTOR } from "constants/DateSelector";
import { DATE_TIME, DATE_TIME_TYPE } from "constants/Constants";
import {
  formatDateTime,
  formatDateTimeWithOriginal,
  getHourByFrame,
  getMinuteDisplay,
  formatDataTimeValue,
  convertDate,
} from "helpers/DateTimeFormatterHelper";

// Styles
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./styles.scss";

export const DateSelector = (props) => {
  const { handleSubmit, showDatePicker, handleClose, data, stateName = "" } = props;
  const { startDate, endDate, typeFilter, includeBlankDate } = data;

  const {
    includeBlankDate: includeBlankDateGeneral
  } = useSelector((state) => state.datePicker.datePicker);

  const initDateRanges = [
    {
      startDate: startDate ?? null,
      endDate: endDate ?? null,
      key: "selection",
    },
  ];

  const initialBlankDate = stateName
    ? (includeBlankDate ?? true)
    : includeBlankDateGeneral;

  const dispatch = useDispatch();
  const today = new Date();

  const [timeBefore, setTimeBefore] = useState(convertDate(null));
  const [timeAfter, setTimeAfter] = useState(convertDate(null));

  const [timeFrameStart, setTimeFrameStart] = useState(
    today.getHours() < 12
      ? DATE_SELECTOR.timeFrame.am
      : DATE_SELECTOR.timeFrame.pm
  );
  const [timeFrameEnd, setTimeFrameEnd] = useState(
    today.getHours() < 12
      ? DATE_SELECTOR.timeFrame.am
      : DATE_SELECTOR.timeFrame.pm
  );

  const [includeBlank, setIncludeBlank] = useState(initialBlankDate);
  const [ranges, setRanges] = useState(initDateRanges);
  const [typeDateFilter, setTypeDateFilter] = useState(typeFilter || DATE_SELECTOR.type.after);
  const [hourStart, setHourStart] = useState(today.getHours());
  const [minuteStart, setMinuteStart] = useState(today.getMinutes());
  const [hourEnd, setHourEnd] = useState(today.getHours());
  const [minuteEnd, setMinuteEnd] = useState(today.getMinutes());
  const [dateEndCurrent, setDateEndCurrent] = useState(null);
  const [dateStartCurrent, setDateStartCurrent] = useState(null);
  const [displayDateSelect, setDisplayDateSelect] = useState({
    dateStart: null,
    timeStart: null,
    dateEnd: null,
    timeEnd: null,
    timeFrameStart: null,
    timeFrameEnd: null
  });

  const handleSelectMultiDate = (ranges) => {
    const { selection } = ranges;

    setCurrentSelectedDate(selection);

    if (dateStartCurrent === dateEndCurrent) {
      setDefaultStartTime();
      setDefaultEndTime();
    }  

    setRanges([selection]);
  };

  const handleSelectSingleDate = (e) => {
    let singleDateRanges = { startDate: new Date(e), endDate: new Date(e), key: "selection" };

    if(typeDateFilter === DATE_SELECTOR.type.after) {
      if (dateEndCurrent !== null && e < dateEndCurrent)
        singleDateRanges.endDate = dateEndCurrent
      else
        singleDateRanges.endDate = null;

      setDefaultStartTime();
    } 
    else if (typeDateFilter === DATE_SELECTOR.type.before) {
      if (dateStartCurrent !== null && dateStartCurrent < e)
        singleDateRanges.startDate = dateStartCurrent
      else
        singleDateRanges.startDate = null;

      setDefaultEndTime();
    }

    setCurrentSelectedDate(singleDateRanges);
    setRanges([singleDateRanges]);
  };

  const setCurrentSelectedDate = (selection) => {
    const dateStart = selection.startDate ? selection.startDate : null;
    const dateEnd = selection.endDate ? selection.endDate : null;

    setTimeAfter(dateStart ? convertDate(dateStart) : null);
    setDateStartCurrent(dateStart ? dateStart : null);

    setTimeBefore(dateEnd ? convertDate(dateEnd) : null);
    setDateEndCurrent(dateEnd ? dateEnd : null);
  }

  const setDefaultStartTime = () => {
    setHourStart(0);
    setMinuteStart(0);
    setTimeFrameStart(DATE_SELECTOR.timeFrame.am);
  }

  const setDefaultEndTime = () => {
    setHourEnd(11);
    setMinuteEnd(59);
    setTimeFrameEnd(DATE_SELECTOR.timeFrame.pm);
  }

  const initialDisplayDate = () => {
    if (dateStartCurrent !== null || dateEndCurrent !== null) {
      let dateStart = null;
      let dateEnd = null;

      // Check if dateStartCurrent and dateEndCurrent are not null - if not, set dateStart or dateEnd
      if (dateStartCurrent !== null) 
        dateStart = (typeDateFilter !== DATE_SELECTOR.type.between) ? dateStartCurrent : ranges[0].startDate;

      if (dateEndCurrent !== null) 
        dateEnd = (typeDateFilter !== DATE_SELECTOR.type.between) ? dateEndCurrent : ranges[0].endDate;
      
      let hourStartCheck = hourStart;
      let hourEndCheck = hourEnd;
      let minuteStartCheck = minuteStart;
      let minuteEndCheck = minuteEnd;

      if (timeFrameStart === DATE_SELECTOR.timeFrame.am) 
        hourStartCheck = (hourStartCheck === 12) ? 0 : hourStartCheck;

      if (timeFrameEnd === DATE_SELECTOR.timeFrame.am) 
        hourEndCheck = (hourEndCheck === 12) ? 0 : hourEndCheck;

      // Check if time start greater than time end will set value again
      if (typeDateFilter === DATE_SELECTOR.type.between && timeBefore === timeAfter) {
        hourStartCheck = getHourByFrame(timeFrameStart, hourStartCheck);
        hourEndCheck = getHourByFrame(timeFrameEnd, hourEndCheck);

        // Allow user clear hour, minute of end time, only check time when blur out of input
        const isInvalidEndTimeFrameAM = hourStartCheck > hourEndCheck &&
          timeFrameStart === DATE_SELECTOR.timeFrame.am &&
          timeFrameEnd === DATE_SELECTOR.timeFrame.am;

        const isInvalidEndTimeFramePM = hourStartCheck > hourEndCheck &&
          timeFrameStart === DATE_SELECTOR.timeFrame.pm &&
          timeFrameEnd === DATE_SELECTOR.timeFrame.pm;

        if (isInvalidEndTimeFrameAM || isInvalidEndTimeFramePM) {
          hourEndCheck = hourStartCheck;
          setHourEnd(hourEndCheck);
        }

        // Minute
        if (hourStartCheck === hourEndCheck && minuteStartCheck > minuteEndCheck) {
          minuteEndCheck = minuteStartCheck;
          setMinuteEnd(minuteEndCheck);
        }

        // Case: frameStart is PM, frameEnd is AM
        if (timeFrameStart === DATE_SELECTOR.timeFrame.pm && timeFrameEnd === DATE_SELECTOR.timeFrame.am) {
          hourEndCheck = hourStartCheck;
          minuteEndCheck = minuteStartCheck;
          setTimeFrameEnd(timeFrameStart);
        }
      }

      let timeStart = dateStart
        ? `${hourStartCheck > 12 ? hourStartCheck - 12 : hourStartCheck}:${minuteStartCheck}`
        : null;

      let timeEnd = dateEnd
        ? `${hourEndCheck > 12 ? hourEndCheck - 12 : hourEndCheck}:${minuteEndCheck}`
        : null;

      const displayDateSelectInitial = {
        dateStart: dateStart,
        timeStart: timeStart,
        dateEnd: dateEnd,
        timeEnd: timeEnd,
        timeFrameStart: dateStart ? timeFrameStart : null,
        timeFrameEnd: dateEnd ? timeFrameEnd : null,
      };

      if (typeDateFilter === DATE_SELECTOR.type.after || typeDateFilter === DATE_SELECTOR.type.between)
        setTimeAfter(dateStart ? convertDate(dateStart) : null);

      if (typeDateFilter === DATE_SELECTOR.type.before || typeDateFilter === DATE_SELECTOR.type.between)
        setTimeBefore(dateEnd ? convertDate(dateEnd) : null);

      setDisplayDateSelect(displayDateSelectInitial);
    }
    else {
      setTimeAfter(null);
      setTimeBefore(null);

      setDisplayDateSelect({
        dateStart: null,
        timeStart: null,
        dateEnd: null,
        timeEnd: null,
        timeFrameStart: DATE_SELECTOR.timeFrame.am,
        timeFrameEnd: DATE_SELECTOR.timeFrame.pm
      });
    }
  };

  const onSelectAmOrPmStart = (e) => {
    setTimeFrameStart(
      e.target.innerHTML === DATE_SELECTOR.timeFrame.am
        ? DATE_SELECTOR.timeFrame.am
        : DATE_SELECTOR.timeFrame.pm
    );
    initialDisplayDate();
  };

  const onSelectAmOrPmEnd = (e) => {
    setTimeFrameEnd(
      e.target.innerHTML === DATE_SELECTOR.timeFrame.am
        ? DATE_SELECTOR.timeFrame.am
        : DATE_SELECTOR.timeFrame.pm
    );
    initialDisplayDate();
  };

  const onChangeHourStart = (e) => {
    let dataParse = parseInt(e.target.value);
    setHourStart(dataParse);
  };

  const onChangeMinuteStart = (e) => {
    let dataParse = parseInt(e.target.value);
    setMinuteStart(dataParse);
  };

  const onChangeHourEnd = (e) => {
    let dataParse = parseInt(e.target.value);
    setHourEnd(dataParse);
  };

  const onChangeMinuteEnd = (e) => {
    let dataParse = parseInt(e.target.value);
    setMinuteEnd(dataParse);
  };

  const onSelectCurrentDate = (date) => {
    handleSelectSingleDate(date);
  };

  const handleSubmitDate = async () => {
    let dateTimeDataStart = null;
    let dateTimeDataEnd = null;
  
    const getDateData = (date, timeFrame, hour, minute) => {
      const minuteValue = getMinuteDisplay(minute);
      return formatDataTimeValue({ date, timeFrame, hour, minute: minuteValue });
    };
  
    switch (typeDateFilter) {
      case DATE_SELECTOR.type.before:
        dateTimeDataEnd = getDateData(timeBefore, timeFrameEnd, hourEnd, minuteEnd);
        break;
  
      case DATE_SELECTOR.type.after:
        dateTimeDataStart = getDateData(timeAfter, timeFrameStart, hourStart, minuteStart);
        break;
  
      case DATE_SELECTOR.type.between: {
        const startDate = convertDate(ranges[0]?.startDate || null);
        const endDate = convertDate(ranges[0]?.endDate || null);
  
        dateTimeDataStart = getDateData(startDate, timeFrameStart, hourStart, minuteStart);
        dateTimeDataEnd = getDateData(endDate, timeFrameEnd, hourEnd, minuteEnd);
        break;
      }
  
      default:
        break;
    }
  
    const dateTimeSubmit = {
      dateTimeDataStart,
      dateTimeDataEnd,
      typeFilter: typeDateFilter,
      includeBlankDate: includeBlank,
    };

    if (stateName === "messageViewDatePicker") {
      dispatch(updateDateTimeMessageViewPicker(dateTimeSubmit));
    } else if (stateName !== "matterDatePicker") {
      dispatch(updateDateTime(dateTimeSubmit));
    }
  
    handleSubmit(dateTimeSubmit);
    handleCloseDatePicker();
  };  

  const handleCloseDatePicker = () => {
    handleClose();
  };

  const resetData = () => {
    setTypeDateFilter(DATE_SELECTOR.type.after);
    setHourStart(null);
    setMinuteStart(null);
    setHourEnd(null);
    setMinuteEnd(null);
    setTimeFrameStart(null);
    setTimeFrameEnd(null);
    setDateStartCurrent(null);
    setDateEndCurrent(null);
    setIncludeBlank(true);
    setDisplayDateSelect(null);
    setTimeAfter(null);
    setTimeBefore(null);
    setRanges([{
      startDate: null,
      endDate: null,
      key: "selection",
    }]);
  };

  const setData = () => {
    if (startDate && endDate)
      setTypeDateFilter(DATE_SELECTOR.type.between);
    else if (endDate)
      setTypeDateFilter(DATE_SELECTOR.type.before);
    else
      setTypeDateFilter(DATE_SELECTOR.type.after);

    setIncludeBlank(initialBlankDate);

    let dateStartOriginal = startDate ? new Date(startDate) : null;
    setDateStartCurrent(dateStartOriginal);

    let dateEndOriginal = endDate ? new Date(endDate) : null;
    setDateEndCurrent(dateEndOriginal);

    setRanges([{
      startDate: dateStartOriginal,
      endDate: dateEndOriginal,
      key: "selection",
    }]);

    let hourStartOriginal = dateStartOriginal?.getHours() ?? null;
    let hourStartAdjusted = hourStartOriginal !== null ? (hourStartOriginal > 12 ? hourStartOriginal - 12 : hourStartOriginal) : 0;
    setHourStart(hourStartAdjusted);

    let minuteStartOriginal = dateStartOriginal ? dateStartOriginal.getMinutes() : null;
    setMinuteStart(minuteStartOriginal);

    let hourEndOriginal = dateEndOriginal ? dateEndOriginal.getHours() : null;
    let hourEndAdjusted = hourEndOriginal ? (hourEndOriginal > 12 ? hourEndOriginal - 12 : hourEndOriginal) : null;
    setHourEnd(hourEndAdjusted);

    let minuteEndOriginal = dateEndOriginal ? dateEndOriginal.getMinutes() : null;
    setMinuteEnd(minuteEndOriginal);

    let timeFrameStart = ( 
      dateStartOriginal 
      ? (dateStartOriginal.getHours() < 12
        ? DATE_SELECTOR.timeFrame.am
        : DATE_SELECTOR.timeFrame.pm)
      : DATE_SELECTOR.timeFrame.am
    );
    setTimeFrameStart(timeFrameStart);

    let timeFrameEnd = ( 
      dateEndOriginal 
      ? (dateEndOriginal.getHours() < 12
        ? DATE_SELECTOR.timeFrame.am
        : DATE_SELECTOR.timeFrame.pm)
      : DATE_SELECTOR.timeFrame.pm
    );
    setTimeFrameEnd(timeFrameEnd);

    let timeStart = dateStartOriginal
      ? `${hourStartAdjusted}:${minuteStartOriginal}`
      : null;

    let timeEnd = dateEndOriginal
      ? `${hourEndAdjusted}:${minuteEndOriginal}`
      : null;

    setDisplayDateSelect({
      dateStart: dateStartOriginal,
      timeStart: timeStart,
      dateEnd: dateEndOriginal,
      timeEnd: timeEnd,
      timeFrameStart: timeFrameStart,
      timeFrameEnd: timeFrameEnd
    });
  };
  
  const renderNavLink = (filterType, label) => (
      <Nav.Item>
        <Nav.Link
          onClick={() => setTypeDateFilter(filterType)}
          eventKey={filterType}
        >
          {label}
        </Nav.Link>
      </Nav.Item>
  );

  const getCalendarToRender = () => {
    switch (typeDateFilter) {
      case DATE_SELECTOR.type.between:
        return (
          <DateRangePicker
            locale={enGB}
            direction="horizontal"
            months={2}
            ranges={ranges}
            onChange={handleSelectMultiDate}
            minDate={new Date(DATE_TIME.minDate)}
            className={rdrNoSelection ? 'rdrNoSelection' : ''}
          />
        );
      case DATE_SELECTOR.type.after:
        return (
          <Calendar
            date={dateStartCurrent ? new Date(dateStartCurrent) : null}
            onChange={onSelectCurrentDate}
            locale={enGB}
            showPreview={false}
            minDate={new Date(DATE_TIME.minDate)}
          />
        );
      case DATE_SELECTOR.type.before:
        return (
          <Calendar
            date={dateEndCurrent ? new Date(dateEndCurrent) : null}
            onChange={onSelectCurrentDate}
            locale={enGB}
            showPreview={false}
            minDate={new Date(DATE_TIME.minDate)}
          />
        );
      default:
        return (
          <Calendar
            date={null}
            onChange={onSelectCurrentDate}
            locale={enGB}
            showPreview={false}
            minDate={new Date(DATE_TIME.minDate)}
          />
        );
    }
  };

  const getTimePickerToRender = () => {
    if (typeDateFilter === DATE_SELECTOR.type.between) {
      return (
        <>
          <TimePicker
            displayDateSelect={displayDateSelect}
            selectAmOrPm={onSelectAmOrPmStart}
            onChangeHour={onChangeHourStart}
            onChangeMinute={onChangeMinuteStart}
            initialDisplayDate={initialDisplayDate}
          />
          <TimePicker
            typeDateFilter={2}
            displayDateSelect={displayDateSelect}
            selectAmOrPm={onSelectAmOrPmEnd}
            onChangeHour={onChangeHourEnd}
            onChangeMinute={onChangeMinuteEnd}
            initialDisplayDate={initialDisplayDate}
          />
        </>
      );
    } else if (typeDateFilter === DATE_SELECTOR.type.after) {
      return (
        <TimePicker
          displayDateSelect={displayDateSelect}
          selectAmOrPm={onSelectAmOrPmStart}
          onChangeHour={onChangeHourStart}
          onChangeMinute={onChangeMinuteStart}
          initialDisplayDate={() => {}}
        />
      );
    } else if (typeDateFilter === DATE_SELECTOR.type.before) {
      return (
        <TimePicker
          typeDateFilter={2}
          displayDateSelect={displayDateSelect}
          selectAmOrPm={onSelectAmOrPmEnd}
          onChangeHour={onChangeHourEnd}
          onChangeMinute={onChangeMinuteEnd}
          initialDisplayDate={() => {}}
        />
      );
    }
  };

  // Fix highlight on all dates when date data is null
  const rdrNoSelection = useMemo(
    () => {
      const range = ranges?.[0];
      return !range || (
        (range.startDate === null || range.startDate === undefined) &&
        (range.endDate === null || range.endDate === undefined)
      );
    },
    [ranges]
  );

  // Check datepicker is showing will disable press hot key to add tag
  useEffect(() => {
    setData();
    dispatch(setCanExecuteHotKey(!showDatePicker));

    if (showDatePicker)
      setIncludeBlank(includeBlankDate ?? true);
    else
      setIncludeBlank(!includeBlankDate ?? false);
  }, [showDatePicker]);

  // Observe to change display date
  useEffect(() => {
    initialDisplayDate();
  }, [
    typeDateFilter,
    ranges,
    hourStart,
    hourEnd,
    minuteStart,
    minuteEnd,
    dateStartCurrent,
    dateEndCurrent
  ]);

  useEffect(() => {
    initialDisplayDate();
  }, [timeFrameStart, timeFrameEnd]);

  // Update includeBlank when initialBlankDate change
  useEffect(() => {
    setIncludeBlank(initialBlankDate);
  }, [initialBlankDate]);

  useEffect(() => {
    setTypeDateFilter(typeFilter);
  }, [typeFilter]);

  return (
    <div>
      <Modal
        className="date-picker-control"
        show={showDatePicker}
        onHide={handleCloseDatePicker}
      >
        <Modal.Header closeButton>
          <Modal.Title>Date Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="date-picker-control__body body-picker">
          <Nav
            variant="pills"
            defaultActiveKey={typeDateFilter}
            className="btn-group"
            activeKey={typeDateFilter}
          >
            {renderNavLink(DATE_SELECTOR.type.after, "After")}
            {renderNavLink(DATE_SELECTOR.type.before, "Before")}
            {renderNavLink(DATE_SELECTOR.type.between, "Between")}
          </Nav>
          {getCalendarToRender()}
          <div className="d-flex justify-content-around">
            {getTimePickerToRender()}
          </div>
        </Modal.Body>
        <Modal.Footer className="date-picker-control__footer">
          <div className="select-date-container">
            {displayDateSelect?.dateStart && (typeDateFilter === DATE_SELECTOR.type.after || typeDateFilter === DATE_SELECTOR.type.between) && (
              <>
                <span className="select-date-container__title">Selected:</span>
                <b className="pe-2">
                  <span> After </span>
                  {formatDateTime({
                    dateTime: displayDateSelect?.dateStart,
                    type: DATE_TIME_TYPE.MMM_DD_YYYY,
                    addZulu: false,
                  })}
                </b>
                <span className="pe-2">
                  {formatDateTimeWithOriginal({
                    dateTime: displayDateSelect?.timeStart,
                    original: DATE_TIME_TYPE.HHmm,
                    newFormat: DATE_TIME_TYPE.HHmm,
                    addZulu: false,
                  })}
                </span>
                {displayDateSelect?.timeFrameStart}
              </>
            )}
            {displayDateSelect?.dateStart && displayDateSelect?.dateEnd && typeDateFilter === DATE_SELECTOR.type.between && (
              <span className="ps-2 pe-2 date_to_title">to</span>
            )}
            {displayDateSelect?.dateEnd && (typeDateFilter === DATE_SELECTOR.type.before || typeDateFilter === DATE_SELECTOR.type.between) && (
              <>
                {(
                  typeDateFilter === DATE_SELECTOR.type.before || (typeDateFilter === DATE_SELECTOR.type.between && !displayDateSelect?.dateStart)
                ) && (
                  <span className="select-date-container__title">Selected:</span>
                )}
                <b className="pe-2">
                  <span> Before </span>
                  {formatDateTime({
                    dateTime: displayDateSelect?.dateEnd,
                    type: DATE_TIME_TYPE.MMM_DD_YYYY,
                    addZulu: false,
                  })}
                </b>
                <span className="pe-2">
                  {formatDateTimeWithOriginal({
                    dateTime: displayDateSelect?.timeEnd,
                    original: DATE_TIME_TYPE.HHmm,
                    newFormat: DATE_TIME_TYPE.HHmm,
                    addZulu: false,
                  })}
                </span>
                {displayDateSelect?.timeFrameEnd}
              </>
            )}
          </div>
          <div className="group-btn_clear_submit">
            {stateName !== "matterDatePicker" && (
              <div className="blank_date_container">
                <input
                  className="blank_date_checkbox"
                  type="checkbox"
                  checked={includeBlank}
                  onChange={() => setIncludeBlank(!includeBlank)}
                />
                <p
                  className="invalid_date_txt"
                  onClick={() => setIncludeBlank(!includeBlank)}
                >
                  Include Invalid/Blank Dates
                </p>
              </div>
            )}
            <div className="btn_group">
              <Button
                name="Reset"
                className="btn_clear_filter_date"
                handleClick={() => {
                  resetData();
                }}
              />
              <Button
                name="Save"
                className="btn-primary-fill"
                handleClick={handleSubmitDate}
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

DateSelector.propTypes = {
  showDatePicker: PropTypes.bool,
  stateName: PropTypes.string,
  data: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
};