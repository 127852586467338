import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

// Services
import * as SavedSearchService from "services/SavedSearchService";
import * as TagManagementService from "services/TagManagementService";
import * as MassTagService from "services/MassTagService";

// Constants
import { COLORS } from "constants/Common";
import { GetMassTagToastId } from "constants/MassTagConstants";

// Components
import DropdownGeneral from "components/shared/dropdown-selector/dropdown-general/DropdownGeneral";
import { Button } from "components/shared/button/Button";
import { toast } from "react-toastify";

// Styles
import styles from "./MassTagSavedSearch.module.scss";
import { BeatLoader } from "react-spinners";

const MassTagSavedSearch = (props) => {
    const {
        projectListOptions,
    } = props;

    const [selectedProject, setSelectedProject] = useState({
        value: "",
        label: ""
    });

    const [savedSearches, setSavedSearches] = useState([]);
    const [selectedSavedSearch, setSelectedSavedSearch] = useState({
        value: "",
        label: ""
    });

    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const fetchSavedSearches = async (project) => {
        if (project.value !== "") {
            setSelectedProject(project);
            setIsLoading(true);
            try {
                const searches = await SavedSearchService.getManageSearch(project.value, {
                    PaginationParams: {
                        PageSize: 100,
                        PageNumber: 1,
                        ColumnSort: "dateCreated",
                        OrderBy: "desc"
                    }
                });
                setSavedSearches(searches.data.items);
            } catch (error) {
                console.log("Search error is: ", error);
                toast.error("An error occurred while fetching saved searches");
            } finally {
                setIsLoading(false);
            }
        }
    };

    const fetchTags = async (matterId) => {
        const matterTags = await TagManagementService.getTags(matterId);
        setTags(matterTags.data.allTags);
    };

    const applyTagToSavedSearch = async () => {
        setIsLoading(true);
        try {
            const result = await MassTagService.applyMassTagToSavedSearch(
                selectedProject.value,
                selectedSavedSearch.value, {
                tagIds: selectedTags.map((t) => t.value)
            });
            toast.loading(`Successfully queued mass tag job with ID ${result.data.jobId}. Tagging...`, {
                toastId: GetMassTagToastId(result.data.jobId),
                autoClose: false,
            });
        } catch (error) {
            console.log("Tag error is: ", error);
            toast.error("An error occurred while queuing Mass Tag job for saved search.");
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles["wrap"]}>
            <h3>Mass Apply a Tag to a Saved Search</h3>
            <div className={clsx(styles["form-group"], "app-form-group")}>
                <label className="app-form-label">
                    Project Name
                    <span className={styles["form-required"]}> *</span>
                </label>
                <DropdownGeneral
                    className={clsx(
                        "dropdown-react-select",
                        "w-100",
                        styles["select-project"]
                    )}
                    classNameContainer=""
                    options={projectListOptions}
                    onChange={(matterId) => {
                        fetchSavedSearches(matterId);
                        fetchTags(matterId);
                    }}
                    value={[selectedProject]}
                    isSearchable={true}
                    placeholder="Select Project"
                    isDisabled={false}
                    isLoading={false}
                />
                <label className="app-form-label">
                    Saved Search
                    <span className={styles["form-required"]}> *</span>
                </label>
                <DropdownGeneral
                    className={clsx(
                        "dropdown-react-select",
                        "w-100",
                        styles["select-project"]
                    )}
                    classNameContainer=""
                    options={savedSearches.map((search) => ({
                        value: search.searchID,
                        label: search.name
                    }))}
                    onChange={(search) => {
                        if (search.label !== "") {
                            setSelectedSavedSearch(search);
                        }
                    }}
                    value={[selectedSavedSearch]}
                    isSearchable={true}
                    placeholder="Select Saved Search"
                    isDisabled={selectedProject.value === ""}
                    isLoading={isLoading}
                />
                <label className="app-form-label">
                    Tags
                    <span className={styles["form-required"]}> *</span>
                </label>
                <DropdownGeneral
                    className={clsx(
                        "dropdown-react-select",
                        "w-100",
                        styles["select-project"]
                    )}
                    classNameContainer=""
                    options={tags.map((tag) => ({
                        value: tag.tagID,
                        label: tag.name
                    }))}
                    onChange={(tag) => {
                        if (tag.label !== "") {
                            if (selectedTags.some((t) => t.value === tag.value)) {
                                setSelectedTags(selectedTags.filter((t) => t.value !== tag.value));
                            } else {
                                setSelectedTags([...selectedTags, tag]);
                            }
                        }
                    }}
                    value={selectedTags}
                    isSearchable={true}
                    placeholder="Select Tag(s)"
                    isDisabled={selectedProject.value === ""}
                    isLoading={isLoading}
                />
                {isLoading ? (
                    <BeatLoader>
                        color={COLORS.black}
                        loading={true}
                        size={8}
                        className={styles["beat-loader"]}
                    </BeatLoader>
                )
                    : (
                        <Button
                            name="Apply Tag(s) To Results"
                            className="btn-primary-fill"
                            handleClick={() => {
                                if (selectedProject.value !== "" && selectedSavedSearch.value !== "" && selectedTags.length > 0) {
                                    applyTagToSavedSearch();
                                }
                            }}
                            isDisabled={selectedProject.value === "" || selectedSavedSearch.value === "" || selectedTags.length === 0}
                        />)}
            </div>
        </div>)
};

MassTagSavedSearch.propTypes = {
    projectListOptions: PropTypes.array,
};

export default MassTagSavedSearch;