import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "hook/click-outside";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Components
import Select from "react-select";
import SearchFilter from "../search-input/search-filter/SearchFilter";
import FilterList from "../select-list/FilterList";

// Reducers
import { setCanExecuteHotKey } from "store/TagReducer";

// Constants & Helpers
import { QUICK_SELECT_ITEM } from "constants/Common";
import { sortAlphabet } from "helpers/CommonHelper";
import { isPopupClipped } from "helpers/PopupClippedHelper";

// Services
import { searchContactApi } from "services/CommonService";

// Styles
import styles from "./QuickFilterGroup.module.scss";
import clsx from "clsx";

const QuickFilterGroup = (props) => {
  const {
    totalDataSelected = [],
    totalDataUnSelect = [],
    getDataSelect = () => { },
    filterLabel = {},
    placeholder = {},
    isReset = 0,
    isEmoji = false,
    disable = false,
    disableSelectAll = false,
    isSuggestion = true,
    totalData,
    notSelectedOption,
  } = props;

  const { projectId } = useParams();
  const menuRef = useRef();
  const [inputSearch, setInputSearch] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [unSelectList, setUnSelectList] = useState([]);
  const [selectedList, setSelectList] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isNotSelected, setIsNotSelected] = useState(false);

  const dispatch = useDispatch();

  const getValueSelected = () => {
    if (isNotSelected) {
      return {
        label: 'Not Tagged Results'
      };
    }

    const labelObject = placeholder ? placeholder : filterLabel;
    return selectedList.length > 0
      ? {
        label: `${selectedList.length} ${selectedList.length > 1 ? labelObject.label : labelObject.labelShort
          }`,
      }
      : null;
  };

  // Filter and sort data by alphabet
  const filterData = (array, value) => {
    const filteredValue = array.filter((item) => item.value !== value);
    return isEmoji ? filteredValue : sortAlphabet(filteredValue);
  };

  // Select data filter
  const handleSelectData = (data) => {
    handleCustomOptionClick(false);
    // Add data into selected list
    setSelectList([...selectedList, data]);
    // Make a data filter with input search condition
    const selectListFiltered = filterData(unSelectList, data.value);
    // Remove data in unselect list
    setUnSelectList(selectListFiltered);
    // Throw data selected for parent component
    getDataSelect([...selectedList, data]);
    console.log([...selectedList, data]);
  };

  // Remove selected data
  const handleUnSelectData = (data) => {
    // Add data into unselect list
    const dataUnselect = sortAlphabet([...unSelectList, data]);
    // Add data into unselect list field emojis
    const findIndex = totalDataUnSelect.findIndex(
      (item) => item.value === data.value
    );
    unSelectList.splice(findIndex, 0, data);
    const dataUnselectEmojis = [...unSelectList];
    setUnSelectList(isEmoji ? dataUnselectEmojis : dataUnselect);
    // Make a data filter with input search condition
    const dataSelected = filterData(selectedList, data.value);
    // Remove data in selected list
    setSelectList(dataSelected);
    // Throw data selected for parent component
    getDataSelect(filterData(selectedList, data.value));
  };

  // Select all data
  const handleSelectAll = (isSelectAll = true) => {
    // Making selected data
    const dataAll = isSuggestion
      ? [...totalDataSelected, ...totalDataUnSelect]
      : [...selectedList, ...unSelectList];
    // Update selected data and unselect data
    if (isSelectAll) {
      handleCustomOptionClick(false);
      // Remove all data in unselect list
      setUnSelectList([]);
      // Add all data into select list
      setSelectList(dataAll);
      // Throw data selected for parent component
      getDataSelect(dataAll);
    } else {
      // Remove all data in selected list
      setSelectList([]);
      // Add all data into unselect list
      setUnSelectList(sortAlphabet(dataAll));
      // Throw data selected for parent component
      getDataSelect([]);
    }
  };

  const handleCustomOptionClick = (isNotSelected) => {
    setIsNotSelected(isNotSelected);
    notSelectedOption?.onSelectCallback(isNotSelected);
  }

  const toggleFilter = (isToggle) => {
    setShowSuggestion(isToggle);
    if (!isToggle) setInputSearch("");
  };

  const makeUnselectData = (allData = []) => {
    const selectedIds = selectedList.map((item) => item.value);
    return sortAlphabet(
      allData.filter((item) => !selectedIds.includes(item.value))
    );
  };

  // Close suggestion when click outside
  useOnClickOutside(menuRef, () => {
    if (!isSuggestion) setUnSelectList(makeUnselectData(totalData));
    setShowSuggestion(false);
    setInputSearch("");
  });

  const handleSearchData = async (event) => {
    event.preventDefault();
    // It just search with entity and contact
    if (isSuggestion) return;
    // Reset all data
    if (!inputSearch) {
      setUnSelectList(makeUnselectData(totalData));
      return;
    }
    // Call API when it has input search
    setSearchLoading(true);
    try {
      const isEntity = filterLabel.label === QUICK_SELECT_ITEM.entities.label;
      // Call search entity or search contact depend on isEntity variable
      const response = await searchContactApi(
        projectId,
        inputSearch.trim(),
        isEntity
      );
      const dataSearch = totalData.filter((item) =>
        response.data.find((result) => result.toString() === item.value)
      );
      setUnSelectList(makeUnselectData(dataSearch));
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false);
    }
  };

  const onClearSearch = () => {
    if (!isSuggestion) setUnSelectList(makeUnselectData(totalData));
    setInputSearch("");
  };

  useEffect(() => {
    // Reset data selected when isReset change
    if (isReset) {
      setUnSelectList([...selectedList, ...unSelectList]);
      setSelectList([]);
      setInputSearch("");
      setIsNotSelected(false);
    }
  }, [isReset]);

  // Update selected data
  useEffect(() => {
    setSelectList(totalDataSelected);
  }, [JSON.stringify(totalDataSelected)]);

  // Update dataUnSelect data
  useEffect(() => {
    // Load number item first time
    setUnSelectList(totalDataUnSelect);
  }, [JSON.stringify(totalDataUnSelect)]);

  useEffect(() => {
    const popup = document.getElementById(`menu-${filterLabel.label}`);
    const quickFilterContainer = document.getElementById("quick-filter");
    quickFilterContainer.style.height = "100%";
    if (isPopupClipped(popup)) quickFilterContainer.style.height = "100rem";
  }, [showSuggestion]);

  return (
    <div className={styles["filter-item"]}>
      <p className={styles["label"]}>
        {filterLabel.label} (
        {totalDataSelected.length + totalDataUnSelect.length})
      </p>
      <div ref={menuRef} className={styles["data-select"]}>
        <div onClick={() => toggleFilter(!showSuggestion)}>
          <Select
            value={getValueSelected()}
            placeholder={`Select ${placeholder.label}`}
            isSearchable={false}
            components={{ Menu: () => null }}
            styles={{
              dropdownIndicator: (base) => ({ ...base, color: "#222222" }),
            }}
          />
        </div>
        <div
          className={clsx(styles["data-list"])}
          hidden={!showSuggestion}
          id={`menu-${filterLabel.label}`}
        >
          <form onSubmit={handleSearchData}>
            <SearchFilter
              value={inputSearch}
              onChange={(event) => setInputSearch(event.target.value)}
              handleClearSearch={onClearSearch}
              placeholder={`Search ${placeholder.label}`}
              size="sm"
              onFocus={() => dispatch(setCanExecuteHotKey(false))}
              onSearch={handleSearchData}
            />
          </form>
          <FilterList
            data={selectedList}
            onSelect={handleUnSelectData}
            onSelectAll={() => handleSelectAll(false)}
            inputSearch={inputSearch}
            isEmoji={isEmoji}
            isSuggestion={isSuggestion}
            isFormat={QUICK_SELECT_ITEM.identifiers.label === filterLabel.label}
            notSelectedOption={isNotSelected ? {
              label: notSelectedOption.label,
              onSelectCallback: () => {
                handleCustomOptionClick(!isNotSelected);
              },
            } : {}}
            isNotSelected={isNotSelected}
          />
          <FilterList
            data={unSelectList}
            isSelected={false}
            onSelect={handleSelectData}
            onSelectAll={handleSelectAll}
            inputSearch={inputSearch}
            isEmoji={isEmoji}
            disable={disable}
            disableSelectAll={disableSelectAll}
            isSuggestion={isSuggestion}
            searchLoading={searchLoading}
            isFormat={QUICK_SELECT_ITEM.identifiers.label === filterLabel.label}
            notSelectedOption={isNotSelected ? {} : {
              label: notSelectedOption?.label,
              onSelectCallback: () => {
                handleSelectAll(disableSelectAll);
                handleCustomOptionClick(!isNotSelected);
              },
            }}
            isNotSelected={isNotSelected}
          />
        </div>
      </div>
    </div>
  );
};

QuickFilterGroup.propTypes = {
  filterLabel: PropTypes.shape({
    label: PropTypes.string,
    labelShort: PropTypes.string,
  }),
  placeholder: PropTypes.shape({
    label: PropTypes.string,
    labelShort: PropTypes.string,
  }),
  isReset: PropTypes.number,
  disableSelectAll: PropTypes.bool,
  isEmoji: PropTypes.bool,
  disable: PropTypes.bool,
  isSuggestion: PropTypes.bool,
  totalDataSelected: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      sourceType: PropTypes.string,
      hotKey: PropTypes.string,
    })
  ),
  totalDataUnSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      sourceType: PropTypes.string,
      hotKey: PropTypes.string,
    })
  ),
  totalData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      sourceType: PropTypes.string,
      hotKey: PropTypes.string,
    })
  ),
  getDataSelect: PropTypes.func,
  notSelectedOption: PropTypes.shape({
    label: PropTypes.string,
    onSelectCallback: PropTypes.func,
    isSelected: PropTypes.bool,
  })
};

export default QuickFilterGroup;
