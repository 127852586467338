export const LIMIT_FILE = {
  recognition: "image/png, image/jpg, image/jpeg",
};

export const STATUS_UPLOAD = {
  completed: {
    Status: "Completed",
    Message: "Import Completed",
  },
  failed: {
    Status: "ErrorMessage",
    Message: "",
  },
  success: {
    Status: "Finished",
    Message: "",
  },
  uploading: {
    Status: "Loading",
    Message: "Updating file...",
  },
  canceling: {
    Status: "Canceling",
    Message: "Canceling",
    errorMessage: "Import canceled"
  },
  copying: {
    Status: "InfoMessage",
    Message: "InfoMessage",
  },
  process: {
    Status: "ProcessMessage",
    Message: "ProcessMessage",
  }
};
