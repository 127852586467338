import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Col, Container, Row } from "react-bootstrap";
import { camelCase, isEqual } from "lodash";

//Services
import { addSaveSearch } from "services/ISService";
import { getManageSearchDetail } from "services/SavedSearchService";
import { getSelectListProject } from "services/ProjectDetailService";

//Redux
import {
  resetIsData,
  setIsData,
  setOriginalISData,
  setIsShowModalPreview,
  setIsLoadingIntelligent,
  resetStoreSearchResult,
} from "store/ISReducer";
import { resetDateTime, updateDateTime } from "store/DatePickerReducer";
import { setIsMatterDisable } from "store/CommonReducer";
import { resetParamSearchReview } from "store/GeneralReviewReducer";

//Constants
import {
  COMMON_TEXT,
  EVENT_KEY_LISTENER,
  PAGINATION,
  PATH_NAME,
} from "constants/Common";
import {
  DATE_TIME_TYPE,
} from "constants/Constants";
import { SEARCH_SELECTOR } from "constants/SearchSelect";
import { PROJECT_CONSTANT } from "constants/ProjectConstant";
import { LOCAL_STORAGE } from "constants/LocalStorage";
import { SEARCH_MODE } from "constants/IntelligentSearchConstant";
import { breadCrumbIntelligentSearch } from "constants/BreadCrumbConstants";

//Helpers
import {
  formatDateTime,
  getTimeUTC,
  handleTypeFilter,
} from "helpers/DateTimeFormatterHelper";
import { getDataQuery, formatListDataSource } from "helpers/CommonHelper";
import { trimObject } from "helpers/ObjectHelper";

//Components
import QueryTypes from "components/intelligent-search/is-settings/query-types/QueryTypes";
import ConfigSearch from "components/intelligent-search/is-settings/search-config/SearchConfig";
import ConsolidatedQuery from "components/intelligent-search/is-settings/consolidated/consolidated-query/ConsolidatedQuery";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import { Button } from "components/shared/button/Button";
import { Loading } from "components/shared/loading/Loading";
import PopupAddSavedSearch from "components/intelligent-search/saved-search/popup-add-saved-search/PopupAddSavedSearch";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";
import { PreviewSearchModal } from "./preview-search/PreviewSearchModal";

//Styles
import "react-toastify/dist/ReactToastify.css";
import styles from "./IntelligentSearchPage.module.scss";

const IntelligentSearchPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { projectId, searchId } = useParams();
  const dispatch = useDispatch();
  const searchConfigRef = useRef("");

  const [showPopup, setShowPopup] = useState(false);
  const [query, setQuery] = useState("");
  const [queryJSON, setQueryJSON] = useState(null);
  const [isShowConfirmPopup, setIsShowConfirmPopup] = useState(false);
  const [mode, setMode] = useState("");
  const [requestBodyPreview, setRequestBodyPreview] = useState(null);
  const [dataSearchID, setDataSearchID] = useState({});
  const [isShowPreviewModal, setIsShowPreviewModal] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const {
    originalISData,
    isData: iSData,
    isLoading,
  } = useSelector((state) => state.is);
  const {
    globalSearch,
    dataSources,
    entities,
    dataTypes,
    startDate,
    endDate,
  } = iSData;

  const { dateTimeDataStart, dateTimeDataEnd, typeFilter, includeBlankDate } =
    useSelector((state) => state.datePicker.datePicker);
  const dataSourceList = useSelector(state => state.dataSource.dataSourceList);

  //Get intelligent search path from localStorage
  const intelligentSearchPath = localStorage.getItem(LOCAL_STORAGE.intelligentSearchPath);
  const localStorageData =
    JSON.parse(
      localStorage.getItem(`${LOCAL_STORAGE.searchData}${projectId}`)
    ) || "";

  const isHaveDataSearch = !!(
    (globalSearch?.length > 0) ||
    dataSources?.length > 0 ||
    entities?.length > 0 ||
    dataTypes?.length > 0 ||
    startDate ||
    endDate ||
    dateTimeDataStart ||
    dateTimeDataEnd
  );

  const handleBack = () => {
    if (state?.fromScreen)
      navigate(
        `/${PATH_NAME.matters}/${projectId}/${PATH_NAME[camelCase(state.fromScreen)]
        }`
      );
    else navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME.streems}`);
  };

  const executeSearch = () => {
    if (searchId && getSerializedISData(originalISData) !== getSerializedISData(iSData)) {
      setMode(SEARCH_MODE.result);
      setIsShowConfirmPopup(true);
    } else if (searchId)
      navigate(
        `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.intelligentSearch}/${PATH_NAME.searchResult}/${searchId}`,
        {
          state: { fromScreen: `${PATH_NAME.intelligentSearch}/${searchId}` },
        }
      );
    else {
      setMode(SEARCH_MODE.original);
      setIsShowConfirmPopup(true);
    }
    localStorage.setItem(
      `${LOCAL_STORAGE.searchData}${projectId}`,
      JSON.stringify({
        ...iSData,
        endDate: dateTimeDataEnd ? getTimeUTC(dateTimeDataEnd) : null,
        startDate: dateTimeDataStart ? getTimeUTC(dateTimeDataStart) : null,
        typeFilter,
        includeBlankDate: includeBlankDate,
      })
    );
  };

  const getSerializedISData = (obj) => {
    const clonedObj = { ...obj };
    delete clonedObj.queryType;
    const allKeys = [];
    JSON.stringify(clonedObj, (key, value) => {
      allKeys.push(key);
      return value;
    });
    allKeys.sort();
    return JSON.stringify(clonedObj, allKeys);
  }

  const handleReloadPage = () => {
    localStorage.removeItem(LOCAL_STORAGE.searchData + projectId);
    localStorage.removeItem(LOCAL_STORAGE.dataQuery + projectId);
  };

  const fetchManageSearchData = async () => {
    try {
      const dataResult = await getManageSearchDetail(projectId, searchId);
      const data = dataResult.data.object;
      const queryJSON = JSON.parse(data.queryJSON);
      setDataSearchID(data);

      const searchData = {
        dataSources: formatListDataSource(
          dataSourceList,
          data.sources.map((source) => source.dataSourceId.toString())
        ),
        entities:
          data?.entities?.length > 0
            ? data.entities.map((item) => JSON.parse(item).Id)
            : [],
        dataTypes: data.dataTypes,
        startDate: data.startDate,
        endDate: data.endDate,
        globalSearch: queryJSON?.globalSearch ?? [],
        searchID: searchId,
        name: data.name,
        type: data.type,
        includeBlankDate: data.includeBlankDate,
        typeFilter: handleTypeFilter(data.startDate, data.endDate),
      };

      dispatch(setIsData(searchData));
      dispatch(setOriginalISData(searchData));

      // Format to add hours depend on time zone
      dispatch(
        updateDateTime({
          dateTimeDataStart: data.startDate
            ? formatDateTime({
              dateTime: data.startDate,
              type: DATE_TIME_TYPE.ISO,
            })
            : null,
          dateTimeDataEnd: data.endDate
            ? formatDateTime({
              dateTime: data.endDate,
              type: DATE_TIME_TYPE.ISO,
            })
            : null,
          includeBlankDate: data.includeBlankDate,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onValidateSaveSearch = () => {
    updateGlobalSearchQuery();
  };

  let queryTxt = "";
  const updateGlobalSearchQuery = () => {
    let handle = new Promise((resolve) => {
      globalSearch.forEach((rootItem, index) => {
        if (globalSearch[index + 1]?.method === SEARCH_SELECTOR.method.nested)
          queryTxt += "(";
        if (rootItem.method === SEARCH_SELECTOR.method.within)
          getQueryNested(
            rootItem,
            globalSearch[index - 1] ? globalSearch[index - 1].keyword : null
          );
        else if (
          globalSearch[index + 1]?.method === SEARCH_SELECTOR.method.within
        )
          return;
        else getQueryNested(rootItem, null);
        if (rootItem.method === SEARCH_SELECTOR.method.nested && index > 0)
          queryTxt += ")";
      });
      resolve();
    });
    handle.then(() => {
      setQuery(queryTxt.replace(`( ${SEARCH_SELECTOR.condition.and}`, "("));
    });
  };

  const renderOperator = (method) => {
    if (method === SEARCH_SELECTOR.method.and)
      return ` ${SEARCH_SELECTOR.condition.and} `;
    if (method === SEARCH_SELECTOR.method.or)
      return ` ${SEARCH_SELECTOR.condition.or} `;
    if (method === SEARCH_SELECTOR.method.within) return "w/";
  };

  const getQueryNested = (temp, kwWithin = null) => {
    if (!temp.nested) {
      if (!temp.method) return (queryTxt += temp.keyword);
      if (temp.method === SEARCH_SELECTOR.method.within) {
        queryTxt += `${queryTxt ? ` ${SEARCH_SELECTOR.condition.and} ` : ""
          }
        ${kwWithin} ${renderOperator(temp.method)}${temp.withinWords} ${temp.keyword}`;
        return;
      }
      queryTxt += renderOperator(temp.method);
      queryTxt += temp.keyword;
      return;
    }
    if (temp.nestedMethod !== SEARCH_SELECTOR.method.within)
      queryTxt += renderOperator(temp.nestedMethod);
    queryTxt += "(";
    temp.nested.forEach((nestedInside, index) => {
      if (nestedInside.method === SEARCH_SELECTOR.method.within) {
        getQueryNested(
          nestedInside,
          temp.nested[index - 1] ? temp.nested[index - 1].keyword : null
        );
      } else if (
        temp.nested[index + 1]?.method === SEARCH_SELECTOR.method.within
      )
        return;
      else getQueryNested(nestedInside, null);
    });
    queryTxt += ")";
  };

  const updateQueryJSON = () => {
    let queryJSON = {};
    if (globalSearch.length)
      queryJSON = { ...queryJSON, globalSearch: globalSearch };
    setQueryJSON(JSON.stringify(queryJSON));
  };

  const onSaveSearch = async (params) => {
    const data = {
      projectId,
      type: params.isPrivate,
      name: params.searchName,
      entities,
      sources: dataSources,
      dataTypes: dataTypes,
      startDate: dateTimeDataStart,
      endDate: dateTimeDataEnd,
      query: getDataQuery(0, query) || "",
      queryJSON,
      isUpdate: !!searchId,
      includeBlankDate,
      searchId: searchId || 0,
    };
    setShowPopup(false);
    dispatch(setIsLoadingIntelligent(true));
    localStorage.setItem(`${LOCAL_STORAGE.dataQuery}${projectId}`, "");
    localStorage.setItem(
      `${LOCAL_STORAGE.searchData}${projectId}`,
      JSON.stringify({
        ...iSData,
        endDate: dateTimeDataEnd ? getTimeUTC(dateTimeDataEnd) : null,
        startDate: dateTimeDataStart ? getTimeUTC(dateTimeDataStart) : null,
        typeFilter,
        includeBlankDate,
      })
    );
    try {
      const res = await addSaveSearch(trimObject(data));
      // Clear store search result before redirect to intelligent search result screen
      dispatch(resetStoreSearchResult());
      if (mode === SEARCH_MODE.result) {
        navigate(
          `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.intelligentSearch}/${PATH_NAME.searchResult}/${searchId}`,
          {
            state: { fromScreen: `${PATH_NAME.intelligentSearch}/${searchId}` },
          }
        );
        dispatch(setOriginalISData(localStorageData));
      } else if (mode === SEARCH_MODE.original) {
        navigate(
          `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.intelligentSearch}/${PATH_NAME.searchResult}/${res.data}`,
          {
            state: { fromScreen: `${PATH_NAME.intelligentSearch}/${res.data}` },
          }
        );
        dispatch(setOriginalISData(localStorageData));
      } else {
        toast.success(res.data.message);
        localStorage.setItem(
          LOCAL_STORAGE.intelligentSearchPath,
          PATH_NAME.intelligentSearch
        );
        navigate(
          `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.savedSearches}`,
          {
            state: { fromScreen: PATH_NAME.intelligentSearch, searchId },
          }
        );
      }
    } catch (err) {
      console.log(err);
      toast.error("Save search failed");
    } finally {
      setDataSearchID({});
      dispatch(setIsLoadingIntelligent(false));
    }
  };

  const onPreviewSearch = () => {
    const data = {
      searchInput: query,
      dataSources: dataSources,
      entities,
      dataTypes: dataTypes,
      start: dateTimeDataStart,
      end: dateTimeDataEnd,
      paginationParams: {
        pageNumber: PAGINATION.pageNumberDefault,
        pageSize: PAGINATION.recordPerPageTable,
      },
      includeBlankDate,
    };
    setRequestBodyPreview(data);
    dispatch(setIsShowModalPreview(true));
    setIsShowPreviewModal(true);
  };

  const handleClearSearch = () => {
    dispatch(resetIsData());
    dispatch(resetDateTime());
    setIsShowConfirmPopup(false);
    setQuery("");
  };

  const handleSubmitPopup = () => {
    if (mode === SEARCH_MODE.clear) {
      handleClearSearch();
      searchConfigRef.current.handleClearSearch();
      return;
    }
    setIsShowConfirmPopup(false);
    setShowPopup(true);
  };

  const handleNotSaveRunSearch = () => {
    localStorage.setItem(`${LOCAL_STORAGE.dataQuery}${projectId}`, query);
    navigate(
      `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.intelligentSearch}/${PATH_NAME.searchResult}`,
      {
        state: { fromScreen: PATH_NAME.intelligentSearch },
      }
    );
    dispatch(resetStoreSearchResult());
  }

  const handleClosePreviewModal = () => {
    dispatch(setIsShowModalPreview(false));
  };

  const handleDateTime = () => {
    const { startDate, endDate } = localStorageData;
    let isIncludeBlankDate = includeBlankDate;
    if (
      localStorageData &&
      localStorageData.includeBlankDate !== null &&
      localStorageData.includeBlankDate !== undefined
    )
      isIncludeBlankDate = localStorageData.includeBlankDate;
    dispatch(
      updateDateTime({
        dateTimeDataStart: startDate
          ? formatDateTime({ dateTime: startDate, type: DATE_TIME_TYPE.ISO })
          : null,
        dateTimeDataEnd: endDate
          ? formatDateTime({ dateTime: endDate, type: DATE_TIME_TYPE.ISO })
          : null,
        typeFilter: localStorageData?.typeFilter || 0,
        includeBlankDate: isIncludeBlankDate,
      })
    );
  };

  const getProjectList = async () => {
    try {
      const dataResult = await getSelectListProject();
      dataResult.data.forEach((item) => {
        if (item?.projectId?.toString() !== projectId?.toString()) {
          localStorage.removeItem(
            `${LOCAL_STORAGE.searchData}${item.projectId}`
          );
          localStorage.removeItem(
            `${LOCAL_STORAGE.dataQuery}${item.projectId}`
          );
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(
      updateDateTime({
        typeFilter: handleTypeFilter(dateTimeDataStart, dateTimeDataEnd),
      })
    );
  }, [dateTimeDataStart, dateTimeDataEnd]);

  useEffect(() => {
    onValidateSaveSearch();
    updateQueryJSON();
  }, [globalSearch, localStorageData]);

  useEffect(() => {
    if (!firstLoad) {
      handleClearSearch();
      dispatch(resetParamSearchReview());
    }
    dispatch(resetStoreSearchResult());
  }, [projectId]);

  useEffect(() => {
    getProjectList();
    localStorage.setItem(PROJECT_CONSTANT.projectId, projectId);
    if (!searchId) {
      dispatch(setIsData(localStorageData));
    } else {
      dispatch(setIsMatterDisable(true));
      fetchManageSearchData();
    }
    handleDateTime();
    handleReloadPage();
    setFirstLoad(false);
    return () => {
      dispatch(setIsMatterDisable(false));
      handleClearSearch();
      window.removeEventListener(EVENT_KEY_LISTENER.beforeunload, () =>
        handleReloadPage()
      );
    };
  }, []);

  return (
    <Container fluid>
      {isShowPreviewModal && (
        <PreviewSearchModal
          isShow={isShowPreviewModal}
          handleClose={handleClosePreviewModal}
          setIsShowPreviewModal={setIsShowPreviewModal}
          requestBody={requestBodyPreview}
          handleSubmit={executeSearch}
        />
      )}
      <PopupConfirm
        isShow={isShowConfirmPopup}
        textReject={
          [SEARCH_MODE.result, SEARCH_MODE.original].includes(mode)
            ? COMMON_TEXT.no
            : ""
        }
        handleReject={handleNotSaveRunSearch}
        handleClose={() => setIsShowConfirmPopup(false)}
        handleSubmit={handleSubmitPopup}
        content={
          mode === SEARCH_MODE.clear
            ? "Are you sure you want to clear this search?"
            : "You haven't saved this search yet. Do you want to save?"
        }
        textConfirm={mode === SEARCH_MODE.clear ? "Clear" : COMMON_TEXT.yes}
        type={mode === SEARCH_MODE.clear ? "remove" : "confirm"}
      />
      <Loading loading={isLoading} isBlur />
      {showPopup && (
        <PopupAddSavedSearch
          handleClose={() => setShowPopup(false)}
          isShow={showPopup}
          handleSubmit={onSaveSearch}
          isUpdate={!!searchId}
          data={dataSearchID}
          mode={mode}
          searchId={searchId}
        />
      )}
      <Row className={clsx("main", styles["intelligent-search"])}>
        <Col className="h-100">
          <div>
            <BreadCrumb
              goBack={handleBack}
              breadCrumbData={breadCrumbIntelligentSearch(
                intelligentSearchPath !== PATH_NAME.intelligentSearch
                  ? intelligentSearchPath
                  : state?.fromScreen
              )}
            />
            <div className={styles["is-header"]}>
              <h2 className={clsx("page-title", styles["is-title"])}>
                Intelligent Search
              </h2>
              <div className={styles["is-buttons"]}>
                <Button
                  name="Clear Search"
                  isDisabled={!isHaveDataSearch}
                  className={"btn-primary"}
                  handleClick={() => {
                    setMode(SEARCH_MODE.clear);
                    setIsShowConfirmPopup(true);
                  }}
                />
                <Button
                  name="Save Search"
                  className={"btn-primary"}
                  handleClick={() => {
                    setMode("saveSearch");
                    setShowPopup(true);
                  }}
                  isDisabled={
                    !isHaveDataSearch ||
                    (searchId && isEqual(originalISData, iSData))
                  }
                />
                <Button
                  name="Preview Search"
                  className={"btn-primary"}
                  isDisabled={!isHaveDataSearch}
                  handleClick={onPreviewSearch}
                />
                <Button
                  handleClick={executeSearch}
                  name="Execute Search"
                  className={"btn-primary-fill"}
                  isDisabled={!isHaveDataSearch}
                />
              </div>
            </div>
          </div>

          <Row className={styles["is-content"]}>
            <Col
              md={6}
              sm={6}
              lg={3}
              className={styles["is-content__col-query"]}
            >
              <div className={styles["is-col-query"]}>
                <QueryTypes />
              </div>
            </Col>
            <Col md={6} sm={6} lg={4} className={styles["is-content__col"]}>
              <div className={styles["is-col"]}>
                <ConfigSearch ref={searchConfigRef} />
              </div>
            </Col>
            <Col md={12} sm={12} lg={5} className={styles["is-content__col"]}>
              <div className={styles["is-col"]}>
                <ConsolidatedQuery />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default IntelligentSearchPage;
