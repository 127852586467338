import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import { Button } from "components/shared/button/Button";
import TagModal from "components/tag-management/tag-modal/TagModal";
import MenuSelectTag from "../menu-select-tag/MenuSelectTag";

// Constants and Helpers
import { COMMON_TEXT, EXPORT_TAG, TYPE_CLICK } from "constants/Common";
import { TAG_ACTION } from "constants/Constants";

// Store
import { setCanExecuteHotKey } from "store/TagReducer";

// Styles
import styles from "./TagExecuteMultiItems.module.scss";

const TagExecuteMultiItems = (props) => {
  const {
    isLoadingData = false,
    isShow,
    disableSelectButton,
    handleBtnCancelSelect,
    disableTagButton,
    disableUnTagButton,
    handleSaveSelectTags,
    isSelectedAll,
    handleUnselectAll,
    handleSelectAll,
    isDisabledPrev,
    isDisabledNext,
    onSwitchHandle,
    handleIsShow,
    handleIsAddTag,
    handleShowFirstOrLastMsg = () => {},
    setTypeClick = () => {},
    isShowThreadNumber = false,
    isShowGoFirstLast = false,
    threadNumber = "",
    isDisabledGoFirst = true,
    isDisabledGoLast = true,
    isShowChangeOption = true,
    disableTag = false,
  } = props;

  const dispatch = useDispatch();

  const [tagSelected, setTagSelected] = useState([]);
  const [unTagSelected, setUnTagSelected] = useState([]);
  const [showAddTagTab, setShowAddTagTab] = useState(false);
  const [isShowMenuTag, setIsShowMenuTag] = useState(false);
  const [isShowMenuUnTag, setIsShowMenuUntag] = useState(false);

  const { tags = [], isExecutingTag } = useSelector((state) => state.tag);

  // Not allow showing tag For Export at the Tag option
  const selectTags = tags.selectTags.filter(
    (tag) => tag.tagID !== EXPORT_TAG.id
  );

  const handleSelectTag = (event, tagID) => {
    const { checked } = event.currentTarget;
    setTagSelected((prev) =>
      checked ? [...prev, tagID] : prev.filter((val) => val !== tagID)
    );
  };

  const handleSelectUnTag = (event, tagID) => {
    const { checked } = event.currentTarget;
    setUnTagSelected((prev) =>
      checked ? [...prev, tagID] : prev.filter((val) => val !== tagID)
    );
  };

  const handleClickCancel = () => {
    handleIsShow(false);
    setTagSelected([]);
    handleBtnCancelSelect();
  };

  const handleSwitchItem = (type) => onSwitchHandle(type);

  const handleClickFirstOrLastMessage = (type) => {
    handleShowFirstOrLastMsg(type);
    setTypeClick(type);
  };

  useEffect(() => handleIsAddTag(showAddTagTab), [showAddTagTab]);

  useEffect(() => {
    if (isShow) return;
    setTagSelected([]);
    setUnTagSelected([]);
  }, [isShow]);

  useEffect(() => {
    dispatch(setCanExecuteHotKey(!showAddTagTab));
  }, [showAddTagTab]);

  useEffect(() => {
    if (!isLoadingData) return;
    setUnTagSelected([]);
    // handleClickCancel();
  }, [isLoadingData]);

  useEffect(() => {
    setTagSelected([]);
    setUnTagSelected([]);
  }, [isShowMenuTag, isShowMenuUnTag]);

  useEffect(() => {
    setIsShowMenuTag(false);
    setIsShowMenuUntag(false);
  }, [isExecutingTag]);

  return (
    <>
      {!isShow ? (
        <button
          type="button"
          onClick={() => handleIsShow(true)}
          disabled={disableSelectButton || isExecutingTag}
          className={clsx(
            styles["btn"],
            styles["btn-select"],
            disableSelectButton || isExecutingTag ? styles["disable"] : ""
          )}
        >
          <span className={styles["tooltip"]}>Select items for tagging</span>
          Select
        </button>
      ) : (
        !disableTag && (
          <>
            <button
              hidden
              type="button"
              onClick={handleClickCancel}
              className={clsx(
                "btn-primary mar_r16",
                styles["btn"],
                styles["btn-select-cancel"]
              )}
            >
              Cancel
            </button>
            <Dropdown
              className="dropup"
              onToggle={() => {
                setShowAddTagTab(false);
                setIsShowMenuTag(!isShowMenuTag);
              }}
              show={isShowMenuTag}
            >
              <Dropdown.Toggle
                type="button"
                className={clsx(
                  "mar_r16",
                  styles["btn"],
                  styles["btn-select-tag"],
                  !disableTagButton && styles["active"]
                )}
                id="dropdown-basic"
                disabled={disableTagButton || isExecutingTag}
              >
                Tag
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={clsx(
                  styles["dropdown-menu"],
                  showAddTagTab && styles["dropdown-add-tag"]
                )}
              >
                {showAddTagTab ? (
                  <TagModal
                    handleClose={() => setShowAddTagTab(false)}
                    isShow={showAddTagTab}
                    type={COMMON_TEXT.add}
                  />
                ) : (
                  <>
                    <div className={styles["menu-header"]}>
                      <p className={styles["label-title"]}>Select Tags</p>
                      <span
                        className={clsx(
                          styles["add-hot-tag"],
                          isExecutingTag ? styles["disabled"] : ""
                        )}
                        onClick={() => setShowAddTagTab(true)}
                      >
                        + Add
                      </span>
                    </div>
                    <MenuSelectTag
                      tagList={selectTags}
                      checkedList={tagSelected}
                      onChange={handleSelectTag}
                      disabled={isExecutingTag}
                    />
                    <div
                      className={clsx(
                        "col d-flex justify-content-end mb-3 pt-3",
                        styles["dropdown-menu-action"]
                      )}
                    >
                      <Dropdown.Item className={styles["dropdown-item"]}>
                        <Button
                          name="Cancel"
                          handleClick={() => setTagSelected([])}
                          className="btn-secondary-md"
                        />
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles["dropdown-item"]}
                        disabled={!tagSelected?.length}
                      >
                        <Button
                          handleClick={() =>
                            handleSaveSelectTags(tagSelected, TAG_ACTION.tag)
                          }
                          isDisabled={!tagSelected?.length || isExecutingTag}
                          className="btn-secondary-md"
                          isBorder={false}
                          name="Save"
                        />
                      </Dropdown.Item>
                    </div>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              className="dropup"
              onToggle={() => setIsShowMenuUntag(!isShowMenuUnTag)}
              show={isShowMenuUnTag}
            >
              <Dropdown.Toggle
                type="button"
                className={clsx(
                  "mar_r16",
                  styles["btn"],
                  styles["btn-select-tag"],
                  !disableUnTagButton && styles["active"]
                )}
                id="dropdown-basic2"
                disabled={disableUnTagButton || isExecutingTag}
              >
                Untag
              </Dropdown.Toggle>
              <Dropdown.Menu
                className={clsx(
                  styles["dropdown-menu"],
                  styles["dropdown-menu-tag"]
                )}
              >
                <>
                  <div className={styles["menu-header"]}>
                    <p className={styles["label-title"]}>Select Tags</p>
                  </div>
                  <MenuSelectTag
                    tagList={selectTags}
                    checkedList={unTagSelected}
                    onChange={handleSelectUnTag}
                    disabled={isExecutingTag}
                  />
                  <div
                    className={clsx(
                      "col d-flex justify-content-end mb-3 pt-3",
                      styles["dropdown-menu-action"]
                    )}
                  >
                    <Dropdown.Item className={styles["dropdown-item"]}>
                      <Button
                        name="Cancel"
                        handleClick={() => setUnTagSelected([])}
                        className="btn-secondary-md"
                      />
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown-item"]}
                      disabled={!unTagSelected?.length}
                    >
                      <Button
                        handleClick={() =>
                          handleSaveSelectTags(unTagSelected, TAG_ACTION.unTag)
                        }
                        isDisabled={!unTagSelected?.length}
                        className="btn-secondary-md"
                        isBorder={false}
                        name="Untag"
                      />
                    </Dropdown.Item>
                  </div>
                </>
              </Dropdown.Menu>
            </Dropdown>
            <button
              type="button"
              className={clsx(
                "mar_r16",
                styles["btn"],
                styles["btn-select-tagall"],
                styles["active"],
                isExecutingTag || isLoadingData ? styles["disable"] : ""
              )}
              onClick={isSelectedAll ? handleUnselectAll : handleSelectAll}
              disabled={isExecutingTag || isLoadingData}
            >
              {isSelectedAll ? "Unselect All" : "Select All"}
            </button>
          </>
        )
      )}
      <div className={styles["btn-change-message"]}>
        {isShowChangeOption && (
          <>
            <button
              type="button"
              className={clsx(
                "mar_r10",
                styles["btn"],
                styles["btn-message-prev"]
              )}
              disabled={isDisabledPrev}
              onClick={() => handleSwitchItem(TYPE_CLICK.previous)}
            >
              <img
                src={`/images/right_move_ic${
                  isDisabledPrev ? "-disabled" : ""
                }.svg`}
                alt={TYPE_CLICK.previous}
              />
            </button>
            {isShowGoFirstLast && (
              <button
                type="button"
                className={clsx(
                  "mar_r10",
                  styles["btn"],
                  styles["btn-message-prev"]
                )}
                disabled={isDisabledGoFirst}
                onClick={() => handleClickFirstOrLastMessage(TYPE_CLICK.first)}
              >
                <img
                  src={`/images/icons/go-first${
                    isDisabledGoFirst ? "-disabled" : ""
                  }.svg`}
                  alt={TYPE_CLICK.first}
                />
              </button>
            )}
            {isShowThreadNumber && (
              <div className={styles["conversation-number"]}>
                <p>{threadNumber}</p>
              </div>
            )}
            {isShowGoFirstLast && (
              <button
                type="button"
                className={clsx(
                  "mar_r10",
                  styles["btn"],
                  styles["btn-message-next"]
                )}
                disabled={isDisabledGoLast}
                onClick={() => handleClickFirstOrLastMessage(TYPE_CLICK.last)}
              >
                <img
                  src={`/images/icons/go-last${
                    isDisabledGoLast ? "-disabled" : ""
                  }.svg`}
                  alt={TYPE_CLICK.last}
                />
              </button>
            )}
            <button
              type="button"
              className={clsx(styles["btn"], styles["btn-message-next"])}
              disabled={isDisabledNext}
              onClick={() => handleSwitchItem(TYPE_CLICK.next)}
            >
              <img
                src={`/images/left_move_ic${
                  isDisabledNext ? "-disabled" : ""
                }.svg`}
                alt={TYPE_CLICK.next}
              />
            </button>
          </>
        )}
      </div>
    </>
  );
};

TagExecuteMultiItems.propTypes = {
  isLoadingData: PropTypes.bool,
  disableSelectButton: PropTypes.bool,
  disableTagButton: PropTypes.bool,
  disableUnTagButton: PropTypes.bool,
  isSelectedAll: PropTypes.bool,
  isDisabledPrev: PropTypes.bool,
  isDisabledNext: PropTypes.bool,
  isDisabledGoFirst: PropTypes.bool,
  isDisabledGoLast: PropTypes.bool,
  isShow: PropTypes.bool,
  isShowThreadNumber: PropTypes.bool,
  isShowGoFirstLast: PropTypes.bool,
  isShowChangeOption: PropTypes.bool,
  disableTag: PropTypes.bool,
  threadNumber: PropTypes.string,
  handleBtnCancelSelect: PropTypes.func,
  handleSaveSelectTags: PropTypes.func,
  handleUnselectAll: PropTypes.func,
  handleSelectAll: PropTypes.func,
  onSwitchHandle: PropTypes.func,
  handleIsShow: PropTypes.func,
  handleIsAddTag: PropTypes.func,
  handleShowFirstOrLastMsg: PropTypes.func,
  setTypeClick: PropTypes.func,
};

export default TagExecuteMultiItems;
