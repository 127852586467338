import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useToken } from "hook/auth";
import { toast } from 'react-toastify';
import * as signalR from '@microsoft/signalr';

// Services
import { subscribeToMatter, unsubscribeFromMatter } from 'services/NotificationService';

// Constants
import { 
    SIGNALR_CLIENT_FUNCTIONS,
    TOAST_MESSAGE_INDEX_REBUILD_STARTED,
    TOAST_MESSAGE_INDEX_REBUILD_DONE,
    TOAST_MESSAGE_IMPORT } from 'constants/Common';
import { STATUS_UPLOAD } from 'constants/UploadConstant';
import { AUTH_CONSTANT } from 'constants/AuthConstant';
import { MASS_TAG_TOAST_MSG } from 'constants/MassTagConstants';
import { 
    GetFilterTagToastId,
    FILTER_TAG_TOAST_MSG 
} from 'constants/FilterTagConstants';
import { 
    GetRunSearchTermsToastId,
    RUN_SEARCH_TERMS_TOAST_MSG 
} from 'constants/SearchTermsConstants';

// Stores
import { updateMatterSubscription, updateNotifHubConnection } from 'store/NotificationReducer';
import { setExportNotification } from 'store/ExportManagementReducer';
import { setImportLog } from 'store/DataSourceReducer';
import { GetMassTagToastId } from 'constants/MassTagConstants';
import { setIsError } from "store/FilterTagReducer";
import { setIsExecutingTag } from "store/TagReducer";
import { setSearchTermsRunning } from "store/SearchTermsReducer";

const MatterNotification = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { token } = useToken();

    const { notifHubConnection, matterSubscription } = useSelector((state) => state.notification);

    useEffect(() => {
        const createNotifHubConnection = async (stateDispatch) => {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_BASE_URL}/notificationhub`, {
                    accessTokenFactory: () => token,
                })
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: (retryContext) => {
                        return 5000; // retry every 5 seconds (each try lasts 30 seconds)
                    }
                })
                .build();

            connection.onreconnected((connectionId) => {
                console.log("Notif connection hub re-connected. Connected with ID:", connectionId);
                stateDispatch(updateMatterSubscription(matterSubscription));
            });

            try {
                await connection.start();
                registerNotifHubFunctions(connection, stateDispatch);
                stateDispatch(updateNotifHubConnection(connection));
            }
            catch (err) {
                console.log("Notification hub connection failed:", err);
            }
        };

        const registerNotifHubFunctions = (connection, stateDispatch) => {
            // MatterIndexRebuildStarted
            connection.on(SIGNALR_CLIENT_FUNCTIONS.MatterIndexRebuildStarted, () => {
                toast.warn(TOAST_MESSAGE_INDEX_REBUILD_STARTED, {
                    autoClose: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    progress: undefined,
                    
                    theme: "light",
                });
            });

            // MatterIndexRebuildDone
            connection.on(SIGNALR_CLIENT_FUNCTIONS.MatterIndexRebuildDone, () => {
                toast.success(TOAST_MESSAGE_INDEX_REBUILD_DONE, {
                    autoClose: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    progress: undefined,
                    theme: "light",
                });
            });

            // ImportNotification
            connection.on(SIGNALR_CLIENT_FUNCTIONS.ImportNotification, (message) => {
                // update new import to data source
                const newImportLogState = {
                    messageLog: {
                        Message: message?.message,
                        TimeStamp: message?.timeStamp,
                    },
                    statusLog: message?.status,
                    dataSourceId: message?.dataSourceId,
                    userId: message?.userId,
                    importProcess:
                        message?.status === STATUS_UPLOAD.process.Status
                            ? Number(message.message)
                            : "",
                };
                stateDispatch(setImportLog(newImportLogState));

                // get userId from local storage
                const user = JSON.parse(localStorage.getItem(AUTH_CONSTANT.userInfo)) || "";

                if (message.UserId !== user.id) return;

                // Show toast message when import finished
                if (message?.Status === STATUS_UPLOAD.success.Status)
                    toast.success(TOAST_MESSAGE_IMPORT.success);
                else if (message?.Status === STATUS_UPLOAD.failed.Status)
                    toast.error(TOAST_MESSAGE_IMPORT.failed);
            });

            // ExportNotification
            connection.on(SIGNALR_CLIENT_FUNCTIONS.ExportNotification, (message) => {
                stateDispatch(
                    setExportNotification({
                        exportId: message?.exportId,
                        exportStatus: message?.exportStatus,
                    })
                )
            });

            // MassTagCompleted
            connection.on(SIGNALR_CLIENT_FUNCTIONS.MassTagCompleted, (jobId, taggedCount, wasUntag, successful) => {
                if (successful) {
                    const msg = wasUntag 
                        ? MASS_TAG_TOAST_MSG.UNTAG_SUCCESS(taggedCount)
                        : MASS_TAG_TOAST_MSG.TAG_SUCCESS(taggedCount);
                    toast.update(GetMassTagToastId(jobId), {
                        type: toast.TYPE.SUCCESS,
                        render: msg,
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        isLoading: false
                    });
                } else {
                    toast.update(GetMassTagToastId(jobId), {
                        type: toast.TYPE.ERROR,
                        render: MASS_TAG_TOAST_MSG.APPLY_ERR,
                        autoClose: false,
                        closeOnClick: true,
                        isLoading: false
                    });
                }
            });

            // FilterTagCompleted
            connection.on(SIGNALR_CLIENT_FUNCTIONS.FilterTagCompleted, (jobId, taggedCount, wasUntag, successful) => {
                if (successful) {
                    const msg = wasUntag 
                        ? FILTER_TAG_TOAST_MSG.UNTAG_SUCCESS(taggedCount)
                        : FILTER_TAG_TOAST_MSG.TAG_SUCCESS(taggedCount);
                    toast.update(GetFilterTagToastId(jobId), {
                        type: toast.TYPE.SUCCESS,
                        render: msg,
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        isLoading: false
                    });
                } else {
                    stateDispatch(setIsError(true));
                    toast.update(GetFilterTagToastId(jobId), {
                        type: toast.TYPE.ERROR,
                        render: FILTER_TAG_TOAST_MSG.APPLY_ERR,
                        autoClose: false,
                        closeOnClick: true,
                        isLoading: false
                    });
                }

                stateDispatch(setIsExecutingTag(false));
            });

            // RunSearchTermsCompleted
            connection.on(SIGNALR_CLIENT_FUNCTIONS.RunSearchTermsCompleted, (jobId, taggedCount, untaggedCount, successful) => {
                if (successful) {
                    const msg = RUN_SEARCH_TERMS_TOAST_MSG.RUN_SEARCH_TERMS_SUCCESS(taggedCount, untaggedCount);
                    toast.update(GetRunSearchTermsToastId(jobId), {
                        type: toast.TYPE.SUCCESS,
                        render: msg,
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        isLoading: false
                    });
                } else {
                    toast.update(GetRunSearchTermsToastId(jobId), {
                        type: toast.TYPE.ERROR,
                        render: FILTER_TAG_TOAST_MSG.APPLY_ERR,
                        autoClose: false,
                        closeOnClick: true,
                        isLoading: false
                    });
                }

                stateDispatch(setSearchTermsRunning(false));
            });
        };

        if (notifHubConnection === null && token) {
            createNotifHubConnection(dispatch);
        }
    }, [dispatch]);

    useEffect(() => {
        const handleMatterSubscriptionChanged = async () => {
            if (matterSubscription.lastId !== 0) {
                try {
                    await unsubscribeFromMatter(notifHubConnection, matterSubscription.lastId);
                } catch (err) {
                    console.log(`An error occurred while unsubscribing from the matter: ${matterSubscription.lastId}`, err);
                }
            }
            if (matterSubscription.currentId !== 0) {
                try {
                    await subscribeToMatter(notifHubConnection, matterSubscription.currentId);
                } catch (err) {
                    console.log(`An error occurred while subscribing to the matter: ${matterSubscription.currentId}`, err);
                }
            }
        };

        if (notifHubConnection !== null) {
            handleMatterSubscriptionChanged();
        }
    }, [matterSubscription, notifHubConnection]);

    useEffect(() => {
        const changeMatterSubscription = async () => {
            const regex = /matters\/(\d+)/;
            const matches = pathname.match(regex);
            if (matches && matches.length > 0) {
                const matterId = Number(matches[1]);
                if (matterSubscription.currentId !== matterId) {
                    dispatch(updateMatterSubscription({ currentId: matterId, lastId: matterSubscription.currentId }));
                }
            } else {
                dispatch(updateMatterSubscription({ currentId: 0, lastId: matterSubscription.currentId ?? 0 }));
            }
        };

        changeMatterSubscription();
    }, [pathname]);

    return (<></>);
};

export default MatterNotification;