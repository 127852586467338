import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "hook/click-outside";

// Styles
import styles from "./ActionMenu.module.scss";

const ActionMenu = ({ options }) => {
  const actionRef = useRef(null);
  const [showActionList, setShowActionList] = useState(false);

  useOnClickOutside(actionRef, () => setShowActionList(false));

  return (
    <div className={styles["wrap"]}>
      <img
        onClick={() => setShowActionList(true)}
        src="/images/menu-control.svg"
        alt="menu-action"
      />
      <ul ref={actionRef} hidden={!showActionList}>
        {options.map((item) => (
          <li key={item.name} onClick={item.handleAction}>
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

ActionMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      handleAction: PropTypes.func,
    })
  ),
};

export default ActionMenu;
