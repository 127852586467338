const UserProfileValidation = {
  avatar: {
    required: "Image file is required.",
    file_size: "Image file should be less than or equal to 20MB.",
    file_type:
      "Please upload an image file with one of the following extensions: *jpeg, *jpg, *png.",
  },
  userName: {
    required: "Login name is required.",
    max_length: "Login name must be less than or equal to 100 characters.",
  },
  firstName: {
    required: "First Name is required.",
    max_length: "First Name must be less than or equal to 100 characters.",
  },
  lastName: {
    required: "Last Name is required.",
    max_length: "Last Name must be less than or equal to 100 characters.",
  },
  email: {
    required: "Email Address is required.",
    invalid: "Please enter a valid email address.",
    max_length: "Email Address must be less than or equal to 100 characters.",
    exists: "Email Address has already exists.",
  },
  phoneNumber: {
    required: "Phone number is required.",
    invalid: "Please enter a valid phone number.",
    length: "Phone number must be between 9 - 20 numeric characters.",
    exists: "Phone number already exists.",
  },
  agree: {
    required: "Please accept the Terms of Use & Privacy Policy to create new user.",
  },
};

export { UserProfileValidation };
