import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import LooseFileInfoContainer from "../loose-file-info-container/LooseFileInfoContainer";

// Constants and Helpers
import { TIMELINE_INFORMATION } from "constants/DataType";
import { PARAMETERS } from "constants/Common";

const InfoVideo = ({ videoInfo, duration }) => {
  const [calculatedDuration, setCalculatedDuration] = useState();
  const [infoResultList, setInfoResultList] = useState([]);

  useEffect(() => {
    if (duration) setCalculatedDuration(duration);

    setInfoResultList(TIMELINE_INFORMATION.video);
    const durationIndex  = infoResultList.indexOf((item) => item.key === PARAMETERS.duration);
    if (durationIndex !== -1) {
      infoResultList[durationIndex].value = calculatedDuration;
    }
  }, [duration]);

  return (
    <LooseFileInfoContainer
      infoResultLabelList={infoResultList}
      dataType={videoInfo[PARAMETERS.type]}
      itemInfo={videoInfo}    
    />
  );
};

InfoVideo.propTypes = {
  duration: PropTypes.string,
  videoInfo: PropTypes.object,
};

export default InfoVideo;
