import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Constants
import { PATHNAME_ITEMS } from "constants/Common";

// Helper
import { keepHtmlEntity } from "helpers/CommonHelper";

// Components
import { Button } from "../button/Button";

// Styles
import "./styles.scss";

const ErrorResult = (props) => {
  const {
    status,
    content,
    contentHTML,
    imgErr,
    textHyperLink,
    hasButton = false,
  } = props;

  const handleReloadPage = () => window.location.reload();

  return (
    <div className="error-result">
      <div className="error-result-body">
        <div className="error-result-body__img">
          <img src={imgErr} alt="Error" />
        </div>
        <div>
          <h1 className="error-result-body__title">{status}</h1>
          {contentHTML ? (
            <p
              className="error-result-body__content"
              dangerouslySetInnerHTML={{
                __html: keepHtmlEntity(contentHTML),
              }}
            ></p>
          ) : (
            <p className="error-result-body__content">{content}</p>
          )}
          <Link
            className="error-result-body__link"
            to={PATHNAME_ITEMS.resetPassword}
          >
            {textHyperLink}
          </Link>
          {hasButton && (
            <Button
              className="btn-medium-blue"
              handleClick={handleReloadPage}
              name="Reload"
            />
          )}
        </div>
      </div>
    </div>
  );
};

ErrorResult.propTypes = {
  status: PropTypes.string,
  content: PropTypes.string,
  contentHTML: PropTypes.string,
  imgErr: PropTypes.string,
  textHyperLink: PropTypes.string,
  hasButton: PropTypes.bool,
};

export default ErrorResult;
