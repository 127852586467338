import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import { Button } from "components/shared/button/Button";
import TagModal from "components/tag-management/tag-modal/TagModal";
import MenuSelectTag from "../menu-select-tag/MenuSelectTag";

// Constants and Helpers
import {
  COMMON_TEXT,
  EXPORT_TAG,
  TYPE_CLICK,
} from "constants/Common";
import { TAG_ACTION } from "constants/Constants";

// Styles
import styles from "./TagExecuteSingleItem.module.scss";

const TagExecuteSingleItem = (props) => {
  const {
    isReset,
    disableTagButton,
    disableUnTagButton,
    handleSaveSelectTags,
    isDisabledPrev,
    isDisabledNext,
    onSwitchHandle,
    handleIsAddTag,
    isShowTag,
    isShowThreadNumber = false,
    threadNumber = 1,
    isShowChangeOption = true,
  } = props;

  const [tagSelected, setTagSelected] = useState([]);
  const [unTagSelected, setUnTagSelected] = useState([]);
  const [showAddTagTab, setShowAddTagTab] = useState(false);
  const [isShowMenuTag, setIsShowMenuTag] = useState(false);
  const [isShowMenuUnTag, setIsShowMenuUntag] = useState(false);

  const { tags, isExecutingTag } = useSelector((state) => state.tag);

  // Not allow showing tag For Export at the Tag option
  const selectTags = tags.selectTags.filter(
    (tag) => tag.tagID !== EXPORT_TAG.id
  );

  const handleSelectTag = (event, tagID) => {
    const { checked } = event.currentTarget;
    setTagSelected((prev) =>
      checked ? [...prev, tagID] : prev.filter((val) => val !== tagID)
    );
  };

  const handleSelectUnTag = (event, tagID) => {
    const { checked } = event.currentTarget;
    setUnTagSelected((prev) =>
      checked ? [...prev, tagID] : prev.filter((val) => val !== tagID)
    );
  };

  const onToggleTag = () => {
    setShowAddTagTab(false);
    setIsShowMenuTag(!isShowMenuTag);
  };

  useEffect(() => handleIsAddTag(showAddTagTab), [showAddTagTab]);

  useEffect(() => {
    setTagSelected([]);
    setUnTagSelected([]);
    setIsShowMenuTag(false);
    setIsShowMenuUntag(false);
  }, [isReset]);

  useEffect(() => {
    setTagSelected([]);
    setUnTagSelected([]);
  }, [isShowMenuTag, isShowMenuUnTag]);

  return (
    <div className={clsx(styles["tag-execute-single"])}>
      {isShowTag && (
        <>
          <Dropdown
            className="dropup"
            onToggle={() => onToggleTag()}
            show={isShowMenuTag}
          >
            <Dropdown.Toggle
              type="button"
              className={clsx(
                "mar_r16",
                styles["btn"],
                styles["btn-select-tag"],
                !disableTagButton && styles["active"]
              )}
              id="dropdown-basic"
              disabled={disableTagButton || isExecutingTag}
            >
              Tag
            </Dropdown.Toggle>
            <Dropdown.Menu
              className={clsx(
                styles["dropdown-menu"],
                showAddTagTab && styles["dropdown-add-tag"]
              )}
            >
              {showAddTagTab ? (
                <TagModal
                  handleClose={() => setShowAddTagTab(false)}
                  isShow={showAddTagTab}
                  type={COMMON_TEXT.add}
                />
              ) : (
                <>
                  <div className={styles["menu-header"]}>
                    <p className={styles["label-title"]}>Select Tags</p>
                    <span
                      className={clsx(
                        styles["add-hot-tag"],
                        isExecutingTag ? styles["disabled"] : ""
                      )}
                      onClick={() => setShowAddTagTab(true)}
                    >
                      + Add
                    </span>
                  </div>
                  <MenuSelectTag
                    tagList={selectTags}
                    checkedList={tagSelected}
                    onChange={handleSelectTag}
                    disabled={isExecutingTag}
                  />
                  <div
                    className={clsx(
                      "col d-flex justify-content-end mb-3 pt-3",
                      styles["dropdown-menu-action"]
                    )}
                  >
                    <Dropdown.Item className={styles["dropdown-item"]}>
                      <Button
                        name="Cancel"
                        handleClick={() => setTagSelected([])}
                        className="btn-secondary-md"
                      />
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown-item"]}
                      disabled={!tagSelected?.length}
                    >
                      <Button
                        handleClick={() =>
                          handleSaveSelectTags(tagSelected, TAG_ACTION.tag)
                        }
                        isDisabled={!tagSelected?.length || isExecutingTag}
                        className="btn-secondary-md"
                        isBorder={false}
                        name="Save"
                      />
                    </Dropdown.Item>
                  </div>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            className="dropup"
            onToggle={() => setIsShowMenuUntag(!isShowMenuUnTag)}
            show={isShowMenuUnTag}
          >
            <Dropdown.Toggle
              type="button"
              className={clsx(
                "mar_r16",
                styles["btn"],
                styles["btn-select-tag"],
                !disableUnTagButton && styles["active"]
              )}
              id="dropdown-basic2"
              disabled={disableUnTagButton || isExecutingTag}
            >
              Untag
            </Dropdown.Toggle>
            {isShowMenuUnTag && (
              <Dropdown.Menu
                className={clsx(
                  styles["dropdown-menu"],
                  styles["dropdown-menu-tag"]
                )}
              >
                <>
                  <div className={styles["menu-header"]}>
                    <p className={styles["label-title"]}>Select Tags</p>
                  </div>
                  <MenuSelectTag
                    tagList={selectTags}
                    checkedList={unTagSelected}
                    onChange={handleSelectUnTag}
                    disabled={isExecutingTag}
                  />
                  <div
                    className={clsx(
                      "col d-flex justify-content-end mb-3 pt-3",
                      styles["dropdown-menu-action"]
                    )}
                  >
                    <Dropdown.Item className={styles["dropdown-item"]}>
                      <Button
                        name="Cancel"
                        handleClick={() => setUnTagSelected([])}
                        className="btn-secondary-md"
                      />
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles["dropdown-item"]}
                      disabled={!unTagSelected?.length}
                    >
                      <Button
                        handleClick={() =>
                          handleSaveSelectTags(unTagSelected, TAG_ACTION.unTag)
                        }
                        isDisabled={!unTagSelected?.length}
                        className="btn-secondary-md"
                        isBorder={false}
                        name="UnTag"
                      />
                    </Dropdown.Item>
                  </div>
                </>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </>
      )}
      <div
        className={clsx(
          styles["btn-change-message"],
          !isShowChangeOption && "hide-element"
        )}
      >
        <button
          type="button"
          className={clsx("mar_r10", styles["btn"], styles["btn-message-prev"])}
          disabled={isDisabledPrev}
          onClick={() => onSwitchHandle(TYPE_CLICK.previous)}
        >
          <img
            src={`/images/right_move_ic${
              isDisabledPrev ? "-disabled" : ""
            }.svg`}
            alt={TYPE_CLICK.previous}
          />
        </button>
        {isShowThreadNumber && (
          <div className={styles["conversation-number"]}>
            <p>{threadNumber}</p>
          </div>
        )}
        <button
          type="button"
          className={clsx(styles["btn"], styles["btn-message-next"])}
          disabled={isDisabledNext}
          onClick={() => onSwitchHandle(TYPE_CLICK.next)}
        >
          <img
            src={`/images/left_move_ic${isDisabledNext ? "-disabled" : ""}.svg`}
            alt={TYPE_CLICK.next}
          />
        </button>
      </div>
    </div>
  );
};

TagExecuteSingleItem.propTypes = {
  disableTagButton: PropTypes.bool,
  disableUnTagButton: PropTypes.bool,
  isSelectedAllMessage: PropTypes.bool,
  isDisabledPrev: PropTypes.bool,
  isDisabledNext: PropTypes.bool,
  isShowThreadNumber: PropTypes.bool,
  isReset: PropTypes.bool,
  isShowTag: PropTypes.bool,
  isShowChangeOption: PropTypes.bool,
  threadNumber: PropTypes.string,
  displayType: PropTypes.string,
  handleSaveSelectTags: PropTypes.func,
  onSwitchHandle: PropTypes.func,
  handleIsAddTag: PropTypes.func,
};

export default TagExecuteSingleItem;
